// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExpandableH_expandable__CvvdR {

    /* position */
    position: relative;

    /* flex options */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    /* dimentions */
    min-height: 100%;
    flex-grow: 1;
}

    .ExpandableH_children__OQo1D {

        /* position */
        position: relative;

        /* flex options */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-grow: 1;

        /* dimentions */
        height: 100%;
    }`, "",{"version":3,"sources":["webpack://./src/Shared-Components/ExpandableH/ExpandableH.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,kBAAkB;;IAElB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,uBAAuB;;IAEvB,eAAe;IACf,gBAAgB;IAChB,YAAY;AAChB;;IAEI;;QAEI,aAAa;QACb,kBAAkB;;QAElB,iBAAiB;QACjB,aAAa;QACb,sBAAsB;QACtB,2BAA2B;QAC3B,uBAAuB;QACvB,YAAY;;QAEZ,eAAe;QACf,YAAY;IAChB","sourcesContent":[".expandable {\n\n    /* position */\n    position: relative;\n\n    /* flex options */\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: flex-start;\n\n    /* dimentions */\n    min-height: 100%;\n    flex-grow: 1;\n}\n\n    .children {\n\n        /* position */\n        position: relative;\n\n        /* flex options */\n        display: flex;\n        flex-direction: column;\n        justify-content: flex-start;\n        align-items: flex-start;\n        flex-grow: 1;\n\n        /* dimentions */\n        height: 100%;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expandable": `ExpandableH_expandable__CvvdR`,
	"children": `ExpandableH_children__OQo1D`
};
export default ___CSS_LOADER_EXPORT___;
