// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Settings_settings__S0Sbe {
    
    /* dimentions */
    width: 350px;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/VisualEditor/LeftMenu/Settings/Settings.module.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".settings {\n    \n    /* dimentions */\n    width: 350px;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settings": `Settings_settings__S0Sbe`
};
export default ___CSS_LOADER_EXPORT___;
