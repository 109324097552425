// Icons
import groupIcon from '../Icons/group.svg';

import buttonIcon from '../Icons/button.svg';

import textIcon from '../Icons/text.svg';
import chatMessageIcon from '../Icons/chatMessage.svg';
import dropdownIcon from '../Icons/list.svg';

import numberIcon from '../Icons/numbers.svg';
import rangeIcon from '../Icons/slider.svg';

import listIcon from '../Icons/unorderedList.svg';
import chatHistoryIcon from '../Icons/chatHistory.svg';

import imageIcon from '../Icons/image.svg';
import audioIcon from '../Icons/audio.svg';

// Common settings gui patterns
//----------------------------------------------------------------------------------------------------

// Common group of settings for managing name
const nameGroup = {
    type: 'group', direction: 'horizontal', name: 'Name', showLine: true, elements: [
        { type: 'text', name: 'Name', setting: 'name' },
        { type: 'text', name: 'Placeholder', setting: 'placeholder' },
        { type: 'checkbox', name: `Show name`, setting: 'showName' },
    ]
}

// Common group of settings for managing min/max/count for text elements
const textareaContentCountGroup = {
    type: 'group', direction: 'horizontal', name: 'Content / count', showLine: true, elements: [
        { type: 'number', name: 'Min length', setting: 'minLength', minValue: 0, step: 1 },
        { type: 'number', name: 'Max length', setting: 'maxLength', minValue: 0, step: 1 },
        { type: 'dropdown', name: 'Count', setting: 'count', values: ['', 'characters', 'words', 'items'] },
        { type: 'checkbox', name: 'Require content', setting: 'requireContent' }
    ]
}

const dropdownContentGroup = {
    type: 'group', direction: 'horizontal', name: 'Content', showLine: true, elements: [
        { type: 'textarea', name: 'Options', setting: 'options' },
        { type: 'checkbox', name: 'Require content', setting: 'requireContent' }
    ]
}

// Common group of settings for managing min/max/step for number elements
const numberContentValueGroup = {
    type: 'group', direction: 'horizontal', name: 'Content', showLine: true, elements: [
        { type: 'number', name: 'Min Value', setting: 'minValue' },
        { type: 'number', name: 'Max Value', setting: 'maxValue' },
        { type: 'number', name: 'Step (round)', setting: 'step' },
        { type: 'checkbox', name: 'Require content', setting: 'requireContent' }
    ]
}
// Common group of settings for managing min/max/step for number elements
const rangeContentValueGroup = {
    type: 'group', direction: 'horizontal', name: 'Content', showLine: true, elements: [
        { type: 'number', name: 'Min Value', setting: 'minValue' },
        { type: 'number', name: 'Max Value', setting: 'maxValue' },
        { type: 'number', name: 'Step (round)', setting: 'step' },
        { type: 'checkbox', name: 'Require content', setting: 'requireContent' }
    ]
}

const chatMessageRoleAndNameGroup = {
    type: 'group', direction: 'horizontal', name: 'Content', showLine: true, elements: [
        { type: 'dropdown', name: 'Role', setting: 'messageRole', values: ['user', 'assistant', 'system'] },
        { type: 'text', name: 'Name', setting: 'messageName' },
    ]
}

const valueItemsGroup = {
    type: 'group', direction: 'horizontal', name: 'Value / Items', showLine: true, elements: [
        { type: 'dropdown', name: 'When element recieves data', setting: 'onRecieve', values: [{ name: 'Create new item', value: 'createNewItem' }, { name: 'Add to last item', value: 'addToLast' }, { name: 'Replace last item', value: 'replaceLast' }] },
        { type: 'checkbox', name: 'Item list', setting: 'showIndex' },
        { type: 'checkbox', name: 'Clear button', setting: 'showClearButton' }
    ]
}

const sizeSettingsGroup = {
    type: 'group', direction: 'horizontal', name: 'Size', showLine: true, elements: [
        { type: 'number', name: 'Min height', setting: 'minHeight' },
        { type: 'number', name: 'Max height', setting: 'maxHeight' },
        { type: 'number', name: 'Height', setting: 'height' },
        { type: 'checkbox', name: 'Resizable', setting: 'resizable' },
        { type: 'checkbox', name: 'Auto resize', setting: 'autoResize' }
    ]
}

const audioSettingsGroup = {
    type: 'group', direction: 'horizontal', name: 'Size', showLine: true, elements: [
        { type: 'number', name: 'Min Length', setting: 'minLength' },
        { type: 'number', name: 'Max Length', setting: 'maxLength' },
        { type: 'checkbox', name: 'Autoplay', setting: 'autoPlay' },
        { type: 'checkbox', name: 'Require content', setting: 'requireContent' }
    ]
}

const recordingSettingsGroup = {
    type: 'group', direction: 'horizontal', name: 'Recording', showLine: true, elements: [
        { type: 'checkbox', name: 'Record button?', setting: 'showRecordButton' },
        { type: 'checkbox', name: 'Detect Speech?', setting: 'useVoiceDetection' },
    ]
}

const imageSettingsGroup = {
    type: 'group', direction: 'horizontal', name: 'Size', showLine: true, elements: [
        { type: 'number', name: 'Min Width', setting: 'minWidth' },
        { type: 'number', name: 'Max Width', setting: 'maxWidth' },
        { type: 'number', name: 'Min Height', setting: 'minHeight' },
        { type: 'number', name: 'Max Height', setting: 'maxHeight' },
        { type: 'checkbox', name: 'Require content', setting: 'requireContent' }
    ]
}


const generalStylesGroup = {
    type: 'group', direction: 'vertical', name: 'General styles (insert custom CSS)', showLine: true, collapsed: true, elements: [
        { type: 'textarea', name: 'Element Container', placeholder: 'Write custom CSS here', setting: 'containerStyle', events: { mouseenter: 'showContainerHighlight', mouseleave: 'hideContainerHighlight' } },
        { type: 'textarea', name: 'Element Name', placeholder: 'Write custom CSS here', setting: 'nameStyle', events: { mouseenter: 'showNameHighlight', mouseleave: 'hideNameHighlight' } },
        { type: 'textarea', name: 'Character Counter', placeholder: 'Write custom CSS here', setting: 'countStyle', events: { mouseenter: 'showCountHighlight', mouseleave: 'hideCountHighlight' } },
        { type: 'textarea', name: 'Clear Button', placeholder: 'Write custom CSS here', setting: 'clearButtonStyle', events: { mouseenter: 'showClearButtonHighlight', mouseleave: 'hideClearButtonHighlight' } }
    ]
}

const textareaStyleGroup = {
    type: 'group', direction: 'vertical', name: 'Text area style', showLine: true, collapsed: true, elements: [
        { type: 'textarea', name: 'Text area', placeholder: 'Write custom CSS here', setting: 'textareaStyle', events: { mouseenter: 'showTextAreaHighlight', mouseleave: 'hideTextAreaHighlight' } },
    ]
}

const chatMessageStyleGroup = {
    type: 'group', direction: 'vertical', name: 'Text area style', showLine: true, collapsed: true, elements: [
        { type: 'textarea', name: 'Text area', placeholder: 'Write custom CSS here', setting: 'textareaStyle', events: { mouseenter: 'showTextAreaHighlight', mouseleave: 'hideTextAreaHighlight' } },
        { type: 'textarea', name: 'Submit button', placeholder: 'Write custom CSS here', setting: 'submitButtonStyle', events: { mouseenter: 'showSubmitButtonHighlight', mouseleave: 'hideSubmitButtonHighlight' } },
    ]
}

const listStyleGroup = {
    type: 'group', direction: 'vertical', name: 'List style', showLine: true, collapsed: true, elements: [
        { type: 'textarea', name: 'List', placeholder: 'Write custom CSS here', setting: 'listStyle', events: { mouseenter: 'showListHighlight', mouseleave: 'hideListHighlight' } },
        { type: 'textarea', name: 'Item', placeholder: 'Write custom CSS here', setting: 'itemStyle', events: { mouseenter: 'showItemHighlight', mouseleave: 'hideItemHighlight' } },
        { type: 'textarea', name: 'Number', placeholder: 'Write custom CSS here', setting: 'numberStyle', events: { mouseenter: 'showNumberHighlight', mouseleave: 'hideNumberHighlight' } },
        { type: 'textarea', name: 'Text area', placeholder: 'Write custom CSS here', setting: 'textareaStyle', events: { mouseenter: 'showTextAreaHighlight', mouseleave: 'hideTextAreaHighlight' } },
        { type: 'textarea', name: 'Delete Button', placeholder: 'Write custom CSS here', setting: 'deleteButtonStyle', events: { mouseenter: 'showDeleteButtonHighlight', mouseleave: 'hideDeleteButtonHighlight' } },
        { type: 'textarea', name: 'Seperator Line', placeholder: 'Write custom CSS here', setting: 'lineStyle', events: { mouseenter: 'showLineHighlight', mouseleave: 'hideLineHighlight' } },
    ]
}

const chatHistoryStyleGroup = {
    type: 'group', direction: 'vertical', name: 'Chat history style', showLine: true, collapsed: true, elements: [
        { type: 'textarea', name: 'Message history', placeholder: 'Write custom CSS here', setting: 'messageHistoryStyle', events: { mouseenter: 'showMessageHistoryHighlight', mouseleave: 'hideMessageHistoryHighlight' } },
        { type: 'textarea', name: 'Message container', placeholder: 'Write custom CSS here', setting: 'messageContainerStyle', events: { mouseenter: 'showMessageHighlight', mouseleave: 'hideMessageHighlight' } },
        { type: 'textarea', name: 'Message text', placeholder: 'Write custom CSS here', setting: 'messageStyle', events: { mouseenter: 'showMessageTextHighlight', mouseleave: 'hideMessageTextHighlight' } },
        { type: 'textarea', name: 'Message text (user)', placeholder: 'Write custom CSS here', setting: 'userMessageStyle' },
        { type: 'textarea', name: 'Message text (assistant)', placeholder: 'Write custom CSS here', setting: 'assistantMessageStyle' },
        { type: 'textarea', name: 'Message text (system)', placeholder: 'Write custom CSS here', setting: 'systemMessageStyle' },
        { type: 'textarea', name: 'Name & time', placeholder: 'Write custom CSS here', setting: 'nameAndTimeStyle', events: { mouseenter: 'showNameAndTimeHighlight', mouseleave: 'hideNameAndTimeHighlight' } },
    ]
}

const dropdownStyleGroup = {
    type: 'group', direction: 'vertical', name: 'Dropdown style', showLine: true, collapsed: true, elements: [
        { type: 'textarea', name: 'Dropdown', placeholder: 'Write custom CSS here', setting: 'dropdownStyle', events: { mouseenter: 'showDropdownHighlight', mouseleave: 'hideDropdownHighlight' } },
        { type: 'textarea', name: 'Display value', placeholder: 'Write custom CSS here', setting: 'displayValueStyle', events: { mouseenter: 'showDisplayValueHighlight', mouseleave: 'hideDisplayValueHighlight' } },
        { type: 'textarea', name: 'Placeholder value', placeholder: 'Write custom CSS here', setting: 'placeholderStyle', events: { mouseenter: 'showPlaceholderHighlight', mouseleave: 'hidePlaceholderHighlight' } },
        { type: 'textarea', name: 'Triangle Icon', placeholder: 'Write custom CSS here', setting: 'iconStyle', events: { mouseenter: 'showIconHighlight', mouseleave: 'hideIconHighlight' } },
        { type: 'textarea', name: 'Options Container', placeholder: 'Write custom CSS here', setting: 'optionsContainerStyle', events: { mouseenter: 'showOptionsContainerHighlight', mouseleave: 'hideOptionsContainerHighlight' } },
        { type: 'textarea', name: 'Option', placeholder: 'Write custom CSS here', setting: 'optionStyle', events: { mouseenter: 'showOptionHighlight', mouseleave: 'hideOptionHighlight' } },
    ]
}

const sliderStyleGroup = {
    type: 'group', direction: 'vertical', name: 'Slider style', showLine: true, collapsed: true, elements: [
        { type: 'textarea', name: 'Slider', placeholder: 'Write custom CSS here', setting: 'sliderStyle', events: { mouseenter: 'showSliderHighlght', mouseleave: 'hideSliderHighlight' } },
        { type: 'textarea', name: 'Track', placeholder: 'Write custom CSS here', setting: 'trackStyle', events: { mouseenter: 'showTrackHighlight', mouseleave: 'hideTrackHighlight' } },
        { type: 'textarea', name: 'Bar', placeholder: 'Write custom CSS here', setting: 'barStyle', events: { mouseenter: 'showBarHighlight', mouseleave: 'hideBarHighlight' } },
    ]
}

const audioStyleGroup = {
    type: 'group', direction: 'vertical', name: "Audio styles (insert custom CSS)", showLine: true, collapsed: true, elements: [
        { type: 'textarea', name: "Element", placeholder: "Write custom CSS here", setting: "elementStyle", events: { mouseenter: 'showAudioElementHighlight', mouseleave: 'hideAudioElementHighlight' } },
        { type: 'textarea', name: "Record Button", placeholder: "Write custom CSS here", setting: "recordButtonStyle", events: { mouseenter: 'showRecordButtonHighlight', mouseleave: 'hideRecordButtonHighlight' } },
        { type: 'textarea', name: "Record Icon", placeholder: "Write custom CSS here", setting: "recordImageStyle", events: { mouseenter: 'showRecordImgHighlight', mouseleave: 'hideRecordImgHighlight' } },
        { type: 'textarea', name: "Play Button", placeholder: "Write custom CSS here", setting: "playStyle", events: { mouseenter: 'showPlayButtonHighlight', mouseleave: 'hidePlayButtonHighlight' } },
        { type: 'textarea', name: "Play Meter", placeholder: "Write custom CSS here", setting: "playMeterStyle", events: { mouseenter: 'showPlayMeterHighlight', mouseleave: 'hidePlayMeterHighlight' } },

        { type: 'textarea', name: "Audio Icon", placeholder: "Write custom CSS here", setting: "audioIconStyle", events: { mouseenter: 'showAudioIconHighlight', mouseleave: 'hideAudioIconHighlight' } },
        { type: 'textarea', name: "Placeholder text", placeholder: "Write custom CSS here", setting: "placeholderStyle", events: { mouseenter: 'showTextHighlight', mouseleave: 'hideTextHighlight' } }
    ]
}

const imageStyleGroup = {
    type: 'group', direction: 'vertical', name: "Image styles (insert custom CSS)", showLine: true, collapsed: true, elements: [
        { type: 'textarea', name: "Element", placeholder: "Write custom CSS here", setting: "elementStyle", events: { mouseenter: 'showImageElementHighlight', mouseleave: 'hideImageElementHighlight' } },
        { type: 'textarea', name: "Image", placeholder: "Write custom CSS here", setting: "imageStyle", events: { mouseenter: 'showImageHighlight', mouseleave: 'hideImageHighlight' } },
        { type: 'textarea', name: "Placeholder", placeholder: "Write custom CSS here", setting: "placeholderStyle", events: { mouseenter: 'showPlaceholderHighlight', mouseleave: 'hidePlaceholderHighlight' } },
        { type: 'textarea', name: "Placeholder Icon", placeholder: "Write custom CSS here", setting: "placeholderIconStyle", events: { mouseenter: 'showPlaceholderIconHighlight', mouseleave: 'hidePlaceholderIconHighlight' } },
    ]
}


// Element templates
//----------------------------------------------------------------------------------------------------

const group = {

    name: 'Group',
    type: 'group',
    icon: groupIcon,

    editable: true,

    showName: false,
    showLine: true,
    direction: 'vertical',

    elements: [],    // Some elements

    gui: [
        {
            type: 'group', direction: 'horizontal', elements: [
                { type: 'text', name: 'Group name', setting: 'name', placeholder: 'Group must have a name' },
                { type: 'dropdown', name: 'Align elements', setting: 'direction', values: ['vertical', 'horizontal'] },
            ]
        },
        {
            type: 'group', direction: 'horizontal', elements: [
                { type: 'checkbox', name: 'Show name', setting: 'showName' },
                { type: 'checkbox', name: 'Expandable', setting: 'expandable' },
                { type: 'checkbox', name: 'Expanded', setting: 'expanded' },
            ]
        },
        generalStylesGroup,
        {
            type: 'group', direction: 'vertical', name: 'Group / container styles', showLine: true, collapsed: true, elements: [
                { type: 'textarea', name: 'Elements Container', placeholder: 'Use css to add custom styles', setting: 'elementsContainerStyle' },
            ]
        },
    ]
}

const button = {

    name: 'Button',
    type: 'button',
    icon: buttonIcon,

    editable: true,
    showLines: true,
    hideName: true,

    onSubmit: [{ command: 'SUBMIT_FIELD', parameter: '' }],

    gui: [
        {
            type: 'group', direction: 'horizontal', name: 'Name', showLine: true, elements: [
                { type: 'text', name: 'Name', setting: 'name' },
                { type: 'text', name: 'Width', setting: 'width', values: ['auto', '100%', '50%'] },
                { type: 'checkbox', name: 'Show lines', setting: 'showLines' },
            ]
        },
        {
            type: 'group', direction: 'vertical', name: 'Actions', showLine: true, elements: [
                { type: 'submitactions', name: 'When button is clicked', setting: 'onSubmit' }
            ],
        },
        {
            type: 'group', direction: 'vertical', name: 'General styles (insert custom CSS)', showLine: true, collapsed: true, elements: [
                { type: 'textarea', name: 'Element Container', placeholder: '', setting: 'nameStyle', events: { mouseenter: 'showContainerHighlight', mouseleave: 'hideContainerHighlight' } },
            ]
        },
        {
            type: 'group', direction: 'vertical', name: 'Element styles (insert custom CSS)', showLine: true, collapsed: true, elements: [
                { type: 'textarea', name: 'Button', placeholder: '', setting: 'buttonStyle', events: { mouseenter: 'showButtonHighlight', mouseleave: 'hideButtonHighlight' } },
                { type: 'textarea', name: 'Side lines', placeholder: '', setting: 'sideLineStyle', events: { mouseenter: 'showSideLineHighlight', mouseleave: 'hideSideLineHighlight' } },
            ]
        }
    ]
}

const text = {

    name: 'Text Input',
    type: 'text',
    icon: textIcon,

    showName: true,
    showCount: true,
    showIndex: true,
    showClearButton: false,
    editable: true,

    minLength: 0,
    maxLength: 500,
    count: 'characters',
    requireContent: true,

    placeholder: null,

    onSubmit: [{ command: 'MESSAGE_NODE', parameter: 'same_as_field' }],
    onRecieve: 'createNewItem',

    gui: [
        nameGroup,
        textareaContentCountGroup,
        valueItemsGroup,
        {
            type: 'group', direction: 'vertical', name: 'Actions', showLine: true, elements: [
                { type: 'submitactions', name: 'When field is submited', setting: 'onSubmit' }
            ],
        },
        generalStylesGroup,
        textareaStyleGroup
    ]
}

const textarea = {

    name: 'Text Area',
    type: 'textarea',
    icon: textIcon,

    showName: true,
    showCount: true,
    showIndex: true,
    showClearButton: true,
    editable: true,
    resizable: true,

    minLength: 0,
    maxLength: 500,
    count: 'characters',
    requireContent: true,

    placeholder: null,

    onSubmit: [{ command: 'MESSAGE_NODE', parameter: 'same_as_field' }],
    onRecieve: 'createNewItem',

    gui: [
        nameGroup,
        sizeSettingsGroup,
        textareaContentCountGroup,
        valueItemsGroup,
        {
            type: 'group', direction: 'vertical', name: 'Actions', showLine: true, elements: [
                { type: 'submitactions', name: 'When field is submitted', setting: 'onSubmit' }
            ],
        },
        generalStylesGroup,
        textareaStyleGroup
    ],
}

const chatMessage = {

    name: 'Chat Message',
    type: 'chatMessage',
    icon: chatMessageIcon,

    showName: true,
    showCount: true,
    showIndex: true,
    showClearButton: true,
    editable: true,

    messageRole: 'user',
    messageName: null,

    minLength: 0,
    maxLength: 500,
    count: 'characters',
    requireContent: true,

    placeholder: null,

    onSubmit: [
        { command: 'MESSAGE_FIELD', parameter: 'Chat History' },
        { command: 'MESSAGE_NODE', parameter: 'same_as_field' },
        { command: 'CLEAR_FIELD', parameter: 'same_as_field' }
    ],

    onRecieve: 'createNewItem',

    gui: [
        nameGroup,
        textareaContentCountGroup,
        chatMessageRoleAndNameGroup,
        valueItemsGroup,
        {
            type: 'group', direction: 'vertical', name: 'Actions', showLine: true, elements: [
                { type: 'submitactions', name: 'When field is submitted', setting: 'onSubmit' }
            ],
        },
        generalStylesGroup,
        chatMessageStyleGroup
    ],
}

const dropdown = {

    name: 'Dropdown',
    type: 'dropdown',
    icon: dropdownIcon,

    showName: true,
    showIndex: true,
    editable: true,

    placeholder: 'Select a value...',
    values: 'example 1, example 2',
    requireContent: true,

    onSubmit: [{ command: 'MESSAGE_NODE', parameter: 'same_as_field' }],
    onRecieve: 'createNewItem',

    gui: [
        nameGroup,
        dropdownContentGroup,
        valueItemsGroup,

        {
            type: 'group', direction: 'vertical', name: 'Actions', showLine: true, elements: [
                { type: 'submitactions', name: 'When field is submitted', setting: 'onSubmit' }
            ],
        },
        generalStylesGroup,
        dropdownStyleGroup
    ],
}

const number = {

    name: 'Number Input',
    type: 'number',
    icon: numberIcon,

    showName: true,
    showIndex: true,
    showClearButton: true,
    editable: true,

    minValue: 0,
    maxValue: 10,
    step: 0.01,
    requireContent: true,

    placeholder: null,

    onSubmit: [{ command: 'MESSAGE_NODE', parameter: 'same_as_field' }],
    onRecieve: 'createNewItem',

    gui: [
        nameGroup,
        numberContentValueGroup,
        valueItemsGroup,
        {
            type: 'group', direction: 'vertical', name: 'Actions', showLine: true, elements: [
                { type: 'submitactions', name: 'When field is submited', setting: 'onSubmit' }
            ],
        },
        generalStylesGroup,
        textareaStyleGroup
    ]
}

const range = {

    name: 'Number Slider',
    type: 'range',
    icon: rangeIcon,

    showName: true,
    showIndex: true,
    showClearButton: true,
    editable: true,

    minValue: 0,
    maxValue: 10,
    step: 1,
    requireContent: true,

    placeholder: 5,

    onSubmit: [{ command: 'MESSAGE_NODE', parameter: 'same_as_field' }],
    onRecieve: 'createNewItem',

    gui: [
        nameGroup,
        rangeContentValueGroup,
        valueItemsGroup,
        {
            type: 'group', direction: 'vertical', name: 'Actions', showLine: true, elements: [
                { type: 'submitactions', name: 'When field is submited', setting: 'onSubmit' }
            ],
        },
        generalStylesGroup,
        sliderStyleGroup
    ]
}

const list = {

    name: 'List',
    type: 'list',
    icon: listIcon,

    showName: true,
    showClearButton: true,
    editable: true,
    listType: true,

    minLength: 0,
    maxLength: 25,
    count: 'items',
    requireContent: true,

    minHeight: 75,
    maxHeight: 600,
    autoResize: true,
    resizable: true,


    placeholder: 'New item',

    onSubmit: [{ command: 'MESSAGE_NODE', parameter: 'same_as_field' }],
    onRecieve: 'createNewItem',

    gui: [
        nameGroup,
        sizeSettingsGroup,
        textareaContentCountGroup,
        valueItemsGroup,
        {
            type: 'group', direction: 'vertical', name: 'Actions', showLine: true, elements: [
                { type: 'submitactions', name: 'When field is submitted', setting: 'onSubmit' }
            ],
        },
        generalStylesGroup,
        listStyleGroup
    ],
}

const chatHistory = {

    name: 'Chat History',
    type: 'chatHistory',
    icon: chatHistoryIcon,

    showName: true,
    showClearButton: true,
    editable: true,
    listType: true,

    minLength: 0,
    maxLength: 500,
    count: 'items',
    requireContent: false,

    minHeight: 350,
    maxHeight: 800,
    height: 350,
    autoResize: false,
    resizable: true,


    placeholder: 'No chat history to display',

    onSubmit: [{ command: 'MESSAGE_NODE', parameter: 'same_as_field' }],
    onRecieve: 'createNewItem',

    gui: [
        nameGroup,
        sizeSettingsGroup,
        textareaContentCountGroup,
        valueItemsGroup,
        {
            type: 'group', direction: 'vertical', name: 'Actions', showLine: true, elements: [
                { type: 'submitactions', name: 'When field is submitted', setting: 'onSubmit' }
            ],
        },
        generalStylesGroup,
        chatHistoryStyleGroup
    ],
}

const audio = {

    name: 'Audio',
    type: 'audio',
    icon: audioIcon,

    showName: true,
    showIndex: true,
    showClearButton: true,
    editable: true,

    minLength: 0,
    maxLength: 500,
    showRecordButton: true,
    autoPlay: true,
    requireContent: true,

    minHeight: 350,
    maxHeight: 800,
    height: 350,
    autoResize: false,
    resizable: true,


    placeholder: 'Click to Upload',

    onSubmit: [{ command: 'MESSAGE_NODE', parameter: 'same_as_field' }],
    onRecieve: 'createNewItem',

    gui: [
        nameGroup,
        audioSettingsGroup,
        recordingSettingsGroup,
        valueItemsGroup,

        {
            type: 'group', direction: 'vertical', name: 'Actions', showLine: true, elements: [
                { type: 'submitactions', name: 'When field is submitted', setting: 'onSubmit' }
            ],
        },
        generalStylesGroup,
        audioStyleGroup,
    ],
}

const image = {

    name: 'Image',
    type: 'image',
    icon: imageIcon,

    showName: true,
    showIndex: true,
    showClearButton: true,
    editable: true,

    minWidth: 0,
    maxWidth: 500,

    minHeight: 350,
    maxHeight: 800,

    requireContent: true,

    height: 350,
    autoResize: false,
    resizable: true,


    placeholder: 'Click to Upload',

    onSubmit: [{ command: 'MESSAGE_NODE', parameter: 'same_as_field' }],
    onRecieve: 'createNewItem',

    gui: [
        nameGroup,
        imageSettingsGroup,
        valueItemsGroup,
        {
            type: 'group', direction: 'vertical', name: 'Actions', showLine: true, elements: [
                { type: 'submitactions', name: 'When field is submitted', setting: 'onSubmit' }
            ],
        },
        generalStylesGroup,
        imageStyleGroup
    ],
}

// Export
//----------------------------------------------------------------------------------------------------

const templates = { group, button, text, textarea, dropdown, number, range, list, chatMessage, chatHistory, audio, image };

export default templates;