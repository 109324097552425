// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Slider_slider__y0pOh {

    /* position */
    position: relative;

    /* dimentions */
    width: 100%;
    height: 15px;

    /* flex options */
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

    .Slider_slider-bar__BlEgf {

        /* position */
        position: absolute;

        /* dimentions */
        width: 100%;
        height: 1px;

        /* background */
        background: rgba(256, 256, 256, 0.25);
    }

    .Slider_slider-thumb-container__XAPhC {

        /* position */
        position: absolute;

        /* dimentions */
        width: 0px;
        height: 100%;
        overflow: visible;

        /* flex options */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Slider_slider-thumb__\\+2AHX {

        /* position */
        position: relative;

        /* dimentions */
        width: 5px;
        height: 100%;

        /* background */
        background: grey;
    }`, "",{"version":3,"sources":["webpack://./src/Shared-Components/Slider/Slider.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,kBAAkB;;IAElB,eAAe;IACf,WAAW;IACX,YAAY;;IAEZ,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,eAAe;AACnB;;IAEI;;QAEI,aAAa;QACb,kBAAkB;;QAElB,eAAe;QACf,WAAW;QACX,WAAW;;QAEX,eAAe;QACf,qCAAqC;IACzC;;IAEA;;QAEI,aAAa;QACb,kBAAkB;;QAElB,eAAe;QACf,UAAU;QACV,YAAY;QACZ,iBAAiB;;QAEjB,iBAAiB;QACjB,aAAa;QACb,uBAAuB;QACvB,mBAAmB;IACvB;;IAEA;;QAEI,aAAa;QACb,kBAAkB;;QAElB,eAAe;QACf,UAAU;QACV,YAAY;;QAEZ,eAAe;QACf,gBAAgB;IACpB","sourcesContent":[".slider {\n\n    /* position */\n    position: relative;\n\n    /* dimentions */\n    width: 100%;\n    height: 15px;\n\n    /* flex options */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    cursor: pointer;\n}\n\n    .slider-bar {\n\n        /* position */\n        position: absolute;\n\n        /* dimentions */\n        width: 100%;\n        height: 1px;\n\n        /* background */\n        background: rgba(256, 256, 256, 0.25);\n    }\n\n    .slider-thumb-container {\n\n        /* position */\n        position: absolute;\n\n        /* dimentions */\n        width: 0px;\n        height: 100%;\n        overflow: visible;\n\n        /* flex options */\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n\n    .slider-thumb {\n\n        /* position */\n        position: relative;\n\n        /* dimentions */\n        width: 5px;\n        height: 100%;\n\n        /* background */\n        background: grey;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slider": `Slider_slider__y0pOh`,
	"slider-bar": `Slider_slider-bar__BlEgf`,
	"slider-thumb-container": `Slider_slider-thumb-container__XAPhC`,
	"slider-thumb": `Slider_slider-thumb__+2AHX`
};
export default ___CSS_LOADER_EXPORT___;
