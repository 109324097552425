// Modules
import React, { useRef, forwardRef, useState, useEffect } from 'react';

// Components
import FrostedGlass from '@Utils/components/FrostedGlass/FrostedGlass';

// Styles
import './DivInput.css';

// Component
const DivInput = forwardRef((props, forwardedRef) => {

    const { className, value, placeholder, options, onChange, style, editable, secret, noselect, onMouseEnter, onMouseLeave } = props;

    const localRef = useRef(null);
    const inputRef = forwardedRef || localRef;
    const selection = useRef(null);
    const position = useRef(null);

    // Options and filtering
    //--------------------------------------------------

    const [showOptions, setShowOptions] = useState(false);
    const availableOptions = options || [];
    const [filteredOptions, setFilteredOptions] = useState(availableOptions);

    useEffect(() => {
        filterOptions();
    }, [value]);

    // Filter options based on inner text
    function filterOptions() {

        const text = inputRef.current.value;

        if (!text) { setFilteredOptions(availableOptions); }

        setFilteredOptions(availableOptions.filter((option) => {
            return (String(option).toLowerCase().trim().includes(String(text).toLowerCase().trim()));
        }));
    }

    function selectOption(option) {
        inputRef.current.value = option;
        onChange(inputRef.current.value);
        inputRef.current.dispatchEvent(new Event('change'));
    }

    // Keydown and input
    //--------------------------------------------------

    function onKeyDown(e) {

        // Defocus if enter key
        if (e.key === 'Enter') {
            inputRef.current.blur();
            return;
        }
    }

    function handleChange(e) {

        const newVal = e.target.value;
        onChange(newVal);
        inputRef.current.dispatchEvent(new Event('change'));
    }

    // Saving position on input
    //--------------------------------------------------

    // Adjust size automatically
    useEffect(() => {

        if (!inputRef.current) { return; }

        if (!inputRef.current.value) {
            inputRef.current.style.width = '100%';
            inputRef.current.style.minWidth = '100%';
            return;
        }

        inputRef.current.style.minWidth = '0px'
        inputRef.current.style.width = '0px';
        inputRef.current.style.width = inputRef.current.scrollWidth + 'px';
        //inputRef.current.style.width = '100%';

        inputRef.current.style.height = '0px';
        inputRef.current.style.height = inputRef.current.scrollHeight + 'px';

    }, [value, inputRef.current]);

    // Component
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={`div-input ${editable ? 'div-input-editable' : null} ${className}`} onClick={() => { inputRef.current.focus(); setFilteredOptions(availableOptions); setShowOptions(true); }} onBlur={() => { setShowOptions(false); }}style={style}>

            <span className={'div-input-placeholder'} style={{ height: value ? '0px' : undefined, overflow: value ? 'hidden' : undefined }}>{placeholder || ' '}</span>
            <textarea className={`div-input-text ${!value ? 'div-input-text-noval' : ''}`} secret={String(secret)} masked={value || ''} ref={inputRef} disabled={editable === false} suppressContentEditableWarning={true} spellCheck={false} onKeyDown={onKeyDown} onInput={handleChange} onSelect={noselect ? (e) => { e.preventDefault(); } : null} value={value}></textarea>

            {/* dropdown options container */}
            {options && showOptions ?
                <div style={{ position: 'absolute', bottom: '-5px', left: '0px', width: 'auto', height: '0px', display: !showOptions ? 'none' : undefined }}>
                    <FrostedGlass className={`div-input-options-container`} style={{ display: !showOptions ? 'none' : undefined }}>
                        {filteredOptions.map((option) => {
                            return (<div className={`div-input-option`} key={option} onMouseDown={() => { selectOption(option); }}>{option}</div>)
                        })}
                    </FrostedGlass>
                </div>
                : null}

            <style>{`@font-face { font-family: 'masked'; src: url('/fonts/masked.ttf') format('opentype'); }`}</style>
        </div>
    );
});

export default DivInput;