// Modules
import { attachEvents } from '@Utils/functions/events.js';

// Player class
export class Player {

    constructor({ source, onReady, onStart, onStop, onProgress, onError, frameRate, autoPlay } = {}) {

        try {

            attachEvents(this);

            // Callbacks
            this.onReady = onReady;
            this.onStart = onStart;
            this.onStop = onStop;
            this.onProgress = onProgress;
            this.onError = onError;
            this.frameRate = frameRate || 140;
            this.autoPlay = autoPlay;

            // Init source and queue
            this.source = source;
            this.frames = [];
            this.queue = [];
            this.done = 0;

            // Init media objects
            this.audioElement = new Audio();
            this.mediaSource = new MediaSource();
            this.audioElement.src = URL.createObjectURL(this.mediaSource);

            this.mediaSource.onsourceopen = () => {
                this.sourceBuffer = this.mediaSource.addSourceBuffer('audio/mpeg');
                this.addFromQueue();
                this.onReady();
            }

            // Add new chunks from source when source changes
            source?.listen?.('update', () => {
                this.addToQueue(this.source.frames?.slice(this.frames.length));
                this.duration = this.source.duration || 0;
            });
        }

        catch (e) {
            this.onError(e);
        }
    }

    // Managing chunks
    //--------------------------------------------------------------------------------

    // Add a chunk of audio to the player
    addToQueue(chunks) {

        try {

            if (!Array.isArray(chunks)) { chunks = [chunks]; }

            // Push chunks to queue and add
            const lengthBeforeAdd = this.queue.length;
            this.frames.push(...chunks);
            this.queue.push(...chunks);

            // Add from queue if not consuming
            if (!this.consuming) {
                this.addFromQueue();
            }
        }

        catch (e) {
            this.onError(e);
        }
    }

    // Add chunks from the queue to the source buffer
    async addFromQueue() {

        try {

            this.consuming = true;

            if (!this.sourceBuffer) {
                this.consuming = false;
                return;
            }

            // Return if updating (will trigger error otherwise)
            if (this.sourceBuffer.updating) {
                this.sourceBuffer.onupdateend = this.addFromQueue.bind(this);
                this.consuming = false;
                return;
            }

            else {
                delete this.sourceBuffer.onupdateend;
            }

            // Grab chunk, go again if chunk is null
            const chunk = this.queue[0];
            this.done = this.done + 1;

            if (!chunk) {

                if (this.queue.length > 0) {
                    this.queue.shift(1);
                    this.addFromQueue();
                    return;
                }

                else {
                    this.queue.shift(1);
                    this.consuming = false;
                    return;
                }
            }

            // Get array buffer from chunk, append to source buffer
            const arrayBuffer = chunk.buffer.buffer;
            //console.log('Appending to source buffer: ', arrayBuffer)
            this.sourceBuffer.appendBuffer(arrayBuffer);
            this.queue.shift(1);

            if (this.queue.length === 0) {
                this.consuming = false;
                return;
            }

            this.addFromQueue();
        }

        catch (e) {
            this.onError(e);
        }
    }

    // Play / pause
    //--------------------------------------------------------------------------------

    // Start player
    async start() {

        console.log('Player | Starting...', this);

        // If there are frames that haven't been added yet
        if (this.frames?.length < 1 && this.source?.frames?.length > 0) {
            this.addToQueue(this.source.frames?.slice(this.frames.length));
            this.duration = this.source.duration || 0;
            setTimeout(this.start.bind(this), 100);
            return;
        }

        if (this.playing) { return; }
        if (this.progress >= 1) { return; }

        // Check for un-added frames
        if (this.done < this.source.frames.length) {
            this.addToQueue(this.source.frames?.slice(this.done));
            this.duration = this.source.duration;
        }

        this.startTime = Date.now();
        this.startPos = this.position || 0;

        this.audioElement.currentTime = this.startPos;
        await (async () => { try { await this.audioElement.play(); console.log('') } catch (e) { console.error(e); } })();
        this.playing = true;

        // Callback and start reporting progress
        this.onStart();
        this.updateInterval = setInterval(this.reflectPlayerInfo.bind(this), 1000 / this.frameRate);
    }

    // Stop player
    async stop(reason) {

        if (!this.playing) { return; }

        console.log(`Player | Stopping... ${reason || ''}`);

        await this.audioElement.pause();
        this.playing = false;

        // Stop reporting progress and stop
        clearInterval(this.updateInterval);
        clearInterval(this.pauseTimeout);
        this.onStop();
    }

    // Scrub player
    async scrub(newProgress, play) {

        this.progress = newProgress;
        this.position = (this.duration || 0) * (this.progress || 0);
        this.startTime = Date.now();
        this.startPos = this.position;
        this.audioElement.currentTime = this.position;

        if (play) { this.start(); }

        this.onProgress();
    }

    // Set progress to zero and give one last update
    async reset(reason) {

        this.resetting = true;

        await this.stop(reason);
        await this.scrub(0);

        this.onProgress();
        this.resetting = false;
    }

    async reflectPlayerInfo() {

        if (this.position > (this.duration || 0)) { if (!this.resetting) { this.reset('position exceeded duration'); } return; }

        // Calculate position and progress
        this.position = (this.startPos + ((Date.now() - this.startTime) / 1000));
        this.progress = (this.position || 0) / (this.duration || 0);

        this.onProgress();
    }
}