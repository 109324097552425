// Modules
import { useRef, useEffect } from 'react';

// Styles
import s from './Slider.module.css';

// Component
export default function Slider({ className, value, max = 10, min = 0, step = 1, onChange }) {

    // Value calculations
    //----------------------------------------------------------------------------------------------------

    const progress = (value - min) / (max - min);

    // When mouse is down in range
    function rangeMouseDown(e) {
        window.addEventListener('mousemove', rangeMouseMove);
        window.addEventListener('mouseup', rangeMouseUp);
        rangeMouseMove(e);
    }

    // When mouse is up in range
    function rangeMouseUp(e) {
        window.removeEventListener('mousemove', rangeMouseMove);
        window.removeEventListener('mouseup', rangeMouseUp);
    }

    // When mouse moves in range
    function rangeMouseMove(e) {

        const sliderRect = sliderRef.current.getBoundingClientRect();
        const relativePosition = (e.clientX - sliderRect.left) / sliderRect.width;
        let newValue = relativePosition * (max - min) + min;

        // Rounding the value according to the step
        newValue = Math.round(newValue / step) * step;

        // Restricting the value to the min and max limits
        newValue = Math.max(min, Math.min(newValue, max));

        // Update element value
        onChange(newValue);
    }

    // Clean up event listeners on component unmount
    useEffect(() => {
        return () => {
            window.removeEventListener('mousemove', rangeMouseMove);
            window.removeEventListener('mouseup', rangeMouseUp);
        };
    }, []);

    // Attach events
    //----------------------------------------------------------------------------------------------------

    const sliderRef = useRef(null);
    const sliderTrackRef = useRef(null);
    const sliderBarRef = useRef(null);

    // Component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={s[`slider`] + ' slider' + ' ' + className} ref={sliderRef} onMouseDown={rangeMouseDown} onMouseUp={rangeMouseUp}>
            <div className={s[`slider-bar`] + ' slider-bar'} ref={sliderTrackRef}></div>
            <div className={s[`slider-thumb-container`] + ' slider-thumb-container'} style={{ marginLeft: 100 * progress + '%', marginRight: 100 - 100 * progress + '%' }}>
                <div className={s[`slider-thumb`] + ' slider-thumb'} style={{ background: 'transparent', outline: 'none', background: 'none', boxShadow: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className={s[`slider-thumb`] + ' slider-thumb'} ref={sliderBarRef} style={{ transform: `translateX(${50 - 100 * progress}%)` }}></div>
                </div>
            </div>
        </div>
    );
}