// Modules
import { useEffect } from 'react';

// State
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { VisualEditorState } from '@State/VisualEditor/VisualEditorState.js';

// Components
import DivInput from '@Shared-Components/DivInput/DivInput.js';

// Resources
import deleteIcon from './deleteIcon.svg';

// Styles
import s from './NodeTop.module.css';

// Component function
export default function NodeTop({ node, beginDragging }) {

    const refresh = useRefresh();

    // Refresh component when node name changes
    useEffect(() => {
        return node.listen('updateName', refresh)
    }, []);

    // Component html
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={s['node-top']} onMouseDown={(e) => { beginDragging(e); }}>

            {/* If there's an icon and we should show it */}
            {node.icon && (node.settings?.showIcon !== false) ?
                <img className={s['node-top-icon']} src={node.icon} title={node.type} onDragStart={(e) => { e.preventDefault(); }}></img>
                : null}

            {/* If there's a name and we should show it */}
            {node.name !== undefined && node.name !== null && (node.settings?.showName !== false) ?
                <DivInput className={s['node-top-name']} value={node.name} editable={node.settings?.canEditName !== false} noselect={true} onChange={(newName) => { node.updateName(newName); }} />
                : null}

            {/* Button to delete node */}
            <img className={s['node-delete-icon']} src={deleteIcon} title={'Delete'} onClick={node.delete.bind(node)} onDragStart={(e) => { e.preventDefault(); }}></img>
        </div>
    );
}