// Modules
import { useRef, useEffect } from 'react';
//import { useSmoothScroll } from '@Utils/hooks/useSmoothScroll.js';
import { useRefresh } from '@Utils/hooks/useRefresh.js';

// Components
import LogItem from './LogItem/LogItem.js';

// Styles
import s from './LogViewer.module.css';

// Component function
export default function NodeLogs({ node }) {

    const refresh = useRefresh();
    const logViewerRef = useRef();

    useEffect(() => {
        return node.listen('updateLogs', refresh);
    }, []);

    // Component html
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={s['log-viewer']} ref={logViewerRef}>
            {node.logs?.map((item, index) => {
                return <LogItem log={item} key={index} />
            })}
        </div>
    );
}