// Set a new listener of a particular category
export function listen(name, callback) {

    // Make path
    if (!this.listeners[name]) { this.listeners[name] = new Set(); }

    // Set listener
    const category = this.listeners[name];
    category.add(callback);

    // Return the function that kills the listener
    return () => {
        category.delete(callback);
    }
}

// Listen ONCE and then delete
export function listenOnce(name, callback) {

    // Make path
    if (!this.listeners[name]) { this.listeners[name] = new Set(); }

    // Set listener that kills itself immediately
    const category = this.listeners[name];
    category.add((...args) => { callback(...args); category.delete(callback); });
}

// Set multiple new listeners
export function listenMulti([names], callback) {

    const killFunctions = {};
    names.forEach((name) => { killFunctions[name] = this.listen(name, callback); });

    return () => {
        killFunctions.forEach((func) => { func(); });
    }
}

// Emit an event that sends a signal to a callback
export function emit(name, ...args) {
    this.listeners[name]?.forEach((callback) => { callback(...args); })
}

// Emit multiple events
export function emitMulti(names, ...args) {
    names.forEach((name) => { this.emit(name, ...args); });
}

// Trigger ALL alerts
export function emitAll(category, ...args) {

    if (!this.callbacks) { return; }

    for (const category in this.callbacks) {
        for (const id in this.callbacks[category]) {
            this.callbacks[category][id].callback(...args);
            if (this.callbacks[category][id].deleteAfter) {
                delete this.callbacks[category][id];
            }
        }
    }
}

export function attachEvents(object) {
    Object.defineProperties(object, {
        listen: { value: listen },
        listenOnce: { value: listenOnce },
        listenMulti: { value: listenMulti },
        emit: { value: emit },
        emitMulti: { value: emitMulti },
        emitAll: { value: emitAll },
        listeners: { value: {} },
    });
}