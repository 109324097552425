// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sandbox-connections {

    /* position */
    position: absolute;
    z-index: 0;

    /* dimensions */
    width: 0px;
    height: 0px;

    /* pointer events */
    pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/VisualEditor/Sandbox/SandboxConnections/SandboxConnections.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,kBAAkB;IAClB,UAAU;;IAEV,eAAe;IACf,UAAU;IACV,WAAW;;IAEX,mBAAmB;IACnB,oBAAoB;AACxB","sourcesContent":[".sandbox-connections {\n\n    /* position */\n    position: absolute;\n    z-index: 0;\n\n    /* dimensions */\n    width: 0px;\n    height: 0px;\n\n    /* pointer events */\n    pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
