// Components
import ElementHeader from '../../SubElements/Header/ElementHeader';
import ElementErrorMessage from '../../SubElements/ErrorMessage/ElementErrorMessage.js';

// Resources
import checkIcon from './check.svg';

// Styles
import './Checkbox.css';

// Component
export default function Checkbox({ element, editing }) {

    const displayValue = element.getValue() || false;

    return (
        <div className={`gui-element`} style={{width: 'auto'}}>

            <ElementHeader element={element} editing={editing} />

            <div className={`checkbox-container`}>
                <div
                    className={`checkbox`}
                    onClick={() => { element.updateValue(!displayValue); }}
                >
                    {displayValue ? <img className={`checkbox-checked-icon`} src={checkIcon}></img> : null}
                </div>
            </div>

            <ElementErrorMessage message={element.errorMessage} />
        </div>
    );
}