// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LogViewer_log-viewer__MU3aq {

    /* position */
    position: relative;

    /* dimentions */
    overflow-y: auto;
    
    /* flex options */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/VisualEditor/Sandbox/SandboxNodes/NodeComponent/NodeLogs/LogViewer/LogViewer.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,kBAAkB;;IAElB,eAAe;IACf,gBAAgB;;IAEhB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,uBAAuB;AAC3B","sourcesContent":[".log-viewer {\n\n    /* position */\n    position: relative;\n\n    /* dimentions */\n    overflow-y: auto;\n    \n    /* flex options */\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: flex-start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"log-viewer": `LogViewer_log-viewer__MU3aq`
};
export default ___CSS_LOADER_EXPORT___;
