// Modules
import { MpegAudio, MpegFrame } from '@edisonai/datatypes';
import { MicVAD } from '@ricky0123/vad-web';

// Recorder class
export class Recorder {

    constructor({ onStart, onFrame, onStop, onVadChange, options } = {}) {

        // Callbacks
        this.onStart = onStart;
        this.onFrame = onFrame;
        this.onStop = onStop;
        this.onVadChange = onVadChange;

        // How often to get chunks
        this.options = {
            bufferSize: 4096,
            numChannels: 1,
            bitRate: 128,
            frameRate: 10,
            echoCancellation: false,
            noiseSuppression: false,
            autoGainControl: false,
            ...options
        }
    }

    // Start recording
    async start() {
        return new Promise(async (resolve, reject) => {

            try {

                if (this.recording && this.shouldStart) { resolve(); return; }

                console.log('Recorder | Starting...')

                this.shouldStart = true;
                this.recording = true;

                // Use voice activity detection
                if (this.options.useVoiceDetection) {

                    this.shouldStart = false;

                    this.vad = await MicVAD.new({

                        onSpeechStart: () => {
                            if (!this.voiceActivity) {
                                this.voiceActivity = true;
                                this.shouldStart = true;
                                this.onVadChange(true);
                            }
                        },

                        onSpeechEnd: () => {
                            if (this.voiceActivity) {
                                this.voiceActivity = false;
                                this.shouldStop = true;
                                this.onVadChange(false);
                            }
                        }
                    });

                    this.vad.start();
                }

                // Get audio stream
                this.stream = await navigator.mediaDevices.getUserMedia({ audio: { ...this.options } });
                const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                const source = audioContext.createMediaStreamSource(this.stream);

                // Use ScriptProcessorNode for simplicity, AudioWorkletNode for better performance in production
                this.processor = audioContext.createScriptProcessor(this.options.bufferSize, this.options.numChannels, this.options.numChannels);

                console.log(this.processor)

                // Connect processor to the audio context
                source.connect(this.processor);
                this.processor.connect(audioContext.destination);

                // Handle audio processing
                this.processor.onaudioprocess = (audioProcessingEvent) => {
                    this.onData(audioProcessingEvent.inputBuffer);
                };

                resolve();
            }

            catch (e) {

                console.error(e);

                this.shouldStart = false;
                this.recording = false;

                if (e.message === 'Permission denied') {
                    reject(new Error('Could not access microphone (check browser settings)'));
                    return;
                }

                reject(e);
            }
        });
    }

    // Stop recording
    async stop() {
        return new Promise(async (resolve, reject) => {

            try {

                if (!this.recording && this.shouldStop) { resolve(); return; }

                console.log('Recorder | Stopping...');
                this.shouldStop = true;

                // Turn off voice detection
                if (this.vad) {
                    this.vad.destroy();
                    this.voiceActivity = false;
                    this.onVadChange(false);
                }

                // Stop the stream
                if (this.processor) { this.processor.disconnect(); }
                this.stream.getTracks().forEach(track => track.stop()); // Stop and release the stream tracks
                
                this.recording = false;

                resolve();
            }

            catch (e) {
                delete this.shouldStop;
                reject(e);
            }
        });
    }

    // When recorder recieves data
    onData(data) {

        const mpegFrame = MpegFrame(data);

        //console.log(mpegFrame);

        if (this.shouldStart) {
            delete this.shouldStart;
            this.onStart(mpegFrame);
            return;
        }

        if (this.shouldStop) {
            delete this.shouldStop;
            this.onStop(mpegFrame);
            return;
        }

        if (this.options.useVoiceDetection && !this.voiceActivity) { return; }

        this.onFrame(mpegFrame);
    }
}