// General
import Button from './Button/Button.js';
import Checkbox from './Checkbox/Checkbox.js';

// Text
import TextInput from './TextInput/TextInput';
import TextArea from './TextArea/TextArea.js';
import ChatMessage from './ChatMessage/ChatMessage.js';
import Dropdown from './Dropdown/Dropdown.js';

// Number
import NumberInput from './NumberInput/NumberInput.js';
import NumberSlider from './NumberSlider/NumberSlider.js';

// List
import List from './List/List.js'
import ChatHistory from './ChatHistory/ChatHistory.js';

// Files
import Audio from './Audio/Audio.js'
import Image from './Image/Image.js';

// Special
import ObjectViewer from './ObjectViewer/ObjectViewer.js';
import CodeEditor from './CodeEditor/CodeEditor.js';

// Actions
import SubmitActions from './SubmitActions/SubmitActions.js';

// Compendium
const elements = {

    // General
    'button': Button,
    'checkbox': Checkbox,

    // Text
    'text': TextInput,
    'textarea': TextArea,
    'chatMessage': ChatMessage,
    'dropdown': Dropdown,

    // Number
    'number': NumberInput,
    'range': NumberSlider,

    // List
    'list': List,
    'chatHistory': ChatHistory,

    // Files
    'audio': Audio,
    'image': Image,

    // Special
    'object': ObjectViewer,
    'codeEditor': CodeEditor,

    // Actions
    'submitactions': SubmitActions,
}

export { elements }