// Modules
import { useRef, useEffect } from 'react';

// Components
import ElementHeader from '../../SubElements/Header/ElementHeader.js';
import ElementErrorMessage from '../../SubElements/ErrorMessage/ElementErrorMessage.js';
import DivInput from '@Shared-Components/DivInput/DivInput.js';

// Styles
import './TextInput.css';

// Component
export default function TextInput({ element, editing }) {

    // Display value
    //----------------------------------------------------------------------------------------------------

    const displayValue = getDisplayValue();

    // Get display value as string
    function getDisplayValue() {

        try {
            return String(element.getValue() || '');
        }

        catch (e) {
            console.error(e);
            element.error(new Error(`Could not convert value to String`));
        }
    }

    // Updating value
    //----------------------------------------------------------------------------------------------------

    const inputRef = useRef(null);

    // Update value of element
    function updateValue(newValue) {
        element.updateValue(newValue);
    }

    // Element specific events
    //----------------------------------------------------------------------------------------------------

    // Attach listed events to element
    useEffect(() => {

        // Requires valid inputRef
        if (!inputRef.current) { return; }

        const functions = {};

        // Define and attach functions, add event listener
        for (const eventName in element.events) { functions[eventName] = (e) => { element.onEvent(eventName, e, element.events[eventName]); } }
        for (const eventName in element.events) { inputRef.current.addEventListener(eventName, functions[eventName]); }

        return () => {

            // Remove event listeners
            if (!inputRef.current) { return; }
            for (const eventName in element.events) { inputRef.current.removeEventListener(eventName, functions[eventName]); }
        };
    }, []);

    // Attach events
    //----------------------------------------------------------------------------------------------------

    const containerRef = useRef(null);

    function showContainerHighlight() { showElementHighlight(containerRef); }
    function hideContainerHighlight() { hideElementHighlight(containerRef); }

    function showTextAreaHighlight() { showElementHighlight(inputRef); }
    function hideTextAreaHighlight() { hideElementHighlight(inputRef); }

    // Show / hide element highlight
    function showElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'true'); } catch { } }
    function hideElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'false'); } catch { } }

    useEffect(() => {
        element.extend({ showContainerHighlight, hideContainerHighlight, showTextAreaHighlight, hideTextAreaHighlight });
    }, []);

    // Component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={`gui-element ${element.id}-container`} ref={containerRef}>

            <ElementHeader element={element} editing={editing} />

            <DivInput
                className={`text-input ${element.errorMessage ? 'text-input-error' : ''} ${element.id}-text-area`}

                ref={inputRef}
                options={element.values}
                secret={element.secret}

                style={{ ...element.style }}

                type={'text'}
                value={displayValue}
                onChange={updateValue}

                placeholder={element.placeholder || ''}

                {...element.properties}
            />

            <ElementErrorMessage message={element.errorMessage} />

            {/* custom style tags */}
            <style>{`.${element.id}-container {${element.containerStyle}}`}</style>
            <style>{`.${element.id}-text-area {${element.textareaStyle}}`}</style>

        </div>
    );
}