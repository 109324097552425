let window = undefined;
let document = undefined;

export class SandboxedClass {

    constructor() {

    }

    logWindow() {
        console.log(devicePixelRatio);
    }
}