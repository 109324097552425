// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gui-element {

    /* dimentions */
    width: 100%;
    height: auto;

    /* padding */
    padding: 6px;

    /* flex options */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-grow: 0;
}`, "",{"version":3,"sources":["webpack://./src/Shared-Components/Gui/Element/Element.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,WAAW;IACX,YAAY;;IAEZ,YAAY;IACZ,YAAY;;IAEZ,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,uBAAuB;IACvB,YAAY;AAChB","sourcesContent":[".gui-element {\n\n    /* dimentions */\n    width: 100%;\n    height: auto;\n\n    /* padding */\n    padding: 6px;\n\n    /* flex options */\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: flex-start;\n    flex-grow: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
