// Modules
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { useEffect } from 'react';

// Components
import SandboxConnection from './SandboxConnection.js';

// Styles
import './SandboxConnections.css';

// Component function
export default function SandboxConnections({ nodemap }) {

    const refresh = useRefresh();

    const nodeDelay = 1000 - (1000 * nodemap?.settings?.flowSpeed);

    useEffect(() => {
        return nodemap.connections.listen('update', refresh);
    }, []);

    // Component
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={'sandbox-connections'}>

            <svg overflow="visible">

                <style>
                    {`
                    
                    .active { animation: progressAnimation ${nodeDelay / 1000}s forwards cubic-bezier(0.3, 0, 0.7, 1); }

                    .streaming { stroke-dasharray: 30 30; animation: streamingAnimation 1s linear infinite; }
                    
                    @keyframes progressAnimation {
                        to {
                            stroke-dashoffset: 0;
                        }
                    }

                    @keyframes streamingAnimation {
                        to {
                            stroke-dashoffset: -240;
                        }
                    }
                `}
                </style>

                {
                   [...nodemap.connections].map((connection, index) => {

                        // Set listener for if connection is deleted
                        connection.listenOnce('delete', refresh);

                        return (<SandboxConnection connection={connection} key={index} />)
                    })
                }

            </svg>

        </div>
    );
}