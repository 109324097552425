// Modules
import { useState, useRef, useEffect } from 'react';
import { useRefresh } from '@Utils/hooks/useRefresh.js';

// Components
import ExpandableV from '@Shared-Components/ExpandableV/ExpandableV.js';
import ExpandableH from '@Shared-Components/ExpandableH/ExpandableH.js';
import ElementHeader from '../../SubElements/Header/ElementHeader.js';
import GuiElement from '../../Element/Element.js';
import AddElementMenu from '../../SubElements/AddElementMenu/AddElementMenu.js';

// Resources
import addIcon from '../../Icons/add.svg';

// Styles
import s from './Group.module.css';

// Component
export default function GuiGroup({ group, editing, className }) {

    // Allow group class to update group component
    //----------------------------------------------------------------------------------------------------

    const refresh = useRefresh();
    useEffect(() => { return group?.listen('update', refresh); }, [group]);

    // Show / hide gui element menu
    //----------------------------------------------------------------------------------------------------

    const [showButton, setShowButton] = useState(false);
    const [showAddElementMenu, setShowAddElementMenu] = useState(false);

    // Attach events
    //----------------------------------------------------------------------------------------------------

    const containerRef = useRef(null);

    function showContainerHighlight() { showElementHighlight(containerRef); }
    function hideContainerHighlight() { hideElementHighlight(containerRef); }

    // Show / hide element highlight
    function showElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'true'); } catch { } }
    function hideElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'false'); } catch { } }
    
    useEffect(() => {
        group?.extend({
            showContainerHighlight, hideContainerHighlight
        });
    }, [group]);

    // Component
    //----------------------------------------------------------------------------------------------------

    if (!group) { return null; }

    return (
        <div className={s[`gui-group`] + ` ${group.id}-container` + ' ' + className} ref={containerRef} style={{ ...group.style, width: group.width + 'px', padding: editing ? '4px' : undefined }} key={group.id} onMouseEnter={() => { if (editing) { setShowButton(true); } }} onMouseLeave={() => { if (editing) { setShowButton(false); } }}>

            <ElementHeader element={group} editing={editing} />

            {/* the elements belonging to the group */}
            <ExpandableV className={s[`gui-group-elements`] + ` ${group.id}-elements`} expanded={group.expanded !== false} time={350} style={{ border: editing ? 'solid 1px rgba(255, 255, 255, 0.03)' : undefined, flexDirection: group.direction === 'horizontal' ? 'row' : 'column' }}>

                {/* Map through elements */}
                {Object.values(group.elements || {}).map((element) => {
                    if (element.type === 'group') { return (<GuiGroup group={element} editing={editing} key={element.id} />); }
                    return <GuiElement element={element} editing={editing} key={element.id} />
                })}

                {/* button for adding elements + menu popup */}
                {editing ?
                    <>
                        <div className={s['gui-group-add-element-button-and-menu-container']}>
                        <img className={s['gui-group-add-element-button']} src={addIcon} onClick={(e) => { e.stopPropagation(); setShowAddElementMenu(true); }} style={{ width: showButton || group.elements?.length < 1 ? undefined : '0px', height: showButton || group.elements?.length < 1 ? undefined : '0px' }}></img>
                            {showAddElementMenu ? <AddElementMenu addElement={group.addElement.bind(group)} hideMenu={() => { setShowAddElementMenu(false); }} /> : null}
                        </div>
                    </>
                    : null}

            </ExpandableV>

            {/* custom style tags */}
            <style>{`.${group.id}-container {${group.containerStyle}}`}</style>
            <style>{`.${group.id}-elements {${group.elementsContainerStyle}}`}</style>
        </div>
    );
}