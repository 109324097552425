// Modules
import { useRef, useEffect, useDebugValue } from 'react';

// State
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { VisualEditorState } from '@State/VisualEditor/VisualEditorState.js';

// Styles
import s from './NodeStateBar.module.css';

// Component function
export default function NodeStateBar({ node }) {

    const refresh = useRefresh();

    useEffect(() => {
        return node.listen('updateState', refresh);
    }, []);

    // Determine transition style
    //----------------------------------------------------------------------------------------------------

    const backgroundColor = useRef(null);

    const paddingLeft = useRef(null);
    const paddingLeftTransition = useRef(null);

    const paddingRight = useRef(null);
    const paddingRightTransition = useRef(null);
    const paddingRightMax = useRef(null);

    switch (node.state) {

        // If node is processing
        case ('processing'): {

            backgroundColor.current = undefined;

            paddingLeft.current = '0%';
            paddingLeftTransition.current = 'width linear 0ms';

            paddingRight.current = '0%';
            paddingRightTransition.current = `width linear ${node.eta || 1500}ms`;
            paddingRightMax.current = '100%';

            break;
        }

        // If node is streaming
        case ('streaming'): {

            backgroundColor.current = undefined;

            paddingLeft.current = '0%';
            paddingLeftTransition.current = 'width ease 0ms';

            paddingRight.current = '0%';
            paddingRightMax.current = '0%';
            paddingRightTransition.current = `width ease ${node.eta || 1500}ms`;

            break;
        }

        // If node is streaming
        case ('done'): {

            backgroundColor.current = undefined;

            paddingLeft.current = '100%';
            paddingLeftTransition.current = 'width ease 350ms';

            paddingRight.current = '0%';
            paddingRightMax.current = '0%';
            paddingRightTransition.current = `max-width ease 350ms`;

            break;
        }

        case ('error'): {

            backgroundColor.current = undefined;

            paddingLeft.current = '100%';
            paddingLeftTransition.current = 'width ease 350ms';

            paddingRight.current = '0%';
            paddingRightMax.current = '0%';
            paddingRightTransition.current = `max-width ease 350ms`;

            break;
        }

        // If node is idle or state is null
        default: {

            backgroundColor.current = node.color || 'white';

            paddingLeft.current = '0%';
            paddingLeftTransition.current = 'width linear 0ms';

            paddingRight.current = '100%';
            paddingRightMax.current = '100%';
            paddingRightTransition.current = `width linear 0ms`;
        }
    }

    const displayColor = node.color || 'white';

    const streamingBackgroundImage = `linear-gradient(90deg,
        transparent 6.25%, ${displayColor} 6.25%,
        ${displayColor} 12.5%, transparent 12.5%,
        transparent 18.75%, ${displayColor} 18.75%,
        ${displayColor} 25%, transparent 25%,
        transparent 31.25%, ${displayColor} 31.25%,
        ${displayColor} 37.5%, transparent 37.5%,
        transparent 43.75%, ${displayColor} 43.75%,
        ${displayColor} 50%, transparent 50%,
        transparent 56.25%, ${displayColor} 56.25%,
        ${displayColor} 62.5%, transparent 62.5%,
        transparent 68.75%, ${displayColor} 68.75%,
        ${displayColor} 75%, transparent 75%,
        transparent 81.25%, ${displayColor} 81.25%,
        ${displayColor} 87.5%, transparent 87.5%,
        transparent 93.75%, ${displayColor} 93.75%,
        ${displayColor} 100%, transparent 100%)`

    // Component html
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={s['node-state-bar']} style={{ background: backgroundColor.current }}>

            <div style={{ width: paddingLeft.current, transition: paddingLeftTransition.current }}></div>

            <div className={s['node-state-bar-fill']}
                style={{
                    background: node.state !== 'streaming' ? displayColor : undefined,
                    backgroundSize: node.state === 'streaming' ? '200% 100%' : undefined,
                    backgroundImage: node.state === 'streaming' ? streamingBackgroundImage : undefined,
                    animation: node.state === 'streaming' ? `${s['marching-ants']} 1.5s linear infinite` : undefined,
                }}
            >
            </div>

            <div style={{ maxWidth: paddingRightMax.current, width: paddingRight.current, transition: paddingRightTransition.current }}></div>
        </div >
    );
}