// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sandbox_sandbox__mnokQ {

    /* position */
    position: absolute;
    left: 0px;
    top: 0px;

    /* flex options */
    display: flex;
    justify-content: center;
    align-items: center;

    /* dimentions */
    width: 100%;
    height: 100%;
    overflow: hidden;

}   .Sandbox_sandbox__mnokQ:focus {
    outline: none;
    
}   .Sandbox_sandbox__mnokQ::before {

     opacity: 0.5;
}

.Sandbox_dragging__ZQ9Kr {
    transition: background-color 0.25s;
    background-color: rgba(255, 255, 255, 0.05);
}

    .Sandbox_sandbox-anchor__GbR0W {
            
        /* position */
        position: absolute;

        /* dimensions */
        width: 0px;
        height: 0px;
        overflow: visible;

        /* background */
        background: transparent;
    }

        .Sandbox_sandbox-center__VAZjh {

            /* position */
            position: absolute;
            left: 0;
            top: 0;

            /* dimensions */
            width: 0px;
            height: 0px;
            overflow: visible;

            /* background */
            background: transparent;
        }`, "",{"version":3,"sources":["webpack://./src/VisualEditor/Sandbox/Sandbox.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,QAAQ;;IAER,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,gBAAgB;;AAEpB,IAAI;IACA,aAAa;;AAEjB,IAAI;;KAEC,YAAY;AACjB;;AAEA;IACI,kCAAkC;IAClC,2CAA2C;AAC/C;;IAEI;;QAEI,aAAa;QACb,kBAAkB;;QAElB,eAAe;QACf,UAAU;QACV,WAAW;QACX,iBAAiB;;QAEjB,eAAe;QACf,uBAAuB;IAC3B;;QAEI;;YAEI,aAAa;YACb,kBAAkB;YAClB,OAAO;YACP,MAAM;;YAEN,eAAe;YACf,UAAU;YACV,WAAW;YACX,iBAAiB;;YAEjB,eAAe;YACf,uBAAuB;QAC3B","sourcesContent":[".sandbox {\n\n    /* position */\n    position: absolute;\n    left: 0px;\n    top: 0px;\n\n    /* flex options */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    /* dimentions */\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n\n}   .sandbox:focus {\n    outline: none;\n    \n}   .sandbox::before {\n\n     opacity: 0.5;\n}\n\n.dragging {\n    transition: background-color 0.25s;\n    background-color: rgba(255, 255, 255, 0.05);\n}\n\n    .sandbox-anchor {\n            \n        /* position */\n        position: absolute;\n\n        /* dimensions */\n        width: 0px;\n        height: 0px;\n        overflow: visible;\n\n        /* background */\n        background: transparent;\n    }\n\n        .sandbox-center {\n\n            /* position */\n            position: absolute;\n            left: 0;\n            top: 0;\n\n            /* dimensions */\n            width: 0px;\n            height: 0px;\n            overflow: visible;\n\n            /* background */\n            background: transparent;\n        }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sandbox": `Sandbox_sandbox__mnokQ`,
	"dragging": `Sandbox_dragging__ZQ9Kr`,
	"sandbox-anchor": `Sandbox_sandbox-anchor__GbR0W`,
	"sandbox-center": `Sandbox_sandbox-center__VAZjh`
};
export default ___CSS_LOADER_EXPORT___;
