// Prepare then serialize value
export async function stringify(value) {

    // Serialize then stringify value
    const serialized = await hibernate(value);
    const string = JSON.stringify(serialized);

    return string;
}

// Prepare for serialization
export async function hibernate(value) {

    // Return anything that is not an object
    if (!value) { return value; }
    if (typeof value !== 'object') { return value; }

    // Hibernate self before calling children
    if (typeof value.hibernate === 'function') { value = await value.hibernate(); }
    else if (typeof value.toJSON === 'function') { value = value.toJSON(); }

    // Spread value if no hibernation function
    else if (Array.isArray(value)) { value = [...value]; }
    else if (typeof value === 'object') { value = { ...value }; }
    else { console.warn(value); throw new Error('Could not serialize value');}

    // Just in case hibernate returns a primitive instead of an object/array
    if (!value) { return value; }
    if (typeof value !== 'object') { return value; }

    // Hibernate all children at once
    const promises = [];
    for (const key in value) { promises.push(hibernate(value[key]).then(result => ({ key, result }))); }
    const results = await Promise.all(promises);
    results.forEach(({ key, result }) => { value[key] = result; });

    return value;
}

// Revive hibernated objects
export async function revive(value, classes) {

    // Return anything that is not an object
    if (!value) { return value; }
    else if (typeof value !== 'object') { return value; }

    // Spread value
    else if (value instanceof Array) { value = [...value]; }
    else if (value instanceof Object) { value = { ...value }; }

    // Revive all children at once
    const promises = [];
    for (const key in value) { promises.push(revive(value[key], classes).then(result => ({ key, result }))); }
    const results = await Promise.all(promises);
    results.forEach(({ key, result }) => { value[key] = result; });

    // Finally revive self
    if (classes[value._revive]) {
        return await classes[value._revive].revive(value);
    }

    return value;
}