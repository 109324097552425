// Modules
import { useEffect } from 'react';

// State
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { VisualEditorState } from '@State/VisualEditor/VisualEditorState.js';

// Components
import NodeOutput from './NodeOutput/NodeOutput.js';

// Resources
import addIcon from './addIcon.svg';

// Styles
import s from './NodeOutputs.module.css';

// Component function
export default function NodeOutputs({ node }) {

    const refresh = useRefresh();

    useEffect(() => {
        return node.listen('updateOutputs', refresh);
    }, []);

    // Component html
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={s['node-outputs']}>

            {node.settings?.outputs?.canAdd ? <div className={s['node-add-output-button']}> </div> : null}

            {/* Map through outputs */}
            {
                node.outputs?.map((output, index) => {
                    return (<NodeOutput output={output} key={index} />);
                })
            }

            {/* Add output button */}
            {node.settings?.outputs?.canAdd ? <img className={s['node-add-output-button']} src={addIcon} onClick={node.addOutput.bind(node)} title={'Add output'} onDragStart={(e) => { e.preventDefault(); }}></img> : null}

        </div>
    );
}