// Modules
import modular from '@edisonai/modular';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

// State
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { CognitoSessionState } from '@State/CognitoSession/CognitoSessionState.js';
import { VisualEditorState } from '@State/VisualEditor/VisualEditorState.js';

// Components
import ProgressIndicator from '@Shared-Components/ProgressIndicator/ProgressIndicator.js';
import Sandbox from './Sandbox/Sandbox.js';
import TopBar from './TopBar/TopBar.js';
import LeftMenu from './LeftMenu/LeftMenu.js';
import RightMenu from './RightMenu/RightMenu.js';
import Notifications from './Notifications/Notifications.js';

// Styles
import styles from './VisualEditor.module.css';
import fadeIn from '@Styles/Animations/FadeIn/FadeIn.module.css';

// Component function
export default function VisualEditor() {

    // State management
    //------------------------------------------------------------

    const refresh = useRefresh();

    useEffect(() => { return VisualEditorState.listen('update', refresh); }, []);
    useEffect(() => { return CognitoSessionState.listen('update', refresh); }, []);

    // Log in user
    //------------------------------------------------------------

    // React to CognitoSession change
    useEffect(() => {

        const { loggedIn, invalid } = CognitoSessionState;

        if (!loggedIn) { VisualEditorState.setLoadingProgress({ text: 'Getting Session', progress: 1, time: 1000 }); }
        if (invalid) { window.location.href = `${process.env.REACT_APP_MAIN_WEBSITE_ORIGIN}/login?on_success=${window.location.toString()}`; return; }

    }, [CognitoSessionState.loggedIn, CognitoSessionState.invalid]);

    // Load nodemap
    //------------------------------------------------------------

    const { id } = useParams();
    const [loadedNodemap, setLoadedNodemap] = useState(false);

    useEffect(() => {

        // If we're not logged in or have already done this before, return
        if (!CognitoSessionState.loggedIn) { return; }
        if (loadedNodemap) { return; }

        loadNodemap(id);

    }, [CognitoSessionState.loggedIn]);

    // Load nodemap while tracking progress
    async function loadNodemap(id) {

        try {

            await VisualEditorState.loadNodemap(id, {
                availableNodes: { ...VisualEditorState.availableNodes },
                edisonAiApiKey: CognitoSessionState.userData?.apiKeys[0]?.value
            }, (progress) => {
                VisualEditorState.setLoadingProgress({ text: 'Loading Nodemap', progress: progress.progress, percentage: true, time: 1 });
            });

            setLoadedNodemap(true);
        }

        catch (e) {
            console.error(e);
            console.error('Could not load nodemap');
        }
    }

    // Connect to dev server
    //------------------------------------------------------------

    useEffect(() => {

        const client = new WebSocket('ws://localhost:9000/node');

        client.onopen = () => {
            console.warn('Connected to node dev server');
        }

        client.onerror = (e) => {
            console.error(e);
        }

        client.onmessage = async ({ data: text }) => {
            const blob = new Blob([text], { type: 'text/javascript' });
            const file = new File([blob], '', { type: 'text/javascript' });
            const module = await VisualEditorState.addNodeModule(file, true);
        }

    }, []);

    // Component HTML
    //------------------------------------------------------------

    return (
        <div className={styles['visual-editor']}>

            {!VisualEditorState.nodemap.instance ? <ProgressIndicator {...VisualEditorState.loadingProgress} className={fadeIn['one-s']} /> : null}

            {VisualEditorState.nodemap.instance ?
                <>
                    <Sandbox nodemap={VisualEditorState.nodemap.instance} />
                    <TopBar />
                    <LeftMenu />
                    <RightMenu />
                    <Notifications />
                </>
                : null}
        </div>
    );
}