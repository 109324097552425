// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FadeIn_p5s__rPi4k {
    animation: FadeIn_fadeIn__O12ps ease 0.5s;
    -webkit-animation: FadeIn_fadeIn__O12ps ease 0.5s;
    -moz-animation: FadeIn_fadeIn__O12ps ease 0.5s;
    -o-animation: FadeIn_fadeIn__O12ps ease 0.5s;
}

.FadeIn_p75s__pjAyo {
    animation: FadeIn_fadeIn__O12ps ease 0.75s;
    -webkit-animation: FadeIn_fadeIn__O12ps ease 0.75s;
    -moz-animation: FadeIn_fadeIn__O12ps ease 0.75s;
    -o-animation: FadeIn_fadeIn__O12ps ease 0.75s;
}

.FadeIn_one-s__zX-fj {
    animation: FadeIn_fadeIn__O12ps ease 1s;
    -webkit-animation: FadeIn_fadeIn__O12ps ease 1s;
    -moz-animation: FadeIn_fadeIn__O12ps ease 1s;
    -o-animation: FadeIn_fadeIn__O12ps ease 1s;
}

.FadeIn_one-p5s__3au3Y {
    animation: FadeIn_fadeIn__O12ps ease 1.5s;
    -webkit-animation: FadeIn_fadeIn__O12ps ease 1.5s;
    -moz-animation: FadeIn_fadeIn__O12ps ease 1.5s;
    -o-animation: FadeIn_fadeIn__O12ps ease 1.5s;
}

.FadeIn_two-s__dGkxk {
    animation: FadeIn_fadeIn__O12ps ease 2s;
    -webkit-animation: FadeIn_fadeIn__O12ps ease 2s;
    -moz-animation: FadeIn_fadeIn__O12ps ease 2s;
    -o-animation: FadeIn_fadeIn__O12ps ease 2s;
}

@keyframes FadeIn_fadeIn__O12ps {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}`, "",{"version":3,"sources":["webpack://./src/Styles/Animations/FadeIn/FadeIn.module.css"],"names":[],"mappings":"AAAA;IACI,yCAA2B;IAC3B,iDAAmC;IACnC,8CAAgC;IAChC,4CAA8B;AAClC;;AAEA;IACI,0CAA4B;IAC5B,kDAAoC;IACpC,+CAAiC;IACjC,6CAA+B;AACnC;;AAEA;IACI,uCAAyB;IACzB,+CAAiC;IACjC,4CAA8B;IAC9B,0CAA4B;AAChC;;AAEA;IACI,yCAA2B;IAC3B,iDAAmC;IACnC,8CAAgC;IAChC,4CAA8B;AAClC;;AAEA;IACI,uCAAyB;IACzB,+CAAiC;IACjC,4CAA8B;IAC9B,0CAA4B;AAChC;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;AACJ","sourcesContent":[".p5s {\n    animation: fadeIn ease 0.5s;\n    -webkit-animation: fadeIn ease 0.5s;\n    -moz-animation: fadeIn ease 0.5s;\n    -o-animation: fadeIn ease 0.5s;\n}\n\n.p75s {\n    animation: fadeIn ease 0.75s;\n    -webkit-animation: fadeIn ease 0.75s;\n    -moz-animation: fadeIn ease 0.75s;\n    -o-animation: fadeIn ease 0.75s;\n}\n\n.one-s {\n    animation: fadeIn ease 1s;\n    -webkit-animation: fadeIn ease 1s;\n    -moz-animation: fadeIn ease 1s;\n    -o-animation: fadeIn ease 1s;\n}\n\n.one-p5s {\n    animation: fadeIn ease 1.5s;\n    -webkit-animation: fadeIn ease 1.5s;\n    -moz-animation: fadeIn ease 1.5s;\n    -o-animation: fadeIn ease 1.5s;\n}\n\n.two-s {\n    animation: fadeIn ease 2s;\n    -webkit-animation: fadeIn ease 2s;\n    -moz-animation: fadeIn ease 2s;\n    -o-animation: fadeIn ease 2s;\n}\n\n@keyframes fadeIn {\n    from {\n        opacity: 0;\n    }\n\n    to {\n        opacity: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"p5s": `FadeIn_p5s__rPi4k`,
	"fadeIn": `FadeIn_fadeIn__O12ps`,
	"p75s": `FadeIn_p75s__pjAyo`,
	"one-s": `FadeIn_one-s__zX-fj`,
	"one-p5s": `FadeIn_one-p5s__3au3Y`,
	"two-s": `FadeIn_two-s__dGkxk`
};
export default ___CSS_LOADER_EXPORT___;
