// Modules 
import { CognitoSession as CognitoSessionClass } from '@edisonai/cognito';

// Instantiate cognito session
export const CognitoSessionState = new CognitoSessionClass({
    userPool: process.env.REACT_APP_AWS_COGNITO_USERS_POOL_ID,
    client: process.env.REACT_APP_AWS_COGNITO_USERS_POOL_CLIENT_ID,
    cookieDomain: process.env.REACT_APP_COGNITO_COOKIE_DOMAIN,
    validateTokens: `${process.env.REACT_APP_MAIN_WEBSITE_ORIGIN}/api/auth/cognito/validate-jwt`,
    generateTokens: '' // Not needed for now (endpoint for generating new tokens)
});