// Set to store unique functions to be executed
const functionsToExecute = new Set();
let scheduled = false;

// Function to be exported
export function frameThrottle(func) {

    // Add the function to the Set only if it's not already present
    if (!functionsToExecute.has(func)) {
        functionsToExecute.add(func);
/*  */
        // Schedule execution if not already scheduled
        if (!scheduled) {
            scheduled = true;

            requestAnimationFrame(() => {
                // Execute each function in the Set
                functionsToExecute.forEach((func) => {
                    func();
                    // Remove the function from the Set after execution
                    functionsToExecute.delete(func);
                });

                // Reset the scheduled flag
                scheduled = false;
            });
        }
    }
}