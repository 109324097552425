// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-area {

    /* dimentions */
    max-width: 100%;
    min-width: 100%;
    height: auto;
    resize: vertical;

    /* margins */
    margin: 0px;

    /* padding */
    padding: 10px;

    /* border */
    border: none;
    outline: solid;
    outline-width: 1px;
    outline-color: var(--distinguish-0p05);
    border-radius: 4px;

    /* background */
    background: var(--distinguish-0p03);

    /* font */
    color: var(--strong-text);

    /* pre-hover */
    transition: background-color 0.2s, outline 0.2s;
    
}   .text-area:hover {

    background: var(--distinguish-0p05);
    outline-color: var(--distinguish-0p1);

}   .text-area:focus {

    background: var(--distinguish-0p05);
    outline-color: var(--distinguish-0p1);
    
}   .text-area::placeholder {

    /* font */
    color: var(--weak-text);
    opacity: 0.5;

}   .text-area-error {

    /* border */
    outline-color: rgba(255, 128, 128, 0.75) !important;
}`, "",{"version":3,"sources":["webpack://./src/Shared-Components/Gui/Elements/TextArea/TextArea.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,eAAe;IACf,eAAe;IACf,YAAY;IACZ,gBAAgB;;IAEhB,YAAY;IACZ,WAAW;;IAEX,YAAY;IACZ,aAAa;;IAEb,WAAW;IACX,YAAY;IACZ,cAAc;IACd,kBAAkB;IAClB,sCAAsC;IACtC,kBAAkB;;IAElB,eAAe;IACf,mCAAmC;;IAEnC,SAAS;IACT,yBAAyB;;IAEzB,cAAc;IACd,+CAA+C;;AAEnD,IAAI;;IAEA,mCAAmC;IACnC,qCAAqC;;AAEzC,IAAI;;IAEA,mCAAmC;IACnC,qCAAqC;;AAEzC,IAAI;;IAEA,SAAS;IACT,uBAAuB;IACvB,YAAY;;AAEhB,IAAI;;IAEA,WAAW;IACX,mDAAmD;AACvD","sourcesContent":[".text-area {\n\n    /* dimentions */\n    max-width: 100%;\n    min-width: 100%;\n    height: auto;\n    resize: vertical;\n\n    /* margins */\n    margin: 0px;\n\n    /* padding */\n    padding: 10px;\n\n    /* border */\n    border: none;\n    outline: solid;\n    outline-width: 1px;\n    outline-color: var(--distinguish-0p05);\n    border-radius: 4px;\n\n    /* background */\n    background: var(--distinguish-0p03);\n\n    /* font */\n    color: var(--strong-text);\n\n    /* pre-hover */\n    transition: background-color 0.2s, outline 0.2s;\n    \n}   .text-area:hover {\n\n    background: var(--distinguish-0p05);\n    outline-color: var(--distinguish-0p1);\n\n}   .text-area:focus {\n\n    background: var(--distinguish-0p05);\n    outline-color: var(--distinguish-0p1);\n    \n}   .text-area::placeholder {\n\n    /* font */\n    color: var(--weak-text);\n    opacity: 0.5;\n\n}   .text-area-error {\n\n    /* border */\n    outline-color: rgba(255, 128, 128, 0.75) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
