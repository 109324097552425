// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.variable-editor {

    /* dimentions */
    background: var(--distinguish-0p03) !important;
    border: none !important;
    outline: none !important;
    max-height: 20px !important;

    padding: 0px !important;
    width: min-content !important;

}   .variable-editor:hover {

    outline: none
}   .variable-editor::placeholder {

    color: var(--weak-text);
}

.click-to-edit-icon {
    display: none;
    opacity: 1;
}

.click-to-remove-icon {
    opacity: 0.15;
    filter: grayscale(1);
    transition: all 0.25s;
}   .click-to-remove-icon:hover {
    opacity: 1;
}

.click-to-add-icon {
    opacity: 0.25;
    transition: all 0.25s;
}   .click-to-add-icon:hover {
    opacity: 1;
}

.edit-cancel {
    display: none;
}

.edit-check {
    display: none;
}

.detected {
    position: absolute;
    top: 4px;
    right: -8px;
    display: block;
}`, "",{"version":3,"sources":["webpack://./src/Shared-Components/Gui/Elements/ObjectViewer/ObjectViewer.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,8CAA8C;IAC9C,uBAAuB;IACvB,wBAAwB;IACxB,2BAA2B;;IAE3B,uBAAuB;IACvB,6BAA6B;;AAEjC,IAAI;;IAEA;AACJ,IAAI;;IAEA,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,UAAU;AACd;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,qBAAqB;AACzB,IAAI;IACA,UAAU;AACd;;AAEA;IACI,aAAa;IACb,qBAAqB;AACzB,IAAI;IACA,UAAU;AACd;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,cAAc;AAClB","sourcesContent":[".variable-editor {\n\n    /* dimentions */\n    background: var(--distinguish-0p03) !important;\n    border: none !important;\n    outline: none !important;\n    max-height: 20px !important;\n\n    padding: 0px !important;\n    width: min-content !important;\n\n}   .variable-editor:hover {\n\n    outline: none\n}   .variable-editor::placeholder {\n\n    color: var(--weak-text);\n}\n\n.click-to-edit-icon {\n    display: none;\n    opacity: 1;\n}\n\n.click-to-remove-icon {\n    opacity: 0.15;\n    filter: grayscale(1);\n    transition: all 0.25s;\n}   .click-to-remove-icon:hover {\n    opacity: 1;\n}\n\n.click-to-add-icon {\n    opacity: 0.25;\n    transition: all 0.25s;\n}   .click-to-add-icon:hover {\n    opacity: 1;\n}\n\n.edit-cancel {\n    display: none;\n}\n\n.edit-check {\n    display: none;\n}\n\n.detected {\n    position: absolute;\n    top: 4px;\n    right: -8px;\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
