// Styles
import s from './ElementErrorMessage.module.css';

export default function ElementErrorMessage({ message }) {

    // Component
    //----------------------------------------------------------------------------------------------------

    if (!message) { return null; }

    return (
        <div className={s['gui-element-error-message']}>{message}</div>
    );
}