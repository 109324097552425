// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SaveMenu_save-menu__mWYBM {

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

    .SaveMenu_save-menu-button__zmoxH {

        /* dimentions */
        width: 24px;
        height: 24px;

        /* margins */
        margin-left: 2px;
        margin-right: 2px;

        /* pre-hover */
        opacity: 0.35;
        transition: all 0.25s;

    } .SaveMenu_save-menu-button__zmoxH:hover {

        /* post-hover */
        cursor: pointer;
        opacity: 1;
    }`, "",{"version":3,"sources":["webpack://./src/VisualEditor/TopBar/SaveMenu/SaveMenu.module.css"],"names":[],"mappings":"AAAA;;IAEI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB;;IAEI;;QAEI,eAAe;QACf,WAAW;QACX,YAAY;;QAEZ,YAAY;QACZ,gBAAgB;QAChB,iBAAiB;;QAEjB,cAAc;QACd,aAAa;QACb,qBAAqB;;IAEzB,EAAE;;QAEE,eAAe;QACf,eAAe;QACf,UAAU;IACd","sourcesContent":[".save-menu {\n\n    /* flex options */\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n}\n\n    .save-menu-button {\n\n        /* dimentions */\n        width: 24px;\n        height: 24px;\n\n        /* margins */\n        margin-left: 2px;\n        margin-right: 2px;\n\n        /* pre-hover */\n        opacity: 0.35;\n        transition: all 0.25s;\n\n    } .save-menu-button:hover {\n\n        /* post-hover */\n        cursor: pointer;\n        opacity: 1;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"save-menu": `SaveMenu_save-menu__mWYBM`,
	"save-menu-button": `SaveMenu_save-menu-button__zmoxH`
};
export default ___CSS_LOADER_EXPORT___;
