// Modules
import { useState, useRef, useEffect } from 'react';
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { MpegAudio } from '@edisonai/datatypes';
import { Player } from '../Utils/Player.js';

// Resources
import playIcon from './Icons/play.svg';
import pauseIcon from './Icons/pause.svg';
import downloadIcon from './Icons/download.svg';

// Styles
import s from './AudioPlayer.module.css';

// Component function
export default function AudioPlayer({ source, options, element }) {

    const refresh = useRefresh();

    const player = useRef(null)
    const players = useRef(new Map());

    const containerRef = useRef(null);
    const progressRef = useRef(null);
    const scrubRef = useRef(null);

    // Manage source
    //---------------------------------------------------------------------------------------------------

    // Playing
    //----------------------------------------------------------------------------------------------------

    useEffect(() => {

        players.current.forEach(async (player) => {
            await player.stop();
        });

        loadPlayer();
        refresh();

    }, [source]);

    // Load player as promise
    async function loadPlayer() {
        return new Promise((resolve, reject) => {

            try {

                if (players.current.get(source)) {
                    player.current = players.current.get(source);
                    resolve();
                    return;
                }

                players.current.set(source, new Player({
                    source,
                    autoPlay: options?.autoPlay || element?.autoPlay,
                    onReady: () => { resolve(); },
                    onStart: () => { refresh(); },
                    onStop: () => { refresh(); },
                    onProgress: () => { reflectProgress(); },
                    onError: (e) => { element.error(e); },
                }));

                player.current = players.current.get(source);
            }
            catch (e) {
                reject(e);
            }
        });
    }

    async function togglePlay() {

        try {

            element.clearError();

            if (!player.current) { return; }
            if (!player.current?.playing) { await startPlayer(); }
            else { await stopPlayer(); }
        }

        catch (e) {
            removeProgressCursor();
            element.error(e);
        }
    }

    // Start the player
    async function startPlayer() {

        try {
            if (!source) { return; }
            if (!player.current) { return; }
            await player.current?.start();
        }

        catch (e) {
            console.error(e);
            element.error(new Error('Could not start player'));
        }
    }

    // Stop the player
    async function stopPlayer() {

        try {
            if (!source) { return; }
            if (!player.current) { return; }
            await player.current.stop();
        }

        catch (e) {
            console.error(e);
            element.error(new Error('Could not stop player'));
        }
    }

    async function scrubTo(newProgress) {
        try {
            if (!source) { return; }
            if (!player.current) { return; }
            await player.current.scrub(newProgress, true);
        }

        catch (e) {
            console.error(e);
            element.error(new Error('Could not scrub to location'));
        }
    }

    function addProgressCursor() { document.body.classList.add('cursor-progress'); }
    function removeProgressCursor() { document.body.classList.remove('cursor-progress'); }

    // Progress
    //--------------------------------------------------------------------------------

    // Display current progress
    function reflectProgress() {

        if (!progressRef.current) { return; }

        progressRef.current.style.transitionDuration = '';
        progressRef.current.style.width = (100 * (player.current?.progress || 0)) + '%';
    }

    // Make progress bar respond instantly
    function onMouseEnter(e) {
        if (!scrubRef.current) { return; }
    }

    // Reset progress with animation
    function onMouseLeave(e) {
        if (!scrubRef.current) { return; }
        scrubRef.current.style.width = '0%';
    }

    function onMouseMove(e) {

        if (!containerRef.current || !scrubRef.current) { return; }

        const { left, width } = containerRef.current.getBoundingClientRect();
        const { clientX } = e;

        scrubRef.current.style.width = (100 * ((clientX - left) / width)) + '%';
    }

    function onClick(e) {

        if (!containerRef.current || !progressRef.current) { return; }

        const { left, width } = containerRef.current.getBoundingClientRect();
        const { clientX } = e;

        const newProgress = ((clientX - left) / width);

        scrubTo(newProgress);
    }

    useEffect(() => {
        if (!progressRef.current) { return; }
        progressRef.current.style.width = (100 * player.current?.progress) + '%';
    }, []);

    reflectProgress();

    // Download file
    //--------------------------------------------------------------------------------

    function downloadFile() {

        console.log(MpegAudio(source))

        const file = MpegAudio(source).toFile();
        const url = URL.createObjectURL(file);

        // Create a temporary anchor element for the download
        let a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'audiofile.mp3'; // You may want to generate a filename based on some logic

        // Append anchor to body, click to download, and remove anchor
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Release the URL
        URL.revokeObjectURL(url);
    }

    // Component HTML
    //--------------------------------------------------------------------------------

    return (
        <div className={s['audio-player']}>

            <div className={`${s['play-button']} ${player.current?.playing ? s['playing'] : ''}`} onClick={togglePlay}>
                <img className={s['play-button-icon']} src={player.current?.playing ? pauseIcon : playIcon} style={{ left: !player.current?.playing ? '1px' : undefined }}></img>
            </div>

            <div className={s['progress-container']} ref={containerRef} {...{ onMouseEnter, onMouseLeave, onMouseMove, onClick }}>
                <div className={s['progress-bar']} ref={progressRef} style={{ width: (100 * player.current?.progress) + '%' }}></div>
                <div className={s['progress-bar']} ref={scrubRef} style={{ width: '0%', opacity: '0.25' }}></div>
            </div>

            <div className={`${s['download-button']}`} onClick={downloadFile}>
                <img className={s['download-button-icon']} src={downloadIcon}></img>
            </div>
        </div>
    );
}