// Modules
import { useRef, useEffect } from 'react';
import { NumberMessage } from '@edisonai/nodemap';

// Components
import ElementHeader from '../../SubElements/Header/ElementHeader.js';
import ElementErrorMessage from '../../SubElements/ErrorMessage/ElementErrorMessage.js';

// Styles
import './NumberInput.css';

// Component
export default function NumberInput({ element, editing }) {

    // Display value
    //----------------------------------------------------------------------------------------------------

    const displayValue = getDisplayValue();

    // Get display value as string
    function getDisplayValue() {

        try {

            const value = element.getValue();

            if (value === undefined) { return ''; }
            if (value === '') { return ''; }

            // Parse the roundValue
            const roundValue = parseFloat(element.step);

            // Determine the number of decimal places
            const decimalPlaces = !isNaN(roundValue) && roundValue.toString().includes('.')
                ? roundValue.toString().split('.')[1].length
                : 0;

            // Format the number to the determined decimal places
            return (value || 0).toFixed(decimalPlaces);
        }

        catch (e) {
            console.error(e);
            element.error(new Error(`Could not convert value to Number`));
            return '';
        }
    }


    // Updating value
    //----------------------------------------------------------------------------------------------------

    const inputRef = useRef(null);

    // Update value of element
    function updateValue() {

        const min = typeof element.minValue === 'number' ? element.minValue : -Infinity;
        const max = typeof element.maxValue === 'number' ? element.maxValue : Infinity;

        if (inputRef.current.value === '') { element.updateValue(''); return; }

        // Get input value and make new StringMessage
        const newNumber = Math.min(Math.max(Number(inputRef.current.value), min), max);

        element.updateValue(newNumber);
    }

    // Attach events
    //----------------------------------------------------------------------------------------------------

    const containerRef = useRef(null);

    function showContainerHighlight() { showElementHighlight(containerRef); }
    function hideContainerHighlight() { hideElementHighlight(containerRef); }

    function showTextAreaHighlight() { showElementHighlight(inputRef); }
    function hideTextAreaHighlight() { hideElementHighlight(inputRef); }

    // Show / hide element highlight
    function showElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'true'); } catch { } }
    function hideElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'false'); } catch { } }

    useEffect(() => {
        element.extend({ showContainerHighlight, hideContainerHighlight, showTextAreaHighlight, hideTextAreaHighlight });
    }, []);

    // Element specific events
    //----------------------------------------------------------------------------------------------------

    // Attach listed events to element
    useEffect(() => {

        // Requires valid inputRef
        if (!inputRef.current) { return; }

        const functions = {};

        // Define and attach functions, add event listener
        for (const eventName in element.events) { functions[eventName] = (e) => { element.onEvent(eventName, e, element.events[eventName]); } }
        for (const eventName in element.events) { inputRef.current.addEventListener(eventName, functions[eventName]); }

        return () => {

            // Remove event listeners
            if (!inputRef.current) { return; }
            for (const eventName in element.events) { inputRef.current.removeEventListener(eventName, functions[eventName]); }
        };
    }, []);

    // Component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={`gui-element ${element.id}-container`} ref={containerRef}>

            <ElementHeader element={element} editing={editing} />

            <input
                className={`text-input ${element.errorMessage ? 'text-input-error' : ''} ${element.id}-text-input`}
                ref={inputRef}

                type='number'
                value={displayValue || ''}
                onChange={updateValue}

                min={element.minValue}
                max={element.maxValue}
                step={element.step}

                placeholder={element.placeholder || ''}
            ></input>

            <ElementErrorMessage message={element.errorMessage} />

            {/* custom style tags */}
            <style>{`.${element.id}-container {${element.containerStyle}}`}</style>
            <style>{`.${element.id}-text-input {${element.textareaStyle}}`}</style>
        </div>
    );
}