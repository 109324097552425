// Modules
import { useEffect, useRef, useState } from 'react';
import { useRefresh } from '@Utils/hooks/useRefresh.js';

// Components
import ElementOptionsPanel from '../OptionsPanel/ElementOptionsPanel.js';

// Resources
import settingsIcon from '../../Icons/settings.svg';
import deleteIcon from '../../Icons/delete.svg';

// Styles
import s from './ElementHeader.module.css'

// Component
export default function ElementHeader({ element, editing, nameModifier, options = {} }) {

    // Updating element name
    //----------------------------------------------------------------------------------------------------

    const elementNameRef = useRef(null);

    function updateElementName() {

        // Get name from innerText
        const newName = elementNameRef.current.innerText;

        // Maintain cursor position
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const start = range.startOffset;

        // Update name
        element.updateName(newName);

        // Set cursor position after component re-renders
        window.requestAnimationFrame(() => {

            if (!newName) { return; }

            const newRange = document.createRange();

            newRange.setStart(elementNameRef.current.firstChild, start);
            newRange.collapse(true);
            selection.removeAllRanges();
            selection.addRange(newRange);
        });
    }

    // Expand / collapse group
    //----------------------------------------------------------------------------------------------------

    function nameClick() {
        element.toggleExpanded();
    }

    // Showing / hiding options panel
    //----------------------------------------------------------------------------------------------------

    const [showOptionsPanel, setShowOptionsPanel] = useState(false);

    // Attach events
    //----------------------------------------------------------------------------------------------------

    //const countRef = useRef(null);
    const indexRef = useRef(null);
    const clearButtonRef = useRef(null);

    function showNameHighlight() { showElementHighlight(elementNameRef); }
    function hideNameHighlight() { hideElementHighlight(elementNameRef); }

    //function showCountHighlight() { showElementHighlight(countRef); }
    //function hideCountHighlight() { hideElementHighlight(countRef); }

    function showIndexHighlight() { showElementHighlight(indexRef); }
    function hideIndexHighlight() { hideElementHighlight(indexRef); }

    function showClearButtonHighlight() { showElementHighlight(clearButtonRef); }
    function hideClearButtonHighlight() { hideElementHighlight(clearButtonRef); }

    useEffect(() => {
        element.extend({ showNameHighlight, hideNameHighlight, showIndexHighlight, hideIndexHighlight, showClearButtonHighlight, hideClearButtonHighlight });
    }, []);

    // Show / hide element highlight
    function showElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'true'); } catch { } }
    function hideElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'false'); } catch { } }


    // Component
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={s['gui-element-header']} style={{ paddingBottom: (element.name || editing) ? undefined : '0px' }}>

            <div className={element.type === 'group' ? s['gui-group-info'] : s['gui-element-info']} style={{ margin: element.name && !element.hideName ? undefined : '0px' }}>

                {/* Element name */}
                {((element.name) && (!element.hideName || element.type === 'group')) && (options.showName !== false) ?
                    <div
                        className={element.type !== 'group' ? s['gui-element-name'] + ` ${element.id + '-name'}` : s['gui-group-name']}
                        ref={elementNameRef}
                        contentEditable={element.editable && editing}
                        suppressContentEditableWarning='true'
                        onInput={updateElementName}

                        style={{
                            border: !element.name ? 'dashed 1px rgba(255, 100, 100, 0.75)' : undefined,
                            borderRadius: !element.name ? '5px' : undefined,
                            transition: 'all 0.15s'
                        }}

                        onClick={(element.type === 'group' && !editing) ? () => { nameClick(); } : null}
                    >
                        {`${element.name}${nameModifier ? `: ${nameModifier}` : ``}`}
                    </div>
                    : null}

                {/* Warning to show when element has no name */}
                {(element.showName && !element.name) ?
                    <div className={s['gui-element-name'] + ` ${element.id + '-name'}`} style={{ color: 'rgba(255, 100, 100, 0.75' }}>{'(name?)'}</div>
                    : null}


                {element.count ? <Counter element={element} /> : null}

                {element.name && element.showLine ? <div className={s['gui-group-line']}></div> : null}

            </div>

            <div className={s['gui-element-buttons']}>

                {/* Index selector */}
                {element.showIndex && element.value?.length > 1 ?
                    <div className={s['gui-element-select-index']} ref={indexRef}>
                        <div className={s['gui-element-select-index-arrow']} onClick={element.decIndex.bind(element)}>{'<'}</div>
                        <div className={s['gui-element-select-index-count']}>{`${(element.index || 0) + 1}/${element.value?.length || 1}`}</div>
                        <div className={s['gui-element-select-index-arrow']} onClick={element.incIndex.bind(element)}>{'>'}</div>
                    </div>
                    : null}

                {/* Clear button */}
                {element.showClearButton ?
                    <div className={s['gui-element-clear-button'] + ` ${element.id + '-clear-button'}`} ref={clearButtonRef} onClick={() => { element.clear(); }}>{'clear'}</div>
                    : null}

                {/* Settings & delete buttons */}

                {editing ?
                    <>
                        <img className={s['gui-group-settings-icon']} src={settingsIcon} onClick={() => { setShowOptionsPanel(true); }}></img>
                        <img className={s['gui-group-delete-icon']} src={deleteIcon} onClick={element.delete.bind(element)}></img>
                    </>
                    : null}


                {showOptionsPanel ? <ElementOptionsPanel element={element} closePanel={() => setShowOptionsPanel(false)} /> : null}
            </div>

            {/* custom style tags */}
            <style>{`.${element.id}-name {${element.nameStyle}}`}</style>
            <style>{`.${element.id}-count {${element.countStyle}}`}</style>
            <style>{`.${element.id}-clear-button {${element.clearButtonStyle}}`}</style>
        </div>
    );
}

// Function specifically for counting
function Counter({ element }) {

    // Listen specifically for updates in value
    const refresh = useRefresh();
    useEffect(() => { return element.listen('updateValue', refresh); }, []);

    const { id, minLength, maxLength } = element;
    const value = element.getValue();
    const count = getCount();

    function getCount() {

        if (element.count === 'characters') { return String(value || '').length || 0; }
        if (element.count === 'words') { return numWords(String(value || '')) || 0; }
        if (element.count === 'items') { return element.value?.length || 0; }

        // Return number of words in message
        function numWords(string) {
            if (string.trim === '') { return 0; }
            return string.split(/\s+/).length;
        }

        return 0;
    }

    return (
        <div className={s['gui-element-count'] + ` ${element.id + '-count'}`} style={{ color: count < element.minLength ? 'rgba(255, 245, 129, 0.75)' : count > element.maxLength ? 'rgba(255, 100, 100, 0.75)' : undefined }}>{`(${count}/${element.maxLength})`}</div>
    );
}