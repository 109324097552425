// Modules
import { useEffect } from 'react';

// State
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { VisualEditorState } from '@State/VisualEditor/VisualEditorState.js';

// Resources
import PlusIcon from './Icons/plus.svg';
import MinusIcon from './Icons/minus.svg';

// Styles
import s from './ZoomIndicator.module.css';
import fadeIn from '@Styles/Animations/FadeIn/FadeIn.module.css';

// Component function
export default function ZoomIndicator() {

    const refresh = useRefresh();
    useEffect(() => { return VisualEditorState.nodemap.instance.listen('updateViewportZoom', refresh); }, []);

    const viewportZoom = VisualEditorState.nodemap.instance.settings.viewportZoom || 1.00;

    function zoomIn() {
        VisualEditorState.nodemap.instance.updateViewportZoom((Math.round(viewportZoom * 20) / 20) + 0.1);
    }

    function zoomOut() {
        VisualEditorState.nodemap.instance.updateViewportZoom((Math.round(viewportZoom * 20) / 20) - 0.1);
    }

    // Component HTML
    //--------------------------------------------------------------------------------

    return (
        <div className={s['zoom-indicator'] + ' ' + fadeIn['one-s']}>
            <img className={s['zoom-indicator-button']} src={MinusIcon} title={'Zoom Out'} onClick={zoomOut}></img>
            <div className={s['zoom-indicator-text']}>{`${(viewportZoom*100).toFixed(0)}%`}</div>
            <img className={s['zoom-indicator-button']} src={PlusIcon} title={'Zoom In'} onClick={zoomIn}></img>
        </div>
    );
}