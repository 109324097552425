// State
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { CognitoSessionState } from '@State/CognitoSession/CognitoSessionState.js';

// Styles
import styles from './UserProfile.module.css';

// Resources
import GoogleLogo from '@Assets/IdentityProviderBadges/google-logo.svg';
import GithubLogo from '@Assets/IdentityProviderBadges/github-logo.svg';
import MicrosoftLogo from '@Assets/IdentityProviderBadges/microsoft-logo.svg';

const identityProviderBadges = { 'google': GoogleLogo, 'github': GithubLogo, 'microsoft': MicrosoftLogo }

// Component function
export default function UserProfile() {

    const { loggedIn, userData } = CognitoSessionState;

    // Component HTML
    //--------------------------------------------------

    return (
        <>
            {loggedIn ?

                <a style={{ display: 'flex', flexDirection: 'row', textDecoration: 'none' }} href={`${process.env.REACT_APP_MAIN_WEBSITE_ORIGIN}/profile`}>
                    <span className={styles.userEmail}>{userData.email}</span>

                    {identityProviderBadges[userData['custom:identity_provider']] ? <img className={styles.identityProviderBadge} src={identityProviderBadges[userData['custom:identity_provider']]} onDragStart={(e) => { e.preventDefault(); }}></img> : null}
                </a >

                :
                <div className={styles.loginAndRegister} >
                    <a className={styles.loginButton} href={`${process.env.REACT_APP_MAIN_WEBSITE_ORIGIN}/login`}>Login</a>
                    <a className={styles.registerButton} href={`${process.env.REACT_APP_MAIN_WEBSITE_ORIGIN}/register`}>Signup</a>
                </div>
            }
        </>
    );
};