// Modules
import { useEffect, useRef } from 'react';
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { Chunk, Image, MpegAudio, MpegFrame } from '@edisonai/datatypes';

// Resources
import deleteIcon from '../../../Icons/delete.svg';

// Styles
import './ListItem.css';
import AudioPlayer from '../../Audio/AudioPlayer/AudioPlayer';

// Component
export default function ListItem({ element, value, number, style, opacity, editable = true, updateItem, deleteItem, addNewItem, onClick, index, focusItem, update, showLine = true, attachMethods, id }) {

    const refresh = useRefresh();

    // Focusing
    //----------------------------------------------------------------------------------------------------

    const textareaRef = useRef(null);

    useEffect(() => { return element.listen('focus' + index, focus); }, [index]);

    function focus() {
        textareaRef.current?.focus?.();
    }

    // Focus on item just before this one
    function focusPrev() {
        focusItem(index - 1);
    }

    // Focus on item just after this one
    function focusNext() {
        focusItem(index + 1);
    }

    // Updating text value (if text)
    //----------------------------------------------------------------------------------------------------


    // When there is a keyDown in the text area
    function textareaKeyDown(e) {

        // Creat new item when naked enter is pressed
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            addNewItem('', index);
            window.setTimeout(() => { update() }, 1);
            window.setTimeout(() => { focusNext() }, 10);
            return;
        }

        if (e.key === 'Backspace' && !value) {
            deleteItem();
            window.setTimeout(() => { focusPrev(); }, 1);
            return;
        }

        if (e.key === 'Delete' && !value) {
            deleteItem();
            return;
        }

        // Navigating with arrow keys
        if (e.key === 'ArrowUp') { focusPrev(); }
        if (e.key === 'ArrowDown') { focusNext(); }
    }

    // When there is a change in the text area
    function textareaChange() {
        const textareaValue = textareaRef.current.value;
        updateItem(textareaValue);
        refresh();
    }

    // Automatically update height of textarea
    useEffect(() => { setHeight(); }, [value]);

    useEffect(() => {
        if (!(value?.isChunk)) { return; }
        return value.listen('update', refresh);
    }, [value]);

    function setHeight() {

        if (!textareaRef.current) { return; }

        textareaRef.current.style.minHeight = '0px';
        textareaRef.current.style.minHeight = textareaRef.current.scrollHeight + 'px';
    }

    // Attach events
    //----------------------------------------------------------------------------------------------------

    const itemRef = useRef(null);
    const numberRef = useRef(null);
    const deleteButtonRef = useRef(null);
    const seperatorLineRef = useRef(null);

    function showItemHighlight() { showElementHighlight(itemRef); }
    function hideItemHighlight() { hideElementHighlight(itemRef); }

    function showNumberHighlight() { showElementHighlight(numberRef); }
    function hideNumberHighlight() { hideElementHighlight(numberRef); }

    function showTextAreaHighlight() { showElementHighlight(textareaRef); }
    function hideTextAreaHighlight() { hideElementHighlight(textareaRef); }

    function showDeleteButtonHighlight() { showElementHighlight(deleteButtonRef); }
    function hideDeleteButtonHighlight() { hideElementHighlight(deleteButtonRef); }

    function showLineHighlight() { showElementHighlight(seperatorLineRef); }
    function hideLineHighlight() { hideElementHighlight(seperatorLineRef); }

    // Show / hide element highlight
    function showElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'true'); } catch { } }
    function hideElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'false'); } catch { } }

    useEffect(() => {
        attachMethods({ showItemHighlight, hideItemHighlight, showNumberHighlight, hideNumberHighlight, showTextAreaHighlight, hideTextAreaHighlight, showDeleteButtonHighlight, hideDeleteButtonHighlight, showLineHighlight, hideLineHighlight });
    }, []);

    // Component
    //----------------------------------------------------------------------------------------------------

    return (
        <>
            <div className={`list-item ${id}-item`} ref={itemRef} style={style} onClick={onClick || null}>

                {/* Number shows if item is numbered */}
                {number ?
                    <div className={`list-item-number ${id}-number`} ref={numberRef}>{number}</div>
                    : null}

                {/* Immediately invoked function */}
                {(() => {

                    // For normal values
                    switch (getSpecialType(value)) {

                        case ('audio'): {
                            return <div style={{ position: 'relative', display: 'flex', height: '30px', width: '100%', paddingRight: '0px' }}><AudioPlayer source={value} options={{ autoPlay: true }} element={element} /></div>
                        }

                        default: {
                            return <textarea className={`list-item-text ${id}-text-area`} ref={textareaRef} contentEditable={editable} suppressContentEditableWarning='true' style={{ ...style, opacity }} onKeyDown={textareaKeyDown} onInput={textareaChange} value={value || ''}></textarea>
                        }
                    }

                })()}

                <img className={`list-item-delete-button ${id}-delete-button`} ref={deleteButtonRef} src={deleteIcon} alt={''} onClick={deleteItem}></img>

            </div>

            {showLine ? <div className={`list-seperator-line ${id}-line`} ref={seperatorLineRef}></div> : null}
        </>
    );

    function getSpecialType(value) {

        // Get type from chunk data
        if (value?.isChunk) { value = value.data; }
        if (value instanceof Image) { return 'image'; }
        if (value instanceof MpegAudio) { return 'audio'; }
        if (value instanceof MpegFrame) { return 'audio'; }

        return null
    }
}