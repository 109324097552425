// Modules
import { useRef, useEffect } from 'react';

// Components
import ElementHeader from '../../SubElements/Header/ElementHeader.js';
import ElementErrorMessage from '../../SubElements/ErrorMessage/ElementErrorMessage.js';

// Styles
import './Button.css';

// Component
export default function TextInput({ element, editing }) {

    // Submit element
    //----------------------------------------------------------------------------------------------------

    const buttonRef = useRef(null);

    function submitElement() {
        element.submit();
    }

    // Attach listed events to element
    useEffect(() => {

        // Requires valid inputRef
        if (!buttonRef.current) { return; }

        const functions = {};

        // Define and attach functions, add event listener
        for (const eventName in element.events) { functions[eventName] = (e) => { element.onEvent(eventName, e, element.events[eventName]); } }
        for (const eventName in element.events) { buttonRef.current.addEventListener(eventName, functions[eventName]); }

        return () => {

            // Remove event listenersw
            if (!buttonRef.current) { return; }
            for (const eventName in element.events) { buttonRef.current.removeEventListener(eventName, functions[eventName]); }
        };
    }, []);

    // Attach events
    //----------------------------------------------------------------------------------------------------

    const containerRef = useRef(null);

    function showContainerHighlight() { showElementHighlight(containerRef); }
    function hideContainerHighlight() { hideElementHighlight(containerRef); }

    // Show / hide element highlight
    function showElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'true'); } catch { } }
    function hideElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'false'); } catch { } }

    useEffect(() => {
        element.extend({ showContainerHighlight, hideContainerHighlight });
    }, []);

    // Component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={`gui-element ${element.id}-container`} ref={containerRef}>

            <ElementHeader element={element} editing={editing} options={{ showName: false }} />

            <div className='button-container'>
                {element.showLines ? <div className={`button-line ${element.id}-side-line`}></div> : null}
                <div className={`button ${element.id}-button`} ref={buttonRef} onClick={submitElement} style={{ background: String(element.color) }}>{String(element.name)}</div>
                {element.showLines ? <div className={`button-line ${element.id}-side-line`}></div> : null}
            </div>

            <ElementErrorMessage message={element.errorMessage} />

            {/* custom style tags */}
            <style>{`.${element.id}-container {${element.containerStyle}}`}</style>
            <style>{`.${element.id}-button {${element.buttonStyle}}`}</style>
            <style>{`.${element.id}-side-line {${element.sideLineStyle}}`}</style>
        </div>
    );
}