// Modules
import { useEffect, useRef } from 'react';
import { useSmoothScroll } from '@Utils/hooks/useSmoothScroll.js';
import { List } from '@edisonai/datatypes';

// Components
import ElementHeader from '../../SubElements/Header/ElementHeader.js';
import ElementErrorMessage from '../../SubElements/ErrorMessage/ElementErrorMessage.js';
import NewListItem from './ListItem/ListItem';

// Styles
import './List.css';

// Component
export default function ListComponent({ element, editing }) {

    // Resize
    //----------------------------------------------------------------------------------------------------

    const listRef = useSmoothScroll()


    // Scroll to bottom if we're already at bottom
    function autoScroll() {
        const distanceFromBottom = listRef.current.scrollHeight - listRef.current.scrollTop - listRef.current.clientHeight;
        if (distanceFromBottom < 100) { listRef.current.scrollTop = listRef.current.scrollHeight; }
    }

    // Managing items
    //----------------------------------------------------------------------------------------------------

    // Add a new item to the list
    function addNewItem(newItem, index) {

        // Init element value if necessary
        if (!element.value) { element.value = new List(); }

        element.value.splice(index + 1, 0, newItem);
        element.emit('update');

        window.setTimeout(() => { focusItem(index + 1); }, 10);
    }

    // Update an existing item in the list
    function updateItem(newItem, index) {

        if (!element.value) { element.value = new List(); }
        element.value.splice(index, 1, newItem);

        element.emit('update');
    }

    function deleteItem(index) {
        element.value.splice(index, 1);
        element.emit('update');
    }

    function focusItem(index) {
        element.emit('focus' + index);
    }

    // Attach events
    //----------------------------------------------------------------------------------------------------

    const containerRef = useRef(null);

    function showContainerHighlight() { showElementHighlight(containerRef); }
    function hideContainerHighlight() { hideElementHighlight(containerRef); }

    function showListHighlight() { showElementHighlight(listRef); }
    function hideListHighlight() { hideElementHighlight(listRef); }

    // Show / hide element highlight
    function showElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'true'); } catch { } }
    function hideElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'false'); } catch { } }

    useEffect(() => {
        element.extend({ showContainerHighlight, hideContainerHighlight, showListHighlight, hideListHighlight });
    }, []);

    function update() {
        element.emit('update');
    }

    // Component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={`gui-element ${element.id}-container`} ref={containerRef}>

            <ElementHeader element={element} editing={editing} />

            <div className={`list ${element.errorMessage ? 'list-error' : ''} ${element.id}-list`}

                ref={listRef}

                style={{
                    resize: element.resizable ? 'vertical' : 'none',
                    height: element.height ? element.height + 'px' : 'auto',
                    minHeight: element.minHeight || undefined,
                    maxHeight: element.maxHeight || undefined,
                }}
            >

                <NewListItem
                    value={element.value?.[0]}
                    editable={true}
                    number={0 + 1 + '.'}
                    index={0}
                    addNewItem={addNewItem}
                    updateItem={(newItem) => { updateItem(newItem, 0); }}
                    deleteItem={() => { deleteItem(0); }}
                    focusItem={focusItem}
                    element={element}
                    update={update}
                    attachMethods={element.attachMethods.bind(element)}
                    numberStyle={element.numberStyle}
                    textareaStyle={element.textareaStyle}
                    deleteButtonStyle={element.deleteButtonStyle}
                    id={element.id}
                />

                {element.value?.map((item, index) => {

                    if (index === 0) { return; }

                    return (
                        <NewListItem
                            value={item}
                            editable={true}
                            number={index + 1 + '.'}
                            index={index}
                            addNewItem={addNewItem}
                            updateItem={(newItem) => { updateItem(newItem, index); }}
                            deleteItem={() => { deleteItem(index); }}
                            focusItem={focusItem}
                            element={element}
                            update={update}
                            attachMethods={element.attachMethods.bind(element)}
                            numberStyle={element.numberStyle}
                            textareaStyle={element.textareaStyle}
                            deleteButtonStyle={element.deleteButtonStyle}
                            id={element.id}
                            key={index}
                        />
                    );
                })}

                <NewListItem
                    value={element.placeholder || 'New Item'}
                    style={{cursor: 'pointer'}}
                    editable={false}
                    number={'+'}
                    opacity={'0.5'}
                    onClick={() => { addNewItem('', (element.value?.length || 1) - 1); }}
                    showLine={false}
                    attachMethods={element.attachMethods.bind(element)}
                    numberStyle={element.numberStyle}
                    textareaStyle={element.textareaStyle}
                    deleteButtonStyle={element.deleteButtonStyle}
                    element={element}
                    id={element.id}
                />

            </div>

            <ElementErrorMessage message={element.errorMessage} />

            {/* custom style tags */}
            <style>{`.${element.id}-container {${element.containerStyle}}`}</style>
            <style>{`.${element.id}-list {${element.listStyle}}`}</style>
            <style>{`.${element.id}-item {${element.itemStyle}}`}</style>
            <style>{`.${element.id}-number {${element.numberStyle}}`}</style>
            <style>{`.${element.id}-text-area {${element.textareaStyle}}`}</style>
            <style>{`.${element.id}-delete-button {${element.deleteButtonStyle}}`}</style>
            <style>{`.${element.id}-line {${element.lineStyle}}`}</style>
        </div>
    );
}