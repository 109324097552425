// Modules
import { useEffect } from 'react';

// State
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { CognitoSessionState } from '@State/CognitoSession/CognitoSessionState.js';
import { VisualEditorState } from '@State/VisualEditor/VisualEditorState.js';

// Components
import AnimatedLogo from '@Assets/AnimatedLogo/AnimatedLogo.js';
import DivInput from '@Shared-Components/DivInput/DivInput.js';
import SaveMenu from './SaveMenu/SaveMenu.js';
import NodeMapControls from './NodeMapControls/NodeMapControls.js';
import UserProfileComponent from './UserProfile/UserProfile.js';

// Styles
import styles from './TopBar.module.css';
import fadeIn from '@Styles/Animations/FadeIn/FadeIn.module.css';

export default function TopBar() {

    const refresh = useRefresh();

    const { userData } = CognitoSessionState;
    const nodemap = VisualEditorState.nodemap.instance;

    useEffect(() => {
        return nodemap.listen('updateName', refresh);
    }, []);

    // Component HTML
    //--------------------------------------------------------------------------------

    const balance = Number(userData?.balance || 0);

    // Component HTML
    //--------------------------------------------------------------------------------

    return (

        <div className={styles['top-bar-container']}>
            <div className={styles['top-bar']}>

                <div className={styles['logo-and-name'] + ' ' + fadeIn['one-s']}>
                    <AnimatedLogo className={styles['logo']} />
                    <div className={styles['name']}>Edison Visual Editor</div>
                    <div className={styles['beta-tag']}>BETA</div>
                    <div className={styles['vertical-seperator-bar']}></div>
                    <DivInput className={styles['top-bar-nodemap-name']} value={nodemap.info?.name || ''} onChange={(newName) => { nodemap.updateName(newName); }} />
                    <div className={styles['vertical-seperator-bar']}></div>
                    <SaveMenu />
                </div>

                <div className={styles['user-profile-container'] + ' ' + fadeIn['one-s']}>
                    <NodeMapControls />
                    <div className={styles['vertical-seperator-bar']}></div>
                    <span className={styles['balance']} negative={String(balance < 0)}>{`$${balance.toFixed(2)}`}</span>
                    <div className={styles['vertical-seperator-bar']}></div>
                    <UserProfileComponent />
                </div>
            </div>
        </div>
    );
}