// Modules
import React, { forwardRef } from 'react';

// Styles
import s from './FrostedGlass.module.css';

// Component function
const FrostedGlass = forwardRef((props, ref) => {

    const { opacity = 0.75, blur = 5, border = 3, children } = props;

    const background = `rgba(0, 0, 0, ${opacity})`;
    const backdropFilter = `brightness(${100 / (1 - opacity)}%) blur(${blur}px)`

    // Component HTML
    //--------------------------------------------------------------------------------

    return (
        <div ref={ref} {...props}>

            <div style={{ position: 'absolute', left: '0px', top: '0px', bottom: '0px', right: '0px', zIndex: '-1', borderRadius: '4px', overflow: 'hidden' }}>
                <div style={{ position: 'relative', width: '200%', height: '200%', left: '-50%', top: '-50%', border: 'none', zIndex: '-2', background, backdropFilter }}></div>
            </div>

            {children}
        </div>
    );
});

export default FrostedGlass;