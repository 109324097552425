// Modules
import { useEffect, useRef, useState, useContext } from 'react';

// State
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { VisualEditorState } from '@State/VisualEditor/VisualEditorState.js';

// Components
import DivInput from '@Shared-Components/DivInput/DivInput.js';

// Styles
import s from './NodeOutput.module.css';

// Component function
export default function NodeOutput({ output }) {

    const refresh = useRefresh();

    const [showDeleteButton, setShowDeleteButton] = useState(false);
    const [strikeThroughName, setStrikeThroughName] = useState(false);

    // Get own position
    //----------------------------------------------------------------------------------------------------

    const outputMarkerRef = useRef(null);
    const getPosInMap = useRef(VisualEditorState.getPosInMap);
    const getMousePosInMap = useRef(VisualEditorState.getMousePosInMap);

    // Get own position in nodemap
    function getOwnCenter() {

        if (!outputMarkerRef.current) { return; }

        const rect = outputMarkerRef.current.getBoundingClientRect();
        output.hidden.markerPos = getPosInMap.current({ x: (rect.left + rect.width / 2), y: (rect.top + 0.5 * rect.height), });

        return output.hidden.markerPos;
    }

    output.hidden.getOwnCenter = getOwnCenter;

    useEffect(() => { return output.options.node.listen('updatePosition', getOwnCenter); }, []);
    useEffect(() => { return output.options.node.listen('redrawConnections', getOwnCenter); }, []);
    useEffect(() => { getOwnCenter(); output.redrawConnections(); }, [outputMarkerRef]);

    // Dragging connection
    //----------------------------------------------------------------------------------------------------

    function beginDraggingConnection(e) {

        if (e.button !== 0) { return; }

        console.log('Beginning connection drag');
        output.options.nodemap.connections.add(output.connect({ dragging: true, hidden: { markerPos: getMousePosInMap.current(e) } }));

        // Set dragging event listeners
        window.addEventListener('mousemove', continueDraggingConnection);
        window.addEventListener('mouseup', endDraggingConnection);
    }

    function continueDraggingConnection(e) {
        [...output.options.nodemap.connections].forEach((connection) => {
            connection.input.hidden.markerPos = getMousePosInMap.current(e);
            connection.redraw();
        });
    }

    function endDraggingConnection(e) {

        console.log('Ending connection drag');

        [...output.options.nodemap.connections].forEach((connection) => {
            if (!connection.input.dragging) { return; }
            output.options.nodemap.connections.delete(connection);
            connection.delete();
        });

        refresh();

        // Remove dragging event listeners
        window.removeEventListener('mousemove', continueDraggingConnection);
        window.removeEventListener('mouseup', endDraggingConnection);
    }


    // Component function
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={s['node-output']}>


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} onMouseEnter={() => { setShowDeleteButton(true); }} onMouseLeave={() => { setShowDeleteButton(false); }}>

                {(output.options.node.settings?.outputs?.canAdd && output.options.node.settings?.outputs?.canRemove !== false && output.canRemove !== false) ?
                    <div className={s['node-output-delete-button']} style={{ width: showDeleteButton ? undefined : '0px', margin: showDeleteButton ? undefined : '0px', transitionDelay: showDeleteButton ? '0s' : undefined }} title={'Delete'} onMouseEnter={() => { setStrikeThroughName(true); }} onMouseLeave={() => { setStrikeThroughName(false); }} onClick={() => { output.delete() }}>
                        <svg fill="white" height="10px" width="10px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 460 460" ><path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55 c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55 c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505 c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55 l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719 c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"></path></svg>
                    </div>
                    : null}

                {/* Output name field */}
                {output.name || output.canEditName || output.options.node.settings?.outputs?.canEditName ? <DivInput value={output.name} options={output.nameOptions || output.options?.node?.settings?.outputs?.nameOptions} placeholder={output.options.node?.settings?.outputs?.placeholder || output.placeholder || 'name?'} className={s['node-output-name']} style={{ textDecoration: strikeThroughName ? 'line-through' : undefined }} editable={output.canEditName} onChange={(newName) => { output.updateName(newName); refresh(); }} /> : null}

            </div>

            <div className={s['node-output-marker']} ref={outputMarkerRef} title={`Output ${output.id}`} onMouseDown={beginDraggingConnection}>
                <svg width="10px" height="10px">
                    <circle cx="5" cy="5" r="5" fill={output.options.node.color || 'white'} />
                </svg>
            </div>

        </div>
    );
}