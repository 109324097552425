import { useState, useEffect } from 'react';

// Object to hold the states of all components
const persistentStates = {};

export const useStatePersist = (initialState, uniqueId) => {

    const [state, setState] = useState(() => {

        return uniqueId && persistentStates[uniqueId] !== undefined 
            ? persistentStates[uniqueId] 
            : initialState;
    });
    
    useEffect(() => {



        // If uniqueId is provided, update the persistentStates object whenever the state changes
        if (uniqueId) {
            persistentStates[uniqueId] = state;
        }

    }, [state, uniqueId]);

    return [state, setState];
};
