// Map types to colors
const colorMap = {

    error: 'rgba(255, 128, 128, 0.75)',
    success: 'rgba(128, 255, 128, 0.75)',
    neutral: 'rgba(128, 128, 255, 0.75)',
}

// Notification class
export class Notification {

    constructor({ color, title, text, resolve, time } = {}) {

        // Set title, text, and set
        this.color = colorMap[color] || color;
        this.title = title;
        this.text = text;
        this.resolveCallback = resolve;
        this.timeStamp = Date.now();

        // Resolve self after a certain time
        setTimeout(() => { this.resolve(); }, time || 5000);
    }

    resolve() {
        this.resolveCallback(this);
    }
}