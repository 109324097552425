// Modules
import { useEffect, useRef } from 'react';
import { frameThrottle } from '@Utils/functions/frameThrottle.js';

// State
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { VisualEditorState } from '@State/VisualEditor/VisualEditorState.js';

// Components
import FrostedGlass from '@Utils/components/FrostedGlass/FrostedGlass.js';
import NodeTop from './NodeTop/NodeTop.js';
import NodeStateBar from './NodeStateBar/NodeStateBar.js';
import NodeInputs from './NodeInputs/NodeInputs.js';
import NodeOutputs from './NodeOutputs/NodeOutputs.js';
import GuiGroup from '@Shared-Components/Gui/Elements/Group/Group.js';
import NodeLogs from './NodeLogs/NodeLogs.js';

// Resources
import loadingIcon from './Icons/loading.svg';

// Styles
import s from './NodeComponent.module.css';

// Component function
export default function NodeComponent({ node }) {

    const nodeRef = useRef(null);
    const refresh = useRefresh();

    useEffect(() => { return node.listen('update', refresh); }, []);
    useEffect(() => { return node.listen('updateSetting', () => { node.options.nodemap.emit('outOfDate'); refresh(); }); }, []);

    // Events
    //----------------------------------------------------------------------------------------------------

    function onKeyDown(e) {

        e.stopPropagation();

        if (e.ctrlKey && e.key === 'e') {
            node.editing = !node.editing;
            refresh();
            e.preventDefault();
        }
    }

    function onWheel(e) {

        if (e.altKey) {

            const rotateBy = 0.05 * (e.deltaY || 0);
            const rotation = node.rotation || 0;

            let newRotation = rotation + rotateBy;
            if (newRotation > 360) { newRotation = newRotation - 360; }
            else if (newRotation < -360) { newRotation = newRotation + 360; }

            newRotation = (5 * Math.round(newRotation / 5))

            node.updateRotation(newRotation);
        }
    }

    // Responding to node state
    //----------------------------------------------------------------------------------------------------

    useEffect(() => { adjustBorderColor(); }, []);
    useEffect(() => { return node.listen('updateState', adjustBorderColor); }, []);
    useEffect(() => { return node.listen('updateSelected', adjustBorderColor); }, []);

    function adjustBorderColor() {

        if (!nodeRef.current) { return; }

        nodeRef.current.style.zIndex = '1';

        // Apply rules
        switch (node.state) {

            case ('processing'): { nodeRef.current.style.outlineColor = ''; break; }
            case ('streaming'): { nodeRef.current.style.outlineColor = ''; break; }
            case ('done'): { nodeRef.current.style.outlineColor = node.color || 'white'; break; }
            case ('error'): { nodeRef.current.style.outlineColor = 'rgba(255, 128, 128, 0.75)'; return; }
            default: { nodeRef.current.style.outlineColor = ''; };
        }

        // Otherwise, set selected
        if (node.selected) {
            nodeRef.current.style.zIndex = '2';
            nodeRef.current.style.outlineColor = 'rgba(255, 255, 255, 0.5)';
            return;
        }
    }

    // Dragging position
    //----------------------------------------------------------------------------------------------------

    const dragging = useRef(false);
    const dragStartPos = useRef(null);
    const mouseDownPos = useRef(null);
    const mouseDragPos = useRef(null);
    const mouseUpPos = useRef(null);

    const getMousePosInMap = VisualEditorState.getMousePosInMap;

    function beginDragging(e) {

        if (e.button !== 0) { return; }

        dragging.current = true;
        dragStartPos.current = node.position;
        mouseDownPos.current = getMousePosInMap(e);

        // Set mouseMove and mouseUp event listeners (globally)
        window.addEventListener('mousemove', continueDragging);
        window.addEventListener('mouseup', endDragging);

        // Select node
        node.select(!e.ctrlKey);
    }

    function continueDragging(e) {
        frameThrottle(() => {

            mouseDragPos.current = getMousePosInMap(e);

            node.updatePosition({
                x: dragStartPos.current.x + (mouseDragPos.current.x - mouseDownPos.current.x),
                y: dragStartPos.current.y + (mouseDragPos.current.y - mouseDownPos.current.y)
            });
        });
    }

    function endDragging(e) {

        dragging.current = false;

        mouseUpPos.current = getMousePosInMap(e);

        node.updatePosition({
            x: dragStartPos.current.x + (mouseUpPos.current.x - mouseDownPos.current.x),
            y: dragStartPos.current.y + (mouseUpPos.current.y - mouseDownPos.current.y)
        });

        // Remove mouseMove and mouseUp event listeners (globally)
        window.removeEventListener('mousemove', continueDragging);
        window.removeEventListener('mouseup', endDragging);
    }

    // Position
    //----------------------------------------------------------------------------------------------------

    useEffect(() => { setTransform(); }, []);
    useEffect(() => { return node.listen('updatePosition', setTransform); }, []);
    useEffect(() => { return node.listen('updateRotation', setTransform); }, []);

    function setTransform() {
        if (!nodeRef.current) { return; }

        nodeRef.current.style.transform = `translate(${node.position.x}px, ${-1 * (node.position.y)}px) rotate(${node.rotation || 0}deg)`;
    }

    // Size
    //----------------------------------------------------------------------------------------------------

    useEffect(() => {

        if (nodeRef.current) {

            // Create observer and observe nodeRef
            const resizeObserver = new ResizeObserver((entries) => { for (const entry of entries) { frameThrottle(() => { node.redrawConnections(); }) } });
            resizeObserver.observe(nodeRef.current);

            // Return cleanup function
            return () => {

                resizeObserver.disconnect();
            }
        }
    }, []);

    // Component html
    //----------------------------------------------------------------------------------------------------

    return (

        <FrostedGlass className={s['node']} ref={nodeRef} blur={50} opacity={0.9} style={{ transform: `translate(${node.position.x}px, ${-1 * (node.position.y)}px) rotate(${node.rotation || 0}deg)` }} onKeyDown={onKeyDown} onWheel={onWheel}>

            {/* Name, icon, etc... */}
            <NodeTop node={node} beginDragging={beginDragging} />

            {/* Progress bar */}
            <NodeStateBar node={node} />

            <div className={s['node-middle']}>
                <NodeInputs node={node} />

                {node.gui ? <GuiGroup group={node.gui} className={s['node-gui']} editing={node.editing} /> : null}

                <NodeOutputs node={node} />
            </div>

            <div className={s['node-logs-container']}>
                <NodeLogs node={node} />
            </div>

            {!node.initialized ?
                <div className={s['node-not-initialized']}>
                    <img src={loadingIcon} className={s['node-loading-icon']}></img>
                </div>
                : null}
        </FrostedGlass>
    );
}