// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NodeStateBar_node-state-bar__wpyQk {

    /* position */
    position: relative;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    /* dimentions */
    width: 100%;
    height: 2px;

    /* padding */
    padding-left: 0%;
    padding-right: 0%;

    /* background */
    background: rgba(255, 255, 255, 0.15);

    transition: all 0.25s;
}

    .NodeStateBar_node-state-bar-fill__9bG0C {

        /* position */
        position: relative;

        /* dimentions */
        width: auto;
        height: 100%;
        flex-grow: 1;
        animation: NodeStateBar_marching-ants__F6cm- 1.5s linear infinite;
    }

    .NodeStateBar_marching-ants__F6cm- {
        animation: NodeStateBar_marching-ants__F6cm- 1.5s linear infinite;
    }

    @keyframes NodeStateBar_marching-ants__F6cm- {
        from {
            background-position: 100% 0;
        }
    }`, "",{"version":3,"sources":["webpack://./src/VisualEditor/Sandbox/SandboxNodes/NodeComponent/NodeStateBar/NodeStateBar.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,kBAAkB;;IAElB,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;;IAEnB,eAAe;IACf,WAAW;IACX,WAAW;;IAEX,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;;IAEjB,eAAe;IACf,qCAAqC;;IAErC,qBAAqB;AACzB;;IAEI;;QAEI,aAAa;QACb,kBAAkB;;QAElB,eAAe;QACf,WAAW;QACX,YAAY;QACZ,YAAY;QACZ,iEAA6C;IACjD;;IAEA;QACI,iEAA6C;IACjD;;IAEA;QACI;YACI,2BAA2B;QAC/B;IACJ","sourcesContent":[".node-state-bar {\n\n    /* position */\n    position: relative;\n\n    /* flex options */\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    \n    /* dimentions */\n    width: 100%;\n    height: 2px;\n\n    /* padding */\n    padding-left: 0%;\n    padding-right: 0%;\n\n    /* background */\n    background: rgba(255, 255, 255, 0.15);\n\n    transition: all 0.25s;\n}\n\n    .node-state-bar-fill {\n\n        /* position */\n        position: relative;\n\n        /* dimentions */\n        width: auto;\n        height: 100%;\n        flex-grow: 1;\n        animation: marching-ants 1.5s linear infinite;\n    }\n\n    .marching-ants {\n        animation: marching-ants 1.5s linear infinite;\n    }\n\n    @keyframes marching-ants {\n        from {\n            background-position: 100% 0;\n        }\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"node-state-bar": `NodeStateBar_node-state-bar__wpyQk`,
	"node-state-bar-fill": `NodeStateBar_node-state-bar-fill__9bG0C`,
	"marching-ants": `NodeStateBar_marching-ants__F6cm-`
};
export default ___CSS_LOADER_EXPORT___;
