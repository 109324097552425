// Modules
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// State
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { CognitoSessionState } from '@State/CognitoSession/CognitoSessionState.js';
import { VisualEditorState } from '@State/VisualEditor/VisualEditorState.js';

// Components
import ProgressIndicator from '@Shared-Components/ProgressIndicator/ProgressIndicator.js';
import GuiGroup from '@Shared-Components/Gui/Elements/Group/Group.js';

// Styles
import s from './Interface.module.css';
import fadeIn from '@Styles/Animations/FadeIn/FadeIn.module.css';

// Component function
export default function Interface() {

    // State management
    //------------------------------------------------------------

    const refresh = useRefresh();

    useEffect(() => { return VisualEditorState.listen('update', refresh); }, []);
    useEffect(() => { return CognitoSessionState.listen('update', refresh); }, []);

    // Log in user
    //------------------------------------------------------------

    // React to CognitoSession change
    useEffect(() => {

        const { loggedIn, invalid } = CognitoSessionState;

        if (!loggedIn) { VisualEditorState.setLoadingProgress({ text: 'Getting Session', progress: 1, time: 1000 }); }
        if (invalid) { window.location.href = `${process.env.REACT_APP_MAIN_WEBSITE_ORIGIN}/login?on_success=${window.location.toString()}`; return; }

    }, [CognitoSessionState.loggedIn, CognitoSessionState.invalid]);

    // Load nodemap
    //------------------------------------------------------------

    const { id } = useParams();

    useEffect(() => {
        if (!CognitoSessionState.loggedIn) { return; }
        setNodemap();
    }, [CognitoSessionState.loggedIn]);

    async function setNodemap() {

        if (!id) {
            await VisualEditorState.setNodemap(null, {
                availableNodes: { ...VisualEditorState.availableNodes },
                edisonAiApiKey: CognitoSessionState.userData?.apiKeys[0]?.value
            });
            return;
        }

        await loadNodemap(id);
    }

    async function loadNodemap(id) {

        try {

            // Load nodemap while tracking progress
            await VisualEditorState.loadNodemap(id, {
                availableNodes: { ...VisualEditorState.availableNodes },
                edisonAiApiKey: CognitoSessionState.userData?.apiKeys[0]?.value
            }, (progress) => {
                VisualEditorState.setLoadingProgress({ text: 'Loading Nodemap', progress: progress.progress, percentage: true, time: 1 });
            });
        }

        catch (e) {

            // Log error and redirect to '/'
            console.error(e);
            console.error('Could not load nodemap');
            //window.location.href = '/';
        }
    }

    // View in editor
    //--------------------------------------------------------------------------------

    async function viewInEditor() {
        window.location.href = `/${id}`;
    }


    // Component html
    //--------------------------------------------------

    return (
        <div className={s['interface']}>

            {!VisualEditorState.nodemap.instance ? <ProgressIndicator {...VisualEditorState.loadingProgress} className={fadeIn['one-s']} /> : null}

            {VisualEditorState.nodemap.instance ?

                <div className={s['gui-container']}>
                    <div className={s['edit-and-view-buttons']}>
                        <span className={s['nodemap-name']}>{VisualEditorState?.nodemap?.instance?.info?.name}</span>
                        <span className={s['view-button']} onClick={() => { viewInEditor(); }}>Open in Editor ↗</span>
                    </div>
                    <GuiGroup group={VisualEditorState?.nodemap?.instance?.gui} className={s['nodemap-gui']} />
                </div>

                : null}
        </div>
    );
}