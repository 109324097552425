// Modules
import { useEffect, useRef, useState } from 'react';

// Styles
import s from './ExpandableV.module.css';

// Component function
export default function ExpandableV({ className, expanded = true, children, time = 250, id, style }) {

    const wrapperRef = useRef(null);
    const childrenRef = useRef(null);
    const resizeObserver = useRef(null);

    // Render children?
    //--------------------------------------------------------------------------------

    const [renderChildren, setRenderChildren] = useState(expanded);
    const [fullyExpanded, setFullyExpanded] = useState(expanded);

    const firstRender = useRef(true);

    useEffect(() => {

        if (firstRender.current) { firstRender.current = false; return; }
        
        if (expanded) {
            setRenderChildren(true);
            setTimeout(() => { setFullyExpanded(true); }, time);
        }

        else {
            setFullyExpanded(false);
            setTimeout(() => { setRenderChildren(false); }, time);
        }

    }, [expanded]);

    useEffect(() => {

        try {

            if (firstRender.current) { firstRender.current = false; return; }

            // When we're expanding
            if (expanded) {

                // Set height then REMOVE it
                wrapperRef.current.style.height = wrapperRef.current.clientHeight + 'px';
                setTimeout(() => { try { wrapperRef.current.style.height = childrenRef.current.clientHeight + 'px'; } catch { } }, 10);
                setTimeout(() => { try { wrapperRef.current.style.height = 'auto'; } catch { } }, time);
            }

            // When we're collapsing
            else {

                wrapperRef.current.style.height = wrapperRef.current.clientHeight + 'px';
                setTimeout(() => { try { wrapperRef.current.style.height = '0px'; } catch { } }, 10);
            }
        }

        catch (e) {

        }

    }, [expanded]);

    // Component HTML
    //--------------------------------------------------------------------------------

    return (
        <div className={`${renderChildren ? className : null}` + ' ' + s['expandable']} ref={wrapperRef} first={String(firstRender.current)}
            style={{
                margin: expanded ? undefined : '0px',
                overflow: expanded && fullyExpanded ? undefined : 'hidden',
                transition: !firstRender.current ? `all ${time}ms ease` : undefined
            }}>
            <div className={s['children']} ref={childrenRef} style={style}>
                {renderChildren ? children : null}
            </div>
        </div>
    );
}