// Modules
import { useEffect } from 'react';
import { useRefresh } from '@Utils/hooks/useRefresh.js';

// Components
import NodeComponent from './NodeComponent/NodeComponent';

// Styles
import s from './SandboxNodes.module.css';

// Component function
export default function SandboxNodes({ nodemap }) {

    // Refresh when nodes update
    const refresh = useRefresh();
    useEffect(() => { return nodemap.listen('updateNodes', refresh); }, []);

    // Component
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={s['sandbox-nodes']}>

            {/* Go through each node */}
            {[...nodemap.nodes].map((node) => {
                return (
                    <NodeComponent node={node} key={node.id} />
                );
            })}
        </div>
    );
}