// Modules
import { useEffect, useRef } from 'react';

// Components
import FrostedGlass from '@Utils/components/FrostedGlass/FrostedGlass.js';

// Resources
import templates from '../../Elements/ElementTemplates.js';

// Styles
import s from './AddElementMenu.module.css';

// Component
export default function AddElementMenu({ addElement, hideMenu }) {

    // Hide menu when user clicks elsewhere
    //----------------------------------------------------------------------------------------------------

    const menuRef = useRef(null);
    const menuClicked = useRef(false);

    useEffect(() => {

        // Stops click from propagating outside menu
        function menuClick(e) { menuClicked.current = true; window.requestAnimationFrame(() => { menuClicked.current = false; }); }
        function windowClick(e) { if (!menuClicked.current) { fadeOutAndHide(); } }

        menuRef?.current?.addEventListener('click', menuClick);
        window.addEventListener('click', windowClick);

        return (() => {
            menuRef?.current?.removeEventListener('click', menuClick);
            window.removeEventListener('click', windowClick);
        });
    }, []);

    // Fade in / fade out when element appears / dissapears
    //----------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (!menuRef?.current) { return; }
        window.setTimeout(() => { showAndFadeIn(); }, 0);
    }, []);

    // Fade out menu and trigger hide function
    function fadeOutAndHide() {

        if (!menuRef?.current) { return; }

        menuRef.current.style.opacity = 0;
        window.setTimeout(() => { hideMenu(); }, 250);
    }

    // Fade in menu gradually
    function showAndFadeIn() {

        if (!menuRef?.current) { return; }
        menuRef.current.style.opacity = 1;
    }

    // Component
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={s['gui-group-add-gui-element-menu']} ref={menuRef}>

            <div className={s['gui-group-add-gui-element-menu-header']}></div>

            <div blur={10} opacity={0.5} className={s['gui-group-add-gui-element-menu-content']}>

                {/* gui elements */}
                {Object.values(templates).map((template) => {

                    function addElementFromTemplate() {
                        addElement(template);
                        hideMenu();
                    }

                    return (
                        <div className={s['gui-group-add-gui-element']} onClick={addElementFromTemplate} key={template.name}>
                            <img className={s['gui-group-add-gui-element-icon']} src={template.icon}></img>
                            <div className={s['gui-group-add-gui-element-name']}>{template.name}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}