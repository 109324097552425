// Modules
import React from 'react';
import { createRoot } from 'react-dom/client';

// Components
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import VisualEditor from './VisualEditor/VisualEditor.js';
import Interface from './Interface/Interface.js';

// Styles
import './index.css';

// Render react
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// Create context
    //--------------------------------------------------------------------------------

// App component function
function App({ props }) {

    // Component HTML
    //--------------------------------------------------------------------------------

    return (
        <BrowserRouter>
            <Routes>

                {/* Allow nodemap id to be passed in as a param*/}
                <Route path='/' element={<VisualEditor />} />
                <Route path='/:id' element={<VisualEditor />} />
                <Route path='/ui/:id' element={<Interface />} />
            </Routes>
        </BrowserRouter>
    );
}