// Modules
import React, { useEffect, useState } from 'react';
import { useSmoothScroll } from '@Utils/hooks/useSmoothScroll';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

// State
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { CognitoSessionState } from '@State/CognitoSession/CognitoSessionState.js';
import { VisualEditorState } from '@State/VisualEditor/VisualEditorState.js';

// Styles
import s from './Nodemaps.module.css';

// Components
import ExpandableV from '@Shared-Components/ExpandableV/ExpandableV.js';

// Resources
import AddIcon from './Icons/add-icon.svg';
import DeleteIcon from './Icons/delete.svg';
import ShareIcon from './Icons/share.svg';

// Component function
export default function Nodemaps() {

    const nodemapsRef = useSmoothScroll(null);
    const refresh = useRefresh();

    // User nodemaps
    //--------------------------------------------------------------------------------

    const [showUserMaps, setShowUserMaps] = useState(true);

    useEffect(() => { return VisualEditorState.listen('updateMaps', refresh); }, []);
    useEffect(() => { return VisualEditorState.listen('savedNodemap', getUserMaps); }, []);
    useEffect(() => { getUserMaps(); }, [CognitoSessionState.userData], []);

    async function getUserMaps() {

        try {

            const { email } = CognitoSessionState.userData || {};

            const getUserMapsResult = await VisualEditorState.getNodemapsByAuthor(email);
            VisualEditorState.setUserMaps(getUserMapsResult);
        }

        catch (e) {
            console.error(e);
            console.error('There are no nodemaps by that user!');
        }
    }

    // Load nodemap
    //--------------------------------------------------------------------------------

    // TODO: Create function
    async function loadNodemap(id) {
        console.log('Loading nodemap...');
        window.location.href = `/${id}`;
    }

    // Create new nodemap
    //--------------------------------------------------------------------------------

    // TODO: Create function
    async function createNodemap() {

        try {

            console.log('Creating new nodemap...');
            document.body.classList.add('cursor-progress');

            const result = await VisualEditorState.createNodemap();
            await getUserMaps();

            document.body.classList.remove('cursor-progress');

            await VisualEditorState.loadNodemap(result.id);
        }

        catch (e) {
            document.body.classList.remove('cursor-progress');
            VisualEditorState.notification({ color: 'error', title: 'Upgrade Subscription', text: e.message });
        }
    }

    // Delete nodemap
    //--------------------------------------------------------------------------------

    // TODO: Create function
    async function deleteNodemap(nodemap) {

        try {

            // Confirm user wants to delete
            if (!window.confirm('Are you sure you want to delete this nodemap?')) { return; }

            console.log('Deleting nodemap...');
            document.body.classList.add('cursor-progress');

            const deleteNodemapResult = await VisualEditorState.deleteNodemap(nodemap.id);

            VisualEditorState.setUserMaps(VisualEditorState.nodemaps?.user?.filter((map) => { return map.id !== nodemap.id }));

            console.log(deleteNodemapResult);

            document.body.classList.remove('cursor-progress');
            VisualEditorState.notification({ color: 'success', title: `${nodemap.info?.name || 'Nodemap'} Deleted`, text: '' });
        }

        catch (e) {
            VisualEditorState.notification({ color: 'error', title: 'Could not delete nodemap', text: e.message });
            document.body.classList.remove('cursor-progress');
        }
    }

    // Share nodemap
    //--------------------------------------------------------------------------------

    // TODO: Create function
    async function shareNodemap(id) {
        console.log('Sharing nodemap...');
    }

    // Calculating time
    //--------------------------------------------------------------------------------

    TimeAgo.addLocale(en);
    const timeAgo = new TimeAgo('en-US');

    // Component HTML
    //--------------------------------------------------------------------------------

    return (
        <div className={s['nodemaps']} ref={nodemapsRef}>

            <div className={s['new-nodemap-button']} onClick={createNodemap}>
                <img className={s['new-nodemap-button-icon']} src={AddIcon} onDragStart={(e) => { e.preventDefault(); }}></img>
                <span className={s['new-nodemap-button-text']}>New Nodemap</span>
            </div>

            <div className={s['horizontal-seperator']}></div>

            <ExpandableV expanded={showUserMaps}>

                <div className={s['nodemaps-list']}>

                    {(VisualEditorState.nodemaps?.user || []).map((nodemap) => {
                        return (
                            <React.Fragment key={nodemap.id}>
                                <div className={s['nodemap']}>

                                    <div className={s['nodemap-info']}>
                                        <span className={s['nodemap-info-name']} onClick={() => { loadNodemap(nodemap.id) }}>{nodemap?.info?.name || 'Unnamed Nodemap'}</span>
                                        <span className={s['nodemap-info-last-modified']}>{timeAgo.format(nodemap?.info?.last_modified || nodemap?.info?.created_on || Date.now())}</span>
                                    </div>

                                    <div className={s['new-nodemap-buttons']}>
                                        <img className={s['new-nodemap-button']} src={DeleteIcon} title={'Delete'} onClick={() => { deleteNodemap(nodemap) }} onDragStart={(e) => { e.preventDefault(); }}></img>
                                        <img className={s['new-nodemap-button']} src={ShareIcon} title={'Share'} onClick={() => { shareNodemap(nodemap) }} onDragStart={(e) => { e.preventDefault(); }}></img>
                                    </div>

                                </div>

                                <div className={s['horizontal-seperator']} style={{ opacity: '0.5', margin: '0px' }}></div>
                            </React.Fragment>
                        );
                    })}

                </div>
            </ExpandableV>
        </div>
    );
}