// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox-container {

    /* dimentions */
    height: 100%;
    width: 100%;

    /* padding */
    padding-top: 6px;
}

    .checkbox {

        /* dimentions */
        width: 20px;
        height: 20px;

        /* flex options */
        display: flex;
        direction: column;
        justify-content: center;
        align-items: center;

        /* border */
        border: solid 1px var(--line-color);
        
        /* hover */
        cursor: pointer;
    }

        .checkbox-checked-icon {
            /* dimentions */
            width: 100%;
            height: 100%;
        }`, "",{"version":3,"sources":["webpack://./src/Shared-Components/Gui/Elements/Checkbox/Checkbox.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,YAAY;IACZ,WAAW;;IAEX,YAAY;IACZ,gBAAgB;AACpB;;IAEI;;QAEI,eAAe;QACf,WAAW;QACX,YAAY;;QAEZ,iBAAiB;QACjB,aAAa;QACb,iBAAiB;QACjB,uBAAuB;QACvB,mBAAmB;;QAEnB,WAAW;QACX,mCAAmC;;QAEnC,UAAU;QACV,eAAe;IACnB;;QAEI;YACI,eAAe;YACf,WAAW;YACX,YAAY;QAChB","sourcesContent":[".checkbox-container {\n\n    /* dimentions */\n    height: 100%;\n    width: 100%;\n\n    /* padding */\n    padding-top: 6px;\n}\n\n    .checkbox {\n\n        /* dimentions */\n        width: 20px;\n        height: 20px;\n\n        /* flex options */\n        display: flex;\n        direction: column;\n        justify-content: center;\n        align-items: center;\n\n        /* border */\n        border: solid 1px var(--line-color);\n        \n        /* hover */\n        cursor: pointer;\n    }\n\n        .checkbox-checked-icon {\n            /* dimentions */\n            width: 100%;\n            height: 100%;\n        }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
