// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZoomIndicator_zoom-indicator__17dUj {

    /* position */
    position: relative;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    /* dimentions */
    min-width: 14ch;

    /* margins & padding */
    margin-right: 6px;
    padding: 12px;

    /* border */
    border: solid 1px var(--line-color);
    border-radius: 4px;

    /* background */
    background: rgba(0, 0, 0, 0.66);
    -webkit-backdrop-filter: brightness(333%) blur(10px);
            backdrop-filter: brightness(333%) blur(10px);

    /* text */
    color: var(--medium-text);
    white-space: nowrap;
    font-family: 'Ubuntu Mono', 'monospace';

    /* pre-hover */
    transition: background 0.25s;

}   .ZoomIndicator_zoom-indicator__17dUj:hover {

    /* post-hover */
    background: rgba(0, 0, 0, 0.5);
}

    .ZoomIndicator_zoom-indicator-text__oSmdk {

        /* margins */
        margin-left: 6px;
        margin-right: 6px;

        /* text */
        font-size: 16px;
    }

    .ZoomIndicator_zoom-indicator-button__gBI7T {

        /* dimentions */
        width: 10px;
        height: 10px;

        /* pre-hover */
        opacity: 0.5;
        transition: all 0.15s;

    }   .ZoomIndicator_zoom-indicator-button__gBI7T:hover {

        /* post-hover */
        opacity: 1;
        cursor: pointer;
    }`, "",{"version":3,"sources":["webpack://./src/VisualEditor/RightMenu/ZoomIndicator/ZoomIndicator.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,kBAAkB;;IAElB,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;;IAEnB,eAAe;IACf,eAAe;;IAEf,sBAAsB;IACtB,iBAAiB;IACjB,aAAa;;IAEb,WAAW;IACX,mCAAmC;IACnC,kBAAkB;;IAElB,eAAe;IACf,+BAA+B;IAC/B,oDAA4C;YAA5C,4CAA4C;;IAE5C,SAAS;IACT,yBAAyB;IACzB,mBAAmB;IACnB,uCAAuC;;IAEvC,cAAc;IACd,4BAA4B;;AAEhC,IAAI;;IAEA,eAAe;IACf,8BAA8B;AAClC;;IAEI;;QAEI,YAAY;QACZ,gBAAgB;QAChB,iBAAiB;;QAEjB,SAAS;QACT,eAAe;IACnB;;IAEA;;QAEI,eAAe;QACf,WAAW;QACX,YAAY;;QAEZ,cAAc;QACd,YAAY;QACZ,qBAAqB;;IAEzB,IAAI;;QAEA,eAAe;QACf,UAAU;QACV,eAAe;IACnB","sourcesContent":[".zoom-indicator {\n\n    /* position */\n    position: relative;\n\n    /* flex options */\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n\n    /* dimentions */\n    min-width: 14ch;\n\n    /* margins & padding */\n    margin-right: 6px;\n    padding: 12px;\n\n    /* border */\n    border: solid 1px var(--line-color);\n    border-radius: 4px;\n\n    /* background */\n    background: rgba(0, 0, 0, 0.66);\n    backdrop-filter: brightness(333%) blur(10px);\n\n    /* text */\n    color: var(--medium-text);\n    white-space: nowrap;\n    font-family: 'Ubuntu Mono', 'monospace';\n\n    /* pre-hover */\n    transition: background 0.25s;\n\n}   .zoom-indicator:hover {\n\n    /* post-hover */\n    background: rgba(0, 0, 0, 0.5);\n}\n\n    .zoom-indicator-text {\n\n        /* margins */\n        margin-left: 6px;\n        margin-right: 6px;\n\n        /* text */\n        font-size: 16px;\n    }\n\n    .zoom-indicator-button {\n\n        /* dimentions */\n        width: 10px;\n        height: 10px;\n\n        /* pre-hover */\n        opacity: 0.5;\n        transition: all 0.15s;\n\n    }   .zoom-indicator-button:hover {\n\n        /* post-hover */\n        opacity: 1;\n        cursor: pointer;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"zoom-indicator": `ZoomIndicator_zoom-indicator__17dUj`,
	"zoom-indicator-text": `ZoomIndicator_zoom-indicator-text__oSmdk`,
	"zoom-indicator-button": `ZoomIndicator_zoom-indicator-button__gBI7T`
};
export default ___CSS_LOADER_EXPORT___;
