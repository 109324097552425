// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VisualEditor_visual-editor__gE9Vq {

    /* position */
    position: relative;

    /* flex options */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    /* dimentions */
    width: 100%;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/VisualEditor/VisualEditor.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,kBAAkB;;IAElB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;;IAEZ,eAAe;IACf,WAAW;IACX,YAAY;AAChB","sourcesContent":[".visual-editor {\n\n    /* position */\n    position: relative;\n\n    /* flex options */\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    flex-grow: 1;\n\n    /* dimentions */\n    width: 100%;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"visual-editor": `VisualEditor_visual-editor__gE9Vq`
};
export default ___CSS_LOADER_EXPORT___;
