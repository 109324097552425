// Modules
import { useRef, useEffect } from 'react';

// Styles
import './SubmitActions.css';

// Resources
import minus from './minus.svg';
import upArrow from './chevron-up.svg';
import downArrow from './chevron-down.svg';


export default function SubmitActions({ element }) {

    const submitActionsRef = useRef(null)

    const commands = [
        { value: null, display: '' },
        { value: 'SUBMIT_FIELD', display: 'Submit Field' },
        { value: 'CLEAR_FIELD', display: 'Clear Field' },
        { value: 'RESET_FIELD', display: 'Reset Field' },
        { value: 'MESSAGE_FIELD', display: 'Message Field' },
        { value: 'MESSAGE_NODE', display: 'Message Node' },
        { value: 'RESET_NODE', display: 'Reset Node' },
        { value: 'RESET_NODEMAP', display: 'Reset Nodemap' }
    ]

    const onSubmitActions = element.getValue() || [];

    // Event Handlers
    //---------------------------------------------------------------------------------------------------


    const handleAddElement = () => {
        element.updateValue(([...onSubmitActions, { command: 'SUBMIT_FIELD', parameter: '' }]));
        // Update value of element
    }

    const handleRemoveElement = (index) => {

        if (onSubmitActions.length < 2) { return; }

        const updatedElements = onSubmitActions.filter((_, i) => i !== index);
        element.updateValue(updatedElements);
    }

    const handleShiftElementUp = (index) => {

        swapElements(onSubmitActions, index, index - 1);

        // Plays the swapping animation
        toggleMoveAnimation('up', index);
        setTimeout(() => {
            element.updateValue(onSubmitActions);
            toggleMoveAnimation('up', index);
        }, 150)

    }

    const handleShiftElementDown = (index) => {

        swapElements(onSubmitActions, index, index + 1);

        // Plays the swapping animation
        toggleMoveAnimation('down', index);
        setTimeout(() => {
            element.updateValue(onSubmitActions);
            toggleMoveAnimation('down', index);
        }, 150);

    }

    // Swap two elements of action array
    const swapElements = (array, fromIndex, toIndex) => {

        // Ensure we don't screw up the array
        if (fromIndex < 0 || fromIndex > array.length || toIndex < 0 || toIndex > array.length) {
            throw new Error("Index out of bounds!");
        }

        // Get array and swap items
        const item = array[toIndex];
        array[toIndex] = array[fromIndex];
        array[fromIndex] = item;

        return array;
    }

    // Shifting Animations
    function toggleMoveAnimation(direction = 'down', index = 0) {

        // Get containers
        const actionContainer = submitActionsRef.current.querySelector('#action' + index);
        const otherActionContainer = submitActionsRef.current.querySelector('#action' + (index + (direction === 'down' ? 1 : -1)));

        // Toggle css for primary action container
        actionContainer.classList.toggle('action-move-' + direction);
        actionContainer.classList.toggle('top-z-index');

        // Toggle css for other action container
        const otherDirection = direction === 'down' ? 'up' : 'down';
        if (otherActionContainer) { otherActionContainer.classList.toggle('action-move-' + otherDirection) };
    }

    // Compiling actions
    //----------------------------------------------------------------------------------------------------

    function compileActions() {

        const submitActionsContainer = submitActionsRef.current;

        const actions = [];

        for (const index in onSubmitActions) {

            // Get elements
            const actionContainer = submitActionsContainer.querySelector('#action' + index);
            const commandInput = actionContainer.querySelector('#command');
            const parameterInput = actionContainer.querySelector('#parameter');

            // Get values
            const command = commandInput.value;
            const parameter = parameterInput.value;

            // Push to array
            actions.push({ command, parameter });
        }

        element.updateValue(actions);
    }


    // component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={`gui-element`} ref={submitActionsRef}>
            <label className={'gui-element-name'} style={{ marginBottom: '10px' }}>{element.name || ''}</label>

            {onSubmitActions?.map((action, index) => {

                return (

                    <div key={index} className={'submit-actions-action'} id={'action' + index}>

                        {onSubmitActions.length > 1 ?
                            <div className={'arrange-actions'}>
                                {index < 1 ? '' :
                                    <img className={'arrange-icon'} onClick={() => handleShiftElementUp(index)} src={upArrow} alt='' />
                                }
                                {index > onSubmitActions.length - 2 ? // array is 1 larger than expected?
                                    '' :
                                    <img className={'arrange-icon'} onClick={() => handleShiftElementDown(index)} src={downArrow} alt='' />
                                }
                            </div>
                            : null}

                        <select className={'submit-actions-action-dropdown'} value={action.command || ''} style={{ width: 'auto', marginRight: '10px', fontSize: '12px' }} onChange={compileActions} id={'command'}>
                            {commands.map((elem, index) => (
                                <option key={index} value={elem.value} className={'submit-actions-action-dropdown-option'}>{elem.display}</option>
                            ))}
                        </select>

                        <input type="text" className={"submit-actions-parameter-textbox"} value={action.parameter || ''} autoComplete="off" onChange={compileActions} id={'parameter'} ></input>
                        <img className={"remove-action-button"} src={minus} alt={''} onClick={() => handleRemoveElement(index)} />
                    </div>
                )
            })}

            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <div onClick={handleAddElement} className="add-action-button">+ Add Action</div>
            </div>
        </div>
    );
}