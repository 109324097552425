// Modules
import { useEffect, useRef } from 'react';
import { useSmoothScroll } from '@Utils/hooks/useSmoothScroll.js';

// State
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { VisualEditorState } from '@State/VisualEditor/VisualEditorState.js';

// Components
import LogViewer from './LogViewer/LogViewer.js';

// Resources
import showLogsIcon from './logsIcon.svg';
import clearLogsIcon from './clearLogsIcon.svg';

// Styles
import s from './NodeLogs.module.css';

// Component function
export default function NodeLogs({ node }) {

    const refresh = useRefresh();

    // Resize
    //----------------------------------------------------------------------------------------------------

    const logsBackgroundRef = useSmoothScroll(null);

    function resizeLogsBackground(e) {

        const verticalScrollbar = logsBackgroundRef.current.scrollHeight > logsBackgroundRef.current.clientHeight;
        const horizontalScrollbar = logsBackgroundRef.current.scrollWidth > logsBackgroundRef.current.clientWidth;

        if (!node.settings) { node.settings = {}; }
        if (!node.settings.logViewerSize) { node.settings.logViewerSize = {}; }

        node.settings.logViewerSize.height = logsBackgroundRef.current.clientHeight + 2 + (horizontalScrollbar ? 15 : 0) + 'px';
        node.settings.logViewerSize.width = logsBackgroundRef.current.clientWidth + 2 + (verticalScrollbar ? 15 : 0) + 'px';

        node.emit('update');
    }

    // Component html
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={s['node-logs']}>

            <div className={s['node-logs-buttons']}>
                <img className={s['node-show-clear-logs-buttons']} src={showLogsIcon} alt={''} onClick={() => { node.updateSetting('showLogs', !node?.settings.showLogs); refresh(); }} onDragStart={(e) => { e.preventDefault(); }}></img>
                {node.settings?.showLogs ? <img className={s['node-show-clear-logs-buttons']} src={clearLogsIcon} alt={''} onClick={() => { node.clearLogs(); }} onDragStart={(e) => { e.preventDefault(); }}></img> : null}
            </div>

            {node.settings?.showLogs ?
                <div className={s['node-logs-background']} ref={logsBackgroundRef} style={{ width: node.settings?.logViewerSize?.width, height: node.settings?.logViewerSize?.height }} onMouseUp={resizeLogsBackground} onMouseLeave={resizeLogsBackground}>
                    <LogViewer node={node} />
                </div>
            : null}
        </div>
    );
}