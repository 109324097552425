// Resources
import AnimatedLogoSvg from './AnimatedLogo.svg';

// Styles
import './AnimatedLogo.css'

// Component function
export default function AnimatedLogo({ className }) {

    return (
        <div className={className}>
            <svg width="100%" height="100%" version="1.1" viewBox="0 0 75 75" id="svg6" xmlns="http://www.w3.org/2000/svg" >
                <defs id="defs10"></defs>
                <sodipodi id="namedview8" pagecolor="#ffffff" bordercolor="#666666" borderopacity="1.0" ></sodipodi>
                <path d="m18.635 5.2287 18.602 32.287-18.602 32.286-9.3829-16.182 9.34-16.105-9.3203-16.143z" fill="#fff" fillOpacity=".99887" stroke="#fff" strokeLinejoin="round" strokeWidth="2.5" id="path2" className="svg-elem-1"></path>
                <path d="m18.635 5.2287h37.2l9.3246 16.144h-18.602l9.281 16.143-9.3247 16.143h18.642l-9.3211 16.143h-37.2l18.602-32.286z" fill="none" stroke="#fff" strokeLinecap="square" strokeLinejoin="bevel" strokeWidth="2.5" id="path4" className="svg-elem-2"></path>
            </svg>
        </div>
    );
}