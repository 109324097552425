// Styles
import styles from './ProgressIndicator.module.css';

const ProgressIndicator = ({ progress, color, text, time, className, percentage }) => {

    return (
        <div className={styles.progressContainer + ' ' + className}>

            <div className={styles.progressCircle}>
                <svg width={200} height={200}>
                    <g transform={`rotate(-90 ${"100 100"})`}>
                        <Circle color="rgba(255, 255, 255, 0.15)" />
                        <Circle color={color || 'white'} progress={progress || 0.01} time={time} />
                    </g>
                </svg>
            </div>

            <div className={styles.progressText}>{text}</div>
            {percentage ?
                <>
                    <div style={{ margin: '4px' }}></div>
                    <div className={styles.progressText}>{(100 * progress).toFixed(1) + '%'}</div>
                </>
            : null}
        </div>
    );
};

const Circle = ({ color, progress, time }) => {

    const r = 70;
    const circ = 2 * Math.PI * r;
    const strokePct = Math.round(((1 - progress) * circ));

    return (
        <circle
            r={r}
            cx={100}
            cy={100}
            fill="transparent"
            stroke={strokePct !== circ ? color : ""} // remove color as 0% sets full circumference
            strokeDasharray={circ}
            strokeDashoffset={progress ? strokePct : 0}
            strokeLinecap="round"
            style={{ transition: `all ${time || 1000}ms ease-out` }}
        ></circle>
    );
};

export default ProgressIndicator;
