// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list {

    /* dimentions */
    width: 100%;
    height: auto;
    resize: vertical;
    overflow: auto;

    /* margins */
    margin: 0px;

    /* border */
    border: solid;
    border-width: 1px;
    border-color: var(--distinguish-0p03);
    border-radius: 4px;

    /* background */
    background: var(--distinguish-0p03);

    /* font */
    color: var(--strong-text);

}   .list-error {

    /* border */
    border-color: rgba(255, 128, 128, 0.75);
}

    .list-seperator-line {

        /* dimentions */
        width: 100%;
        height: 1px;

        /* background */
        background: var(--distinguish-0p03);
    }`, "",{"version":3,"sources":["webpack://./src/Shared-Components/Gui/Elements/List/List.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,cAAc;;IAEd,YAAY;IACZ,WAAW;;IAEX,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,qCAAqC;IACrC,kBAAkB;;IAElB,eAAe;IACf,mCAAmC;;IAEnC,SAAS;IACT,yBAAyB;;AAE7B,IAAI;;IAEA,WAAW;IACX,uCAAuC;AAC3C;;IAEI;;QAEI,eAAe;QACf,WAAW;QACX,WAAW;;QAEX,eAAe;QACf,mCAAmC;IACvC","sourcesContent":[".list {\n\n    /* dimentions */\n    width: 100%;\n    height: auto;\n    resize: vertical;\n    overflow: auto;\n\n    /* margins */\n    margin: 0px;\n\n    /* border */\n    border: solid;\n    border-width: 1px;\n    border-color: var(--distinguish-0p03);\n    border-radius: 4px;\n\n    /* background */\n    background: var(--distinguish-0p03);\n\n    /* font */\n    color: var(--strong-text);\n\n}   .list-error {\n\n    /* border */\n    border-color: rgba(255, 128, 128, 0.75);\n}\n\n    .list-seperator-line {\n\n        /* dimentions */\n        width: 100%;\n        height: 1px;\n\n        /* background */\n        background: var(--distinguish-0p03);\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
