// Modules
import { useRef, useEffect } from 'react';

// Components
import ElementHeader from '../../SubElements/Header/ElementHeader.js';
import ElementErrorMessage from '../../SubElements/ErrorMessage/ElementErrorMessage.js';
import ReactJson from '@microlink/react-json-view';

// Styles
import './ObjectViewer.css';

// Component function
export default function ObjectViewer({ element, editing }) {

    // Display value
    //--------------------------------------------------------------------------------

    const displayValue = element.getValue();

    // Resize
    //--------------------------------------------------------------------------------

    const containerRef = useRef(null);

    function onResize(e) {

        if (!element.style) { element.style = {}; }

        const verticalScrollbar = containerRef.current.scrollHeight > containerRef.current.clientHeight;
        const horizontalScrollbar = containerRef.current.scrollWidth > containerRef.current.clientWidth;

        element.style.height = containerRef.current.clientHeight + (horizontalScrollbar ? 15 : 0) + 'px';
        element.style.width = containerRef.current.clientWidth + (verticalScrollbar ? 15 : 0) + 'px';

        element.emit('update');
    }

    // Options
    //--------------------------------------------------------------------------------

    const options = {

        name: false,
        displayDataTypes: false,
        displayObjectSize: false,
        enableEdit: true,
        quotesOnKeys: false,
        enableClipboard: false,

        style: {
            width: 'max-content',
        },

        theme: {
            base00: "transparent",              // Default background
            base01: "var(--distinguish-0p03)",                      // Lighter background
            base02: "var(--distinguish-0p03)",  // Bar
            base03: "red",                      // Comments
            base04: "var(--medium-text)",                      // Dark foreground
            base05: "var(--medium-text)",                      // Default foreground
            base06: "var(--medium-text)",                      // String-based object names
            base07: "var(--medium-text)",                      // Light background
            base08: "blue",                      // Variables
            base09: "rgba(128, 255, 128)",                      // Strings
            base0A: "rgba(128, 128, 255, 0.5)",                      // Null
            base0B: "red",                      // Strings
            base0C: "var(--weak-text)",                      // Labels
            base0D: "var(--strong-text)",                      // Functions
            base0E: "rgba(128, 128, 255, 0.5)",                      // Bool
            base0F: "rgba(128, 128, 255)"                     // Opening/closing tags (numbers!)
        },

        onEdit: ({updated_src}) => { element.updateValue(updated_src); },
        onAdd: ({updated_src}) => { element.updateValue(updated_src); },
        onDelete: ({updated_src}) => { element.updateValue(updated_src); },
    }

    // Component HTML
    //--------------------------------------------------------------------------------

    return (
        <div className={`gui-element`}>
            <ElementHeader element={element} editing={editing} />

            <div ref={containerRef}

                onMouseUp={onResize}
                onMouseLeave={onResize}

                style={{
                    width: '200px',
                    resize: 'both',
                    overflow: 'auto',
                    overflowX: 'visible',
                    background: 'var(--distinguish-0p03)',
                    padding: '10px',
                    //height: '200px',
                    ...element.style,
                }}>

                <ReactJson src={displayValue} {...options} />
            </div>
            <ElementErrorMessage message={element.errorMessage} />
        </div>
    );
}