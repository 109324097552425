// Modules
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSmoothScroll } from '@Utils/hooks/useSmoothScroll.js';

// State
import { VisualEditorState } from '@State/VisualEditor/VisualEditorState.js';

// Components
import FrostedGlass from '@Utils/components/FrostedGlass/FrostedGlass.js';
import GuiGroup from '@Shared-Components/Gui/Elements/Group/Group.js';
import ZoomIndicator from './ZoomIndicator/ZoomIndicator.js';

// Styles
import s from './RightMenu.module.css';
import fadeIn from '@Styles/Animations/FadeIn/FadeIn.module.css';

// Component function
export default function RightMenu() {

    const rightMenuRef = useSmoothScroll(null);
    const [editing, setEditing] = useState(false);

    // View nodemap
    //--------------------------------------------------------------------------------

    const { id } = useParams();

    function viewNodemap() {
        window.location.href = `ui/${id}`;
    }

    // Instantiate gui if necessary
    //--------------------------------------------------------------------------------

    if (Object.keys(VisualEditorState.nodemap.instance.gui || {}).length < 1) { VisualEditorState.nodemap.instance.makeGui(); }

    const gui = VisualEditorState.nodemap.instance.gui;

    // Component HTML
    //--------------------------------------------------------------------------------

    return (
        <div className={s['right-menu-container']}>

            <div className={s['selector-and-zoom-indicator']}>
                <ZoomIndicator />
            </div>

            <div className={s['right-menu']} ref={rightMenuRef}>
                <div className={s['edit-and-view-buttons']}>
                    <span className={s['edit-button']} onClick={() => { setEditing(!editing); }}>{editing ? 'Stop editing' : 'Edit'}</span>
                    <span className={s['view-button']} onClick={() => { viewNodemap(); }}>View UI ↗</span>
                </div>
                <GuiGroup group={gui} className={s['nodemap-gui']} editing={editing} />
            </div>

        </div>
    );
}