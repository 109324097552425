// Styles
import s from './Settings.module.css';

// Component function
export default function Settings() {

    // Component HTML
    //--------------------------------------------------------------------------------

    return (
        <div className={s['settings']}>

            

        </div>
    );
}