// Modules
import { useEffect, useRef } from 'react';
import { Buffer, Stream } from '@edisonai/datatypes';

// Components
import ElementHeader from '../../SubElements/Header/ElementHeader.js';
import ElementErrorMessage from '../../SubElements/ErrorMessage/ElementErrorMessage.js';

// Styles
import './TextArea.css';

// Component
export default function TextArea({ element, editing }) {

    // Display value
    //----------------------------------------------------------------------------------------------------

    const displayValue = getDisplayValue();

    // Get display value as string
    function getDisplayValue() {

        try {
            return String(element.getValue() || '');
        }

        catch (e) {
            element.error(new Error(`Could not convert value to String`));
        }
    }

    // Updating value
    //----------------------------------------------------------------------------------------------------

    const textareaRef = useRef(null);

    useEffect(() => { return element.listen('updateValue', () => {
        textareaRef.current.value = getDisplayValue();
    }); }, []);

    // Update value of element
    function updateValue() {
        if (textareaRef.current.value.endsWith('...')) {
            streamTest(textareaRef.current.value);
            return;
        }
        element.updateValue(textareaRef.current.value);
    }

    async function streamTest(value) {

        console.log('Text Area | Streaming...');

        element.updateValue(null);

        const stream = new Stream();
        const buffer = new Buffer({
            onFirst: (char) => { sendChunk(stream.first(char)); },
            onItem: (char) => { sendChunk(stream.chunk(char)); },
            onLast: (char) => { sendChunk(stream.last(char));},
            rate: 100000000,
        });

        buffer.add(...value);
        buffer.end();

        function sendChunk(chunk) {
            element.message(chunk).submit(chunk);
        }
    }

    // Resize
    //----------------------------------------------------------------------------------------------------

    function resizeTextArea(e) {

        if (!element.style) { element.style = {}; }

        const verticalScrollbar = textareaRef.current.scrollHeight > textareaRef.current.clientHeight;
        const horizontalScrollbar = textareaRef.current.scrollWidth > textareaRef.current.clientWidth;

        element.style.height = textareaRef.current.clientHeight + (horizontalScrollbar ? 15 : 0) + 'px';
        element.style.width = textareaRef.current.clientWidth + (verticalScrollbar ? 15 : 0) + 'px';

        element.emit('update');
    }

    // Auto-resize
    //----------------------------------------------------------------------------------------------------

    useEffect(() => { if (element.autoResize) { resize(); } }, [displayValue]);

    function getHeight() { return textareaRef.current.clientHeight; }
    function setHeight(newHeight) { textareaRef.current.style.height = newHeight + 'px'; }

    // We must always set the height to zero before getting the scrollheight
    function getScrollHeight() {

        const originalHeight = getHeight();
        setHeight(0);
        const scrollHeight = textareaRef.current.scrollHeight;
        setHeight(originalHeight);

        return scrollHeight;
    }

    // Automatically resize text area
    function resize() {
        
        const height = getHeight();
        const scrollHeight = getScrollHeight();

        // If scroll content is greater than height
        if (scrollHeight - height > 0) { setHeight(scrollHeight); }

        // If scroll content is less than height with a slight margin
        if (scrollHeight - height < 0 && scrollHeight - height > - 25) { setHeight(scrollHeight); }
    }

    // Element specific events
    //----------------------------------------------------------------------------------------------------

    // Attach listed events to element
    useEffect(() => {

        // Requires valid inputRef
        if (!textareaRef.current) { return; }

        const functions = {};

        // Define and attach functions, add event listener
        for (const eventName in element.events) { functions[eventName] = (e) => { element.onEvent(eventName, e, element.events[eventName]); } }
        for (const eventName in element.events) { textareaRef.current.addEventListener(eventName, functions[eventName]); }

        return () => {

            // Remove event listenersw
            if (!textareaRef.current) { return; }
            for (const eventName in element.events) { textareaRef.current.removeEventListener(eventName, functions[eventName]); }
        };
    }, []);

    // Attach events
    //----------------------------------------------------------------------------------------------------

    const containerRef = useRef(null);

    function showContainerHighlight() { showElementHighlight(containerRef); }
    function hideContainerHighlight() { hideElementHighlight(containerRef); }

    function showTextAreaHighlight() { showElementHighlight(textareaRef); }
    function hideTextAreaHighlight() { hideElementHighlight(textareaRef); }

    // Show / hide element highlight
    function showElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'true'); } catch { } }
    function hideElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'false'); } catch { } }

    useEffect(() => {
        element.extend({ showContainerHighlight, hideContainerHighlight, showTextAreaHighlight, hideTextAreaHighlight });
    }, []);

    // Component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={`gui-element ${element.id}-container`} ref={containerRef}>

            <ElementHeader element={element} editing={editing} />

            <textarea
                className={`text-area ${element.errorMessage ? 'text-area-error' : ''} ${element.id}-text-area`}
                ref={textareaRef}

                type='text'
                value={displayValue}
                onChange={updateValue}

                placeholder={element.placeholder || ''}

                style={{
                    resize: element.resize,
                    minHeight: element.minHeight || undefined,
                    maxHeight: element.maxHeight || undefined,
                    ...element.style,
                }}

                {...element.properties}

                onMouseUp={resizeTextArea}
                onMouseLeave={resizeTextArea}

            ></textarea>

            <ElementErrorMessage message={element.errorMessage} />

            {/* custom style tags */}
            <style>{`.${element.id}-container {${element.containerStyle}}`}</style>
            <style>{`.${element.id}-text-area {${element.textareaStyle}}`}</style>
        </div>
    );
}