// Modules
import { useEffect, useRef, useState } from 'react';
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { Recorder } from './Utils/Recorder.js';
import { MpegAudio, MpegFrame, Stream } from '@edisonai/datatypes';
import * as MusicMetadata from 'music-metadata-browser';

// Components
import ElementHeader from '../../SubElements/Header/ElementHeader.js';
import ElementErrorMessage from '../../SubElements/ErrorMessage/ElementErrorMessage.js';
import AudioPlayer from './AudioPlayer/AudioPlayer.js';

// Resources
import audioIcon from '../../Icons/audio.svg';

// Styles
import './Audio.css';

// Component
export default function Audio({ element, editing }) {

    //console.log(element);

    const refresh = useRefresh();

    // Display value
    //----------------------------------------------------------------------------------------------------

    const displayValue = getDisplayValue();

    // Get display value as string
    function getDisplayValue() {

        try {
            const value = element.getValue();
            return value;
        }

        catch (e) {
            console.error(e);
            element.error(new Error(`Could not convert value to AudioMpeg`));
        }
    }

    // Recording
    //----------------------------------------------------------------------------------------------------

    const [recording, setRecording] = useState(false);
    const [voiceActivity, setVoiceActivity] = useState(false);
    const recorder = useRef();

    async function toggleRecording() {

        try {
            element.clearError();
            if (!recording) { await startRecording(); }
            else { await stopRecording(); }

            element.setIndex(Infinity);
        }

        catch (e) {
            removeProgressCursor();
            element.error(e);
        }
    }

    async function startRecording() {

        // Don't start if it's already recording
        if (recorder.current?.recording || recorder.current?.starting) { return; }

        addProgressCursor();

        //element.setIndex((element.value?.length || 1) - 1);

        const stream = new Stream();
        recorder.current = new Recorder({

            onStart: (frame) => { sendChunk(stream.first(frame)); },
            onFrame: (frame) => { sendChunk(stream.chunk(frame)); },
            onStop: (frame) => { sendChunk(stream.last(frame)); },

            onVadChange: (value) => { setVoiceActivity(value); },

            options: {
                useVoiceDetection: element.useVoiceDetection,
                frameRate: 4
            }
        });

        await recorder.current.start();

        removeProgressCursor();
        setRecording(true);

        function sendChunk(chunk) {
            try { element.message(chunk.shard()).submit(chunk.shard()); }
            catch (e) { element.error(e); stopRecording(); }
        }
    }

    async function stopRecording() {

        if (!recorder.current?.recording || recorder.current?.stopping) { return; }

        addProgressCursor();

        await recorder.current.stop();

        setRecording(false);
        removeProgressCursor();
    }

    function addProgressCursor() { document.body.classList.add('cursor-progress'); }
    function removeProgressCursor() { document.body.classList.remove('cursor-progress'); }

    // Updating value
    //----------------------------------------------------------------------------------------------------

    async function handleUpload(e) {

        try {

            const file = e.target.files[0];

            if (file) {
                const audio = await MpegAudio(file);
                element.updateValue(audio);
            }
        }

        catch (e) {
            console.warn('Failed to get file and create audio message');
        }
    }

    // Attach events
    //----------------------------------------------------------------------------------------------------

    const containerRef = useRef(null);
    const elementRef = useRef(null);
    const AudioIconRef = useRef(null);
    const textRef = useRef(null);

    function showContainerHighlight() { showElementHighlight(containerRef); }
    function hideContainerHighlight() { hideElementHighlight(containerRef); }
    function showAudioElementHighlight() { showElementHighlight(elementRef); }
    function hideAudioElementHighlight() { hideElementHighlight(elementRef); }
    function showAudioIconHighlight() { showElementHighlight(AudioIconRef); }
    function hideAudioIconHighlight() { hideElementHighlight(AudioIconRef); }
    function showTextHighlight() { showElementHighlight(textRef); }
    function hideTextHighlight() { hideElementHighlight(textRef); }

    // Show / hide element highlight
    function showElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'true'); } catch { } }
    function hideElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'false'); } catch { } }


    useEffect(() => {
        element.extend({
            showContainerHighlight,
            hideContainerHighlight,
            showAudioElementHighlight,
            hideAudioElementHighlight,
            showAudioIconHighlight,
            hideAudioIconHighlight,
            showTextHighlight,
            hideTextHighlight
        });
    }, []);

    // Component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={`gui-element ${element.id}-container`} ref={containerRef}>

            <ElementHeader element={element} editing={editing} />

            <div className={`audio ${element.id}-elementStyle ${element.errorMessage ? 'audio-error' : ''}`} ref={elementRef}>

                {/* Handle the record button */}
                {element.showRecordButton ?
                    <div className={`record-button ${element.id}-recordButtonStyle ${recording ? 'recording' : ''} ${voiceActivity ? 'speaking' : ''}`} onClick={toggleRecording}>
                        <svg className={'record-button-icon'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M19 10V12C19 15.866 15.866 19 12 19M5 10V12C5 15.866 8.13401 19 12 19M12 19V22M8 22H16M12 15C10.3431 15 9 13.6569 9 12V5C9 3.34315 10.3431 2 12 2C13.6569 2 15 3.34315 15 5V12C15 13.6569 13.6569 15 12 15Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                    </div>
                    : null}

                { /* if no audio data, show audio upload element */}
                {!displayValue ?
                    <>
                        <div className={'audio-placeholder'}>
                            <img className={`audio-icon ${element.id}-placeHolderStyle`} ref={AudioIconRef} src={audioIcon} alt={''}></img>
                            <span ref={textRef}>{String(element.placeholder) || 'Click to Upload'}</span>
                        </div>

                        <input className={'audio-upload'} type={'file'} accept={'audio/mpeg'} onChange={handleUpload}></input>
                    </>
                    : null}

                {displayValue ? <AudioPlayer {...{ element, source: displayValue }} /> : null}
            </div>

            <ElementErrorMessage message={element.errorMessage} />


            <style>{`.${element.id}-container {${element.containerStyle}}`}</style>
            <style>{`.${element.id}-placeHolderStyle {${element.placeholderStyle}}`}</style>
            <style>{`.${element.id}-elementStyle {${element.elementStyle}}`}</style>
            <style>{`.${element.id}-recordButtonStyle {${element.recordButtonStyle}}`}</style>
            <style>{`.${element.id}-recordImageStyle {${element.recordImageStyle}}`}</style>
        </div>
    );
}