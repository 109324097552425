// Modules
import { useEffect, useRef } from 'react';
import { useSmoothScroll } from '@Utils/hooks/useSmoothScroll.js';
import { List, ChatMessage } from '@edisonai/datatypes';

// Components
import ElementHeader from '../../SubElements/Header/ElementHeader.js';
import ElementErrorMessage from '../../SubElements/ErrorMessage/ElementErrorMessage.js';
import ChatHistoryMessage from './ChatHistoryMessage/ChatHistoryMessage.js';

// Styles
import './ChatHistory.css';

// Component
export default function ChatHistory({ element, editing }) {

    // Resize
    //----------------------------------------------------------------------------------------------------

    const chatHistoryRef = useSmoothScroll()

    useEffect(() => { if (element.autoResize) { resize(); } }, [element.value]);

    function getHeight() { return chatHistoryRef.current.clientHeight; }
    function setHeight(newHeight) { chatHistoryRef.current.style.height = newHeight + 2 + 'px'; }

    // We must always set the height to zero before getting the scrollheight
    function getScrollHeight() {

        const originalHeight = getHeight();
        setHeight(0);
        const scrollHeight = chatHistoryRef.current.scrollHeight;
        setHeight(originalHeight);

        return scrollHeight;
    }

    // Automatically resize text area
    function resize() {

        const height = getHeight();
        const scrollHeight = getScrollHeight();

        // If scroll content is greater than height
        setHeight(scrollHeight);
    }

    // Scroll to bottom if we're already at bottom
    function autoScroll(force) {
        const distanceFromBottom = chatHistoryRef.current.scrollHeight - chatHistoryRef.current.scrollTop - chatHistoryRef.current.clientHeight;
        if (distanceFromBottom < 100 || force) { chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight; }
    }

    useEffect(() => {
        autoScroll(true);
        return element.listen('update', () => { setTimeout(() => { autoScroll(); }, 5); });
    }, []);

    // Detecting resize

    function resizeDetected() {

        if (chatHistoryRef.current.scrollWidth > chatHistoryRef.current.clientWidth) { element.height = chatHistoryRef.current.clientHeight + 17; return; }
        element.height = chatHistoryRef.current.clientHeight + 2;
    }

    useEffect(() => {
        const observer = new ResizeObserver((entries) => { for (const entry of entries) { resizeDetected(); } });
        if (chatHistoryRef.current) { observer.observe(chatHistoryRef.current); }
        return () => { observer.disconnect(); }
    }, [chatHistoryRef])

    // Managing items
    //----------------------------------------------------------------------------------------------------

    function update() {
        element.emit('update');
    }

    // Add a new item to the list
    function addNewItem(newItem, index) {

        // Init element value if necessary
        if (!element.value) { element.value = new List(); }

        element.value.splice(index + 1, 0, newItem);
        element.emit('update');

        window.setTimeout(() => { focusItem(index + 1); }, 10);
    }

    // Update an existing item in the list
    function updateItem(newItem, index) {

        if (!element.value) { element.value = new List(); }
        element.value.splice(index, 1, newItem);

        element.emit('update');
    }

    function deleteItem(index) {
        element.value.splice(index, 1);
        element.emit('update');
    }

    function focusItem(index) {
        element.emit('focus' + index);
    }

    // Attach events
    //----------------------------------------------------------------------------------------------------

    const containerRef = useRef(null);

    function showContainerHighlight() { showElementHighlight(containerRef); }
    function hideContainerHighlight() { hideElementHighlight(containerRef); }

    function showMessageHistoryHighlight() { showElementHighlight(chatHistoryRef); }
    function hideMessageHistoryHighlight() { hideElementHighlight(chatHistoryRef); }

    // Show / hide element highlight
    function showElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'true'); } catch { } }
    function hideElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'false'); } catch { } }

    useEffect(() => {
        element.extend({ showContainerHighlight, hideContainerHighlight, showMessageHistoryHighlight, hideMessageHistoryHighlight });
    }, []);

    // Component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={`gui-element ${element.id}-container chat-history-element`} ref={containerRef}>

            <ElementHeader element={element} editing={editing} />

            <div className={`chat-history-relative ${element.id}-message-history ${element.errorMessage ? 'chat-history-error' : ''}`}
                style={{
                    resize: element.resizable ? 'none' : 'none',
                    //height: element.resizable && element.height ? element.height + 'px' : undefined,
                    //minHeight: element.minHeight || undefined,
                    //maxHeight: element.maxHeight || undefined,
                }}
            >
                <div className={`chat-history-absolute`} ref={chatHistoryRef}>

                    <div style={{ marginTop: 'auto' }}></div>

                    {element.value?.map((item, index) => {
                        
                        return (
                            <ChatHistoryMessage
                                value={ChatMessage(item)}
                                editable={false}
                                number={index + 1 + '.'}
                                index={index}
                                addNewItem={addNewItem}
                                updateItem={(newItem) => { updateItem(newItem, index); }}
                                deleteItem={() => { deleteItem(index); }}/*  */
                                focusItem={focusItem}
                                update={update}
                                element={element}
                                key={index}
                                id={element.id}
                                attachMethods={element.attachMethods.bind(element)}
                            />
                        );
                    })}
                </div>
            </div>

            {/* custom styles */}
            <style>{`.${element.id}-container {${element.containerStyle}}`}</style>
            <style>{`.${element.id}-message-history {${element.messageHistoryStyle}}`}</style>
            <style>{`.${element.id}-message {${element.messageContainerStyle}}`}</style>
            <style>{`.${element.id}-message-text {${element.messageStyle}}`}</style>
            <style>{`.${element.id}-user-message-text {${element.userMessageStyle}}`}</style>
            <style>{`.${element.id}-assistant-message-text {${element.assistantMessageStyle}}`}</style>
            <style>{`.${element.id}-system-message-text {${element.systemMessageStyle}}`}</style>
            <style>{`.${element.id}-details {${element.nameAndTimeStyle}}`}</style>

            <ElementErrorMessage message={element.errorMessage} />
        </div>
    );
}