// Modules
import { useEffect } from 'react';
import { Inspector } from 'react-inspector';

// State
import { useRefresh } from '@Utils/hooks/useRefresh.js';

// Styles
import s from './LogItem.module.css';

const theme = {

	BASE_FONT_FAMILY: 'Ubuntu Mono, monospace',
	BASE_FONT_SIZE: '11px',
	BASE_LINE_HEIGHT: 1.2,

	BASE_BACKGROUND_COLOR: 'transparent',
	BASE_COLOR: 'rgb(213, 213, 213)',

	OBJECT_PREVIEW_ARRAY_MAX_PROPERTIES: 10,
	OBJECT_PREVIEW_OBJECT_MAX_PROPERTIES: 5,
	OBJECT_NAME_COLOR: '#7CACF8',
	OBJECT_VALUE_NULL_COLOR: 'rgb(127, 127, 127)',
	OBJECT_VALUE_UNDEFINED_COLOR: 'rgb(127, 127, 127)',
	OBJECT_VALUE_REGEXP_COLOR: 'rgb(233, 63, 59)',
	OBJECT_VALUE_STRING_COLOR: '#5CD5FB',
	OBJECT_VALUE_SYMBOL_COLOR: 'rgb(233, 63, 59)',
	OBJECT_VALUE_NUMBER_COLOR: '#847FFF',
	OBJECT_VALUE_BOOLEAN_COLOR: 'hsl(252, 100%, 75%)',
	OBJECT_VALUE_FUNCTION_PREFIX_COLOR: 'rgb(85, 106, 242)',

	HTML_TAG_COLOR: 'rgb(93, 176, 215)',
	HTML_TAGNAME_COLOR: 'rgb(93, 176, 215)',
	HTML_TAGNAME_TEXT_TRANSFORM: 'lowercase',
	HTML_ATTRIBUTE_NAME_COLOR: 'rgb(155, 187, 220)',
	HTML_ATTRIBUTE_VALUE_COLOR: 'rgb(242, 151, 102)',
	HTML_COMMENT_COLOR: 'rgb(137, 137, 137)',
	HTML_DOCTYPE_COLOR: 'rgb(192, 192, 192)',

	ARROW_COLOR: 'rgb(145, 145, 145)',
	ARROW_MARGIN_RIGHT: 4,
	ARROW_MARGIN_LEFT: 8,
	ARROW_FONT_SIZE: 8,
	ARROW_ANIMATION_DURATION: '0.25s',

	TREENODE_FONT_FAMILY: 'Ubuntu Mono, monospace',
	TREENODE_FONT_SIZE: '12px',
	TREENODE_LINE_HEIGHT: '125%',
	TREENODE_PADDING_LEFT: 15,

	TABLE_BORDER_COLOR: 'rgb(85, 85, 85)',
	TABLE_TH_BACKGROUND_COLOR: 'rgb(44, 44, 44)',
	TABLE_TH_HOVER_COLOR: 'rgb(48, 48, 48)',
	TABLE_SORT_ICON_COLOR: 'black', //'rgb(48, 57, 66)',
	TABLE_DATA_BACKGROUND_IMAGE:
		'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 50%, rgba(51, 139, 255, 0.0980392) 50%, rgba(51, 139, 255, 0.0980392))',
	TABLE_DATA_BACKGROUND_SIZE: '128px 32px',
};

// Component function
export default function LogItem({ log }) {

	const refresh = useRefresh();

	// Component html
	//----------------------------------------------------------------------------------------------------

	return (
		<div className={s['log-item']}>

			<div className={s['log-item-content']} style={{ color: theme.OBJECT_VALUE_STRING_COLOR }}>
				{Object.values(log.items || []).map((item, index) => {
					return (
						<LogSubItem item={item} key={index} />
					);
				})}
			</div>

			<span className={s['log-timestamp']}>{` (${new Date(log.time).toLocaleTimeString('en-US', { date: 'day', hour: 'numeric', minute: '2-digit' })})`}</span>
		</div>
	);
}

function LogSubItem({ item }) {

	const refresh = useRefresh();

	useEffect(() => {
		return item?.listen?.('update', refresh);
	}, [item]);

	// Basic types
	if (typeof item === 'string') { return <>{item}&nbsp;</>; }

	return (
		<div className={s['log-item-inspector']}>
			<Inspector data={item?.isChunk ? item.data : item} theme={theme} showNonenumerable={false} />
		</div>
	);
}

function LogNormalNumber({ item }) {

}

function LogObject({ item }) {

}