// Modules
import { useEffect, useRef } from 'react';
import { ChatMessage } from '@edisonai/datatypes';

// Components
import ElementHeader from '../../SubElements/Header/ElementHeader.js';
import ElementErrorMessage from '../../SubElements/ErrorMessage/ElementErrorMessage.js';

// Resources
import sendIcon from '../../Icons/sendIcon.svg';

// Styles
import './ChatMessage.css';

// Component
export default function ChatMessageComponent({ element, editing }) {

    // Submit element
    //----------------------------------------------------------------------------------------------------

    function submitElement() {
        element.submit();
    }

    // Display value
    //----------------------------------------------------------------------------------------------------

    const displayValue = getDisplayValue();

    // Get display value as string
    function getDisplayValue() {

        try {
            return String(element.getValue() || '');
        }

        catch (e) {
            console.error(e);
            element.error(new Error(`Could not convert value to String`));
        }
    }

    // Updating value
    //----------------------------------------------------------------------------------------------------

    const textareaRef = useRef(null);

    // Update value of element
    function updateValue() {

        // Get input value and make new StringMessage
        if(!textareaRef.current.value) {
            element.updateValue(null);
            return;
        }

        const newMessage = ChatMessage({
            content: textareaRef.current.value || undefined,
            role: element.messageRole || undefined,
            name: element.messageName || undefined,
        });

        element.updateValue(newMessage);
    }

    function textareaKeyDown(e) {

        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            submitElement();
        }
    }

    // Auto-resize
    //----------------------------------------------------------------------------------------------------

    useEffect(() => { resize(); }, [displayValue]);

    function getHeight() { return textareaRef.current.clientHeight; }
    function setHeight(newHeight) { textareaRef.current.style.height = newHeight + 2 + 'px'; }

    // We must always set the height to zero before getting the scrollheight
    function getScrollHeight() {

        const originalHeight = getHeight();
        setHeight(0);
        const scrollHeight = textareaRef.current.scrollHeight;
        setHeight(originalHeight);

        return scrollHeight;
    }

    // Automatically resize text area
    function resize() {
        const scrollHeight = getScrollHeight();
        setHeight(scrollHeight);
    }

    // Attach events
    //----------------------------------------------------------------------------------------------------

    const containerRef = useRef(null);
    const submitButtonRef = useRef(null);

    function showContainerHighlight() { showElementHighlight(containerRef); }
    function hideContainerHighlight() { hideElementHighlight(containerRef); }

    function showTextAreaHighlight() { showElementHighlight(textareaRef); }
    function hideTextAreaHighlight() { hideElementHighlight(textareaRef); }

    function showSubmitButtonHighlight() { showElementHighlight(submitButtonRef); }
    function hideSubmitButtonHighlight() { hideElementHighlight(submitButtonRef); }

    // Show / hide element highlight
    function showElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'true'); } catch { } }
    function hideElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'false'); } catch { } }

    useEffect(() => {
        element.extend({ showContainerHighlight, hideContainerHighlight, showTextAreaHighlight, hideTextAreaHighlight, showSubmitButtonHighlight, hideSubmitButtonHighlight });
    }, []);

    // Component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={`gui-element ${element.id}-container`} ref={containerRef}>

            <ElementHeader element={element} editing={editing} />

            <div className='chat-message-container'>

                <textarea
                    className={`chat-message ${element.errorMessage ? 'chat-message-error' : ''} ${element.id}-text-area`}
                    ref={textareaRef}

                    type='text'
                    value={displayValue}
                    onKeyDown={textareaKeyDown}
                    onChange={updateValue}

                    placeholder={element.placeholder || ''}

                    style={{
                        resize: element.resizable ? 'vertical' : 'none',
                        minHeight: element.minHeight || undefined,
                        maxHeight: element.maxHeight || undefined,
                    }}

                    {...element.properties}

                ></textarea>

                <img className={`chat-message-submit-button ${element.id}-submit-button`} ref={submitButtonRef} src={sendIcon} alt={''} onClick={submitElement}></img>

            </div>

            <ElementErrorMessage message={element.errorMessage} />

            {/* custom style tags */}
            <style>{`.${element.id}-container {${element.containerStyle}}`}</style>
            <style>{`.${element.id}-text-area {${element.textareaStyle}}`}</style>
            <style>{`.${element.id}-submit-button {${element.submitButtonStyle}}`}</style>
        </div>
    );
}