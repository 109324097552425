// Modules
import { useEffect } from 'react';

// State
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { VisualEditorState } from '@State/VisualEditor/VisualEditorState.js';

// Styles
import s from './PositionIndicator.module.css';
import fadeIn from '@Styles/Animations/FadeIn/FadeIn.module.css';

// Component function
export default function PositionIndicator() {

    const refresh = useRefresh();
    useEffect(() => { return VisualEditorState.nodemap.instance.listen('updateViewportPosition', refresh); }, []);

    const { x, y } = VisualEditorState.nodemap.instance.settings.viewportPosition || { x: 0, y: 0 };

    function centerViewport() {
        VisualEditorState.nodemap.instance.updateViewportPosition({ x: 0, y: 0 });
    }

    // Component HTML
    //--------------------------------------------------------------------------------

    return (
        <div className={s['position-indicator'] + ' ' + fadeIn['one-s']} onClick={centerViewport} title={'Center Viewport'}>
            {`${x.toFixed(0)}, ${y.toFixed(0)}`}
        </div>
    );
}