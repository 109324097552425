// Modules
import { useEffect, useState } from 'react';
import { useRefresh } from '@Utils/hooks/useRefresh.js';

// Elements
import { elements } from '../Elements/Elements.js';

// Styles
import './Element.css';

export default function GuiElement({ element, editing }) {

    // Allow element class to trigger update in component
    const refresh = useRefresh();
    useEffect(() => { return element.listen('update', refresh); }, []);

    // Component
    //----------------------------------------------------------------------------------------------------

    const CustomElement = elements[element.type];

    if (!CustomElement) { return null; }

    return (
        <CustomElement element={element} editing={editing} />
    );
}