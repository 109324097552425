// Modules
import { useRef, useState, useEffect } from 'react';

// Components
import FrostedGlass from '@Utils/components/FrostedGlass/FrostedGlass.js';
import ElementHeader from '../../SubElements/Header/ElementHeader.js';
import ElementErrorMessage from '../../SubElements/ErrorMessage/ElementErrorMessage.js';

// Resources
import triangleIcon from './triangle.svg';

// Styles
import './Dropdown.css';

// Component
export default function Dropdown({ element, editing }) {

    const dropDown = useRef(null);
    const [openDropdown, setOpenDropdown] = useState(false);

    // Display value
    //----------------------------------------------------------------------------------------------------

    const displayValue = getDisplayValue();

    // Get display value as string
    function getDisplayValue() {

        try {
            return String(element.getValue() || '');
        }

        catch (e) {
            console.error(e);
            element.error(new Error(`Could not convert value to String`));
        }
    }


    // Updating value(s)
    //----------------------------------------------------------------------------------------------------

    if (typeof element.values === 'string') {
        try { element.values = element.values.split(', '); }
        catch (e) { element.values = []; }
    }

    // Find name of selected value or just the value itself
    let value = element.values?.find((value) => { return (value?.value || value) === displayValue; });
    let valueName = value?.name || value;

    // Update value of element
    function updateValue(newValue) {

        element.updateValue(newValue);

        dropDown.current.dispatchEvent(new Event('change'));
    }

    // Element specific events
    //----------------------------------------------------------------------------------------------------

    // Attach listed events to element
    useEffect(() => {

        // Requires valid inputRef
        if (!dropDown.current) { return; }

        const functions = {};

        // Define and attach functions, add event listener
        for (const eventName in element.events) { functions[eventName] = (e) => { element.onEvent(eventName, e, element.events[eventName]); } }
        for (const eventName in element.events) { dropDown.current.addEventListener(eventName, functions[eventName]); }

        return () => {

            // Remove event listeners
            if (!dropDown.current) { return; }
            for (const eventName in element.events) { dropDown.current.removeEventListener(eventName, functions[eventName]); }
        };
    }, []);

    // Attach events
    //----------------------------------------------------------------------------------------------------

    const containerRef = useRef(null);
    const displayValueRef = useRef(null);
    const placeholderRef = useRef(null);
    const optionsContainerRef = useRef(null);
    const optionRef = useRef(null);
    const iconRef = useRef(null);

    function showContainerHighlight() { showElementHighlight(containerRef); }
    function hideContainerHighlight() { hideElementHighlight(containerRef); }

    function showDropdownHighlight() { showElementHighlight(dropDown); }
    function hideDropdownHighlight() { hideElementHighlight(dropDown); }

    function showDisplayValueHighlight() { showElementHighlight(displayValueRef); }
    function hideDisplayValueHighlight() { hideElementHighlight(displayValueRef); }

    function showPlaceholderHighlight() { showElementHighlight(placeholderRef); }
    function hidePlaceholderHighlight() { hideElementHighlight(placeholderRef); }

    function showOptionsContainerHighlight() { setOpenDropdown(true); showElementHighlight(optionsContainerRef); }
    function hideOptionsContainerHighlight() { setOpenDropdown(false); hideElementHighlight(optionsContainerRef); }

    function showOptionHighlight() { setOpenDropdown(true); showElementHighlight(optionRef); }
    function hideOptionHighlight() { setOpenDropdown(false); hideElementHighlight(optionRef); }

    function showIconHighlight() { showElementHighlight(iconRef); }
    function hideIconHighlight() { hideElementHighlight(iconRef); }

    // Show / hide element highlight
    function showElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'true'); } catch { } }
    function hideElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'false'); } catch { } }

    useEffect(() => {
        element.extend({ showContainerHighlight, hideContainerHighlight, showDropdownHighlight, hideDropdownHighlight, showDisplayValueHighlight, hideDisplayValueHighlight, showPlaceholderHighlight, hidePlaceholderHighlight, showOptionsContainerHighlight, hideOptionsContainerHighlight, showOptionHighlight, hideOptionHighlight, showIconHighlight, hideIconHighlight });
    }, []);

    // Component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={`gui-element ${element.id}-container`} ref={containerRef}>

            <ElementHeader element={element} editing={editing} />

            <div
                ref={dropDown}
                className={`dropdown ${element.errorMessage ? 'dropdown-error' : ''} ${openDropdown ? 'dropdown-open' : ''} ${element.id}-dropdown`}
                style={{ borderBottomColor: openDropdown ? 'transparent' : undefined }}
                onClick={() => { setOpenDropdown(!openDropdown) }}
                onMouseLeave={() => { { setOpenDropdown(false) } }}>

                {/* current dropdown value or placeholder */}
                {displayValue ? <div className={`dropdown-value-text ${element.id}-value-text`} ref={displayValueRef}>{valueName || displayValue}</div> : null}
                {!displayValue ? <div className={`dropdown-value-placeholder ${element.id}-placeholder`} ref={placeholderRef}>{String(element.placeholder)}&nbsp;</div> : null}

                {/* triangle icon */}
                <img className={`dropdown-triangle ${element.id}-icon`} ref={iconRef} src={triangleIcon} alt={''}></img>

                {/* dropdown options container */}
                <div style={{ position: 'absolute', bottom: '-1px', left: '0px', width: '100%', height: '1px', display: !openDropdown ? 'none' : undefined }}>
                    <FrostedGlass className={`dropdown-options-container ${element.id}-options-container`} ref={optionsContainerRef} style={{ display: !openDropdown ? 'none' : undefined }}>
                        {element.values?.map((option) => {
                            return (<div className={`dropdown-option ${element.id}-option`} key={option.name || option} ref={optionRef} onClick={() => { updateValue(typeof option.value === 'string' ? option.value : option) }}>{option.name || option}</div>)
                        })}
                    </FrostedGlass>
                </div>

                {/* custom style tags */}
                <style>{`.${element.id}-container {${String(element.containerStyle)}}`}</style>
                <style>{`.${element.id}-dropdown {${String(element.dropdownStyle)}}`}</style>
                <style>{`.${element.id}-value-text {${String(element.displayValueStyle)}}`}</style>
                <style>{`.${element.id}-placeholder {${String(element.placeholderStyle)}}`}</style>
                <style>{`.${element.id}-icon {${String(element.iconStyle)}}`}</style>
                <style>{`.${element.id}-options-container {${String(element.optionsContainerStyle)}}`}</style>
                <style>{`.${element.id}-option {${String(element.optionStyle)}}`}</style>

            </div>

            <ElementErrorMessage message={element.errorMessage} />
        </div>
    );
}