// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NodeInputs_node-inputs__QjVFR {

    /* flex options */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    /* dimentions */
    width: auto;
    height: 100%;

    /* padding */
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
}

    .NodeInputs_node-add-input-button__YYfim {

        /* dimentions */
        width: 16px;
        height: 16px;

        /* margins */
        margin-left: auto;
        margin-right: auto;

        /* pre-hover */
        opacity: 0.25;
        transition: all 0.25s;

    }   .NodeInputs_node-add-input-button__YYfim:hover {

        /* post-hover */
        opacity: 1;
        cursor: pointer;
    }`, "",{"version":3,"sources":["webpack://./src/VisualEditor/Sandbox/SandboxNodes/NodeComponent/NodeInputs/NodeInputs.module.css"],"names":[],"mappings":"AAAA;;IAEI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;;IAEvB,eAAe;IACf,WAAW;IACX,YAAY;;IAEZ,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;AACrB;;IAEI;;QAEI,eAAe;QACf,WAAW;QACX,YAAY;;QAEZ,YAAY;QACZ,iBAAiB;QACjB,kBAAkB;;QAElB,cAAc;QACd,aAAa;QACb,qBAAqB;;IAEzB,IAAI;;QAEA,eAAe;QACf,UAAU;QACV,eAAe;IACnB","sourcesContent":[".node-inputs {\n\n    /* flex options */\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: flex-start;\n\n    /* dimentions */\n    width: auto;\n    height: 100%;\n\n    /* padding */\n    padding-top: 4px;\n    padding-bottom: 4px;\n    padding-left: 4px;\n}\n\n    .node-add-input-button {\n\n        /* dimentions */\n        width: 16px;\n        height: 16px;\n\n        /* margins */\n        margin-left: auto;\n        margin-right: auto;\n\n        /* pre-hover */\n        opacity: 0.25;\n        transition: all 0.25s;\n\n    }   .node-add-input-button:hover {\n\n        /* post-hover */\n        opacity: 1;\n        cursor: pointer;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"node-inputs": `NodeInputs_node-inputs__QjVFR`,
	"node-add-input-button": `NodeInputs_node-add-input-button__YYfim`
};
export default ___CSS_LOADER_EXPORT___;
