// Modules
import { useEffect, useRef } from 'react';
import { ChatMessage } from '@edisonai/datatypes';

// Resources
import deleteIcon from '../../../Icons/delete.svg';

// Styles
import './ChatHistoryMessage.css';

const colorMap = {
    user: { outline: 'rgba(0, 238, 255, 0.25)', background: undefined, text: 'rgba(0, 238, 255, 0.9)' },
    assistant: { outline: 'rgba(255, 255, 255, 0.25)', background: undefined, text: 'rgba(255, 255, 255, 0.75)' }
}

// Component
export default function ChatHistoryMessage({ value, editable = true, updateItem, deleteItem, addNewItem, index, focusItem, update, attachMethods, id }) {

    // Get value as chat message
    //----------------------------------------------------------------------------------------------------

    const message = ChatMessage(value);

    // Focusing
    //----------------------------------------------------------------------------------------------------

    // Focus on item just before this one
    function focusPrev() {
        focusItem(index - 1);
    }

    // Focus on item just after this one
    function focusNext() {
        focusItem(index + 1);
    }

    // Updating text value (if text)
    //----------------------------------------------------------------------------------------------------

    const textareaRef = useRef(null);

    useEffect(() => {
        if (!textareaRef.current) { return; }
        value.focus = () => { textareaRef.current.focus() };
    }, [textareaRef.current, value]);

    // When there is a keyDown in the text area
    function textareaKeyDown(e) {

        if (e.key === 'Backspace' && !value.content) {
            deleteItem();
            window.setTimeout(() => { focusPrev(); }, 1);
            return;
        }

        if (e.key === 'Delete' && !value.content) {
            deleteItem();
            return;
        }

        // Navigating with arrow keys
        if (e.key === 'ArrowUp') { focusPrev(); }
        if (e.key === 'ArrowDown') { focusNext(); }
    }

    // When there is a change in the text area
    function textareaChange() {

        // Maintain cursor position
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        const start = range.startOffset;

        value.content = textareaRef.current.innerText;
        updateItem(value);

        // Set cursor position after component re-renders
        window.requestAnimationFrame(() => {

            if (!value.content) { return; }

            const newRange = document.createRange();

            newRange.setStart(textareaRef.current.firstChild, start);
            newRange.collapse(true);
            selection.removeAllRanges();
            selection.addRange(newRange);
        });
    }

    // Attach events
    //----------------------------------------------------------------------------------------------------

    const messageRef = useRef(null);
    const messageNameAndTimeRef = useRef(null);

    function showMessageHighlight() { showElementHighlight(messageRef); }
    function hideMessageHighlight() { hideElementHighlight(messageRef); }

    function showMessageTextHighlight() { showElementHighlight(textareaRef); }
    function hideMessageTextHighlight() { hideElementHighlight(textareaRef); }

    function showNameAndTimeHighlight() { showElementHighlight(messageNameAndTimeRef); }
    function hideNameAndTimeHighlight() { hideElementHighlight(messageNameAndTimeRef); }

    // Show / hide element highlight
    function showElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'true'); } catch { } }
    function hideElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'false'); } catch { } }

    useEffect(() => {
        attachMethods({ showMessageHighlight, hideMessageHighlight, showMessageHighlight, showMessageTextHighlight, hideMessageTextHighlight, showNameAndTimeHighlight, hideNameAndTimeHighlight });
    }, []);

    // Component
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={`chat-history-message ${id}-message`} ref={messageRef} style={{ marginRight: value.role !== 'user' ? 'auto' : undefined, marginLeft: value.role === 'user' ? 'auto' : undefined, alignItems: value.role === 'user' ? 'flex-end' : 'flex-start' }}>

            {/* text value */}
            <div className={`chat-history-message-text chat-history-${value.role}-message-text ${id}-${value.role}-message-text ${id}-message-text`} ref={textareaRef} contentEditable={editable} suppressContentEditableWarning='true' onKeyDown={textareaKeyDown} onInput={textareaChange}>{String(value)}</div>

            {/* name and time */}
            <div className={`chat-history-message-details ${id}-details`} ref={messageNameAndTimeRef} style={{ justifyContent: value.role !== 'user' ? 'flex-start' : 'flex-end' }}>
                <div className='chat-history-message-name'>{value.role || value.name || 'system'}</div> {/* BAND-AID */}
                <div className='chat-hisotry-message-time'>{new Date(value.date || Date.now()).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })}</div>
            </div>
        </div>
    );
}