// Modules
import modular from '@edisonai/modular';
import { useEffect, useRef } from 'react';

// State
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { VisualEditorState } from '@State/VisualEditor/VisualEditorState.js';

// Components
import SandboxNodes from './SandboxNodes/SandboxNodes.js';
import SandboxConnections from './SandboxConnections/SandboxConnections.js';
import TestIframeComponent from './TestIframeComponent/TestIframeComponent.js';

// Styles
import s from './Sandbox.module.css';

// Component function
export default function Sandbox({ nodemap }) {

    //nodemap.options.secrets.permission();
    //console.log(nodemap.options.secrets.hello);

    const editor = VisualEditorState;
    const refresh = useRefresh();

    if (!nodemap.settings) { nodemap.settings = {}; }
    if (!nodemap.settings.viewportZoom) { nodemap.settings.viewportZoom = 1.00; }
    if (!nodemap.settings.viewportPosition) { nodemap.settings.viewportPosition = { x: 0, y: 0 }; }

    useEffect(() => { return nodemap.listen('update', refresh); }, [])

    // Drag / drop new nodesAlright. Say we're in react and we have some modules we want to import. How can we 
    //--------------------------------------------------------------------------------

    function onDragEnter(e) {
        e.preventDefault();
        sandboxRef.current.classList.add(s['dragging']);
    }

    function onDragOver(e) {
        e.preventDefault();
    }

    function onDragLeave(e) {
        e.preventDefault();
        sandboxRef.current.classList.remove(s['dragging']);
    }

    function onDrop(e) {

        try {

            // Prevent default and remove dragging highlight
            e.preventDefault();
            sandboxRef.current.classList.remove(s['dragging']);

            const mousePos = getMousePosInMap(e);

            // Get files
            const { files } = e.dataTransfer;
            if (!files || files.length < 1) { return; }

            [...files].forEach(async (file) => {
                try {
                    if (!(file?.type?.includes('javascript') || file?.name?.endsWith('.node'))) { return; }
                    const nodeModule = await VisualEditorState.addNodeModule(file);
                    console.log(nodeModule)
                    await nodemap.addNode(structuredClone(nodeModule.template), mousePos);
                }

                catch (e) {
                    console.error(e);
                    VisualEditorState.error({ title: 'Could not add node', text: e.message });
                }
            });
        }

        catch (e) {
            console.error(e);
        }
    }

    // Context Menu
    //----------------------------------------------------------------------------------------------------

    function showContext(e) {

    }

    // Wheel and keydown
    //----------------------------------------------------------------------------------------------------

    useEffect(() => {

        if (!sandboxRef.current) { return; }
        sandboxRef.current.addEventListener('wheel', onWheel);

        return () => {
            if (!sandboxRef.current) { return; }
            sandboxRef.current.removeEventListener('wheel', onWheel);
        }
    }, []);

    function onKeyDown(e) {

        if (nodemap?.commandsBlocked) { return; }

        e.stopPropagation();

        if (e.key === 'Delete') {
            e.preventDefault();
            nodemap.deleteSelectedNodes(true);
        }

        if (e.ctrlKey) {

            if (e.key === 'a') {
                e.preventDefault();
                nodemap.selectAll();
            }

            if (e.key === 's') {
                e.preventDefault();
                nodemap.emit('save');
            }
        }
    }

    // Getting position in map
    //----------------------------------------------------------------------------------------------------

    const sandboxRef = useRef(null);
    const sandboxAnchorRef = useRef(null);
    const sandboxCenterRef = useRef(null);

    VisualEditorState.getMousePos = getMousePos;
    VisualEditorState.getMousePosInMap = getMousePosInMap;
    VisualEditorState.getPosInMap = getPosInMap;

    // Get mouse position relative to sandbox anchor, taking zoom into account
    function getMousePos(e) {

        const sandboxAnchorRect = sandboxAnchorRef.current.getBoundingClientRect();
        const x = (e.clientX - sandboxAnchorRect.left) / nodemap.settings.viewportZoom;
        const y = -1 * (e.clientY - sandboxAnchorRect.top) / nodemap.settings.viewportZoom;

        return { x, y };
    }

    // Get mouse position relative to sandbox CENTER, taking zoom into account
    function getMousePosInMap(e) {

        const sandboxCenterRefRect = sandboxCenterRef.current.getBoundingClientRect();
        const x = (e.clientX - sandboxCenterRefRect.left) / nodemap.settings.viewportZoom;
        const y = -1 * (e.clientY - sandboxCenterRefRect.top) / nodemap.settings.viewportZoom;

        return { x, y };
    }

    // Get absolute position in map
    function getPosInMap(pos) {

        const sandboxCenterRefRect = sandboxCenterRef.current.getBoundingClientRect();
        const x = (pos.x - sandboxCenterRefRect.left) / nodemap.settings.viewportZoom;
        const y = -1 * (pos.y - sandboxCenterRefRect.top) / nodemap.settings.viewportZoom;

        return { x, y };
    }

    // Viewport Position
    //----------------------------------------------------------------------------------------------------

    const dragging = useRef(false);
    const dragStartPos = useRef(null);
    const dragOffset = useRef(null);

    const mouseDownPos = useRef(null);
    const mouseDragPos = useRef(null);
    const mouseUpPos = useRef(null);

    // Begin dragging sandbox
    function beginDragging(e) {

        // Must be left/middle click with sandbox as target
        if (e.button !== 0 & e.button !== 1) { return; }
        if (e.target !== sandboxRef.current) { return; }

        // Set dragging variables
        dragging.current = true;
        dragStartPos.current = nodemap.settings.viewportPosition;
        dragOffset.current = { x: 0, y: 0 };
        mouseDownPos.current = getMousePos(e);

        // Set event listeners for dragging
        window.addEventListener('mousemove', continueDragging);
        window.addEventListener('mouseup', endDragging);

        // Deselect all nodes
        nodemap.unselectAll();
    }

    // Continue dragging sandbox
    function continueDragging(e) {

        // Get mouse position and calculate offset
        mouseDragPos.current = getMousePos(e);
        dragOffset.current = { x: mouseDragPos.current.x - mouseDownPos.current.x, y: mouseDragPos.current.y - mouseDownPos.current.y };

        // Update viewport position
        nodemap.updateViewportPosition({ x: dragStartPos.current.x - dragOffset.current.x, y: dragStartPos.current.y - dragOffset.current.y });
    }

    // End dragging sandbox
    function endDragging(e) {

        // Get mouse position and calculate offset
        mouseUpPos.current = getMousePos(e);
        dragOffset.current = { x: mouseUpPos.current.x - mouseDownPos.current.x, y: mouseUpPos.current.y - mouseDownPos.current.y };

        // Update viewport position
        nodemap.updateViewportPosition({ x: dragStartPos.current.x - dragOffset.current.x, y: dragStartPos.current.y - dragOffset.current.y });

        // Reset dragging variables
        dragging.current = false;
        dragOffset.current = { x: 0, y: 0 };

        // Remove event listeners for dragging
        window.removeEventListener('mousemove', continueDragging);
        window.removeEventListener('mouseup', endDragging);
    }

    // Set transform of sandbox without re-rendering component
    function setSandboxTransform() {
        sandboxCenterRef.current.style.transform = `translate(${-1 * nodemap.settings.viewportPosition.x}px, ${nodemap.settings.viewportPosition.y}px)`;
    }

    useEffect(() => { return nodemap.listen('updateViewportPosition', setSandboxTransform); }, []);

    useEffect(() => {
        setSandboxTransform();
    }, [sandboxCenterRef]);


    // Viewport Zoom
    //----------------------------------------------------------------------------------------------------

    // Set sandbox scale (and transform) when viewport position updates
    useEffect(() => {
        return nodemap.listen('updateViewportZoom', () => {
            setSandboxScale();
            setSandboxTransform();
        });
    }, []);

    const viewportZoom = useRef(nodemap?.settings?.viewportZoom || 1.00);
    const zoomSensitivity = 0.005;

    function onWheel(e) {

        e.preventDefault();

        if (e.ctrlKey) {

            // Get the current mouse position relative to the sandbox CENTER
            const mousePosBeforeZoom = getMousePos(e);

            // Scale viewport zoom
            const scaleZoomBy = (1 / (1 + Math.pow(2.71, (zoomSensitivity * e.deltaY)))) + 0.5;
            nodemap.settings.viewportZoom = nodemap.settings.viewportZoom * scaleZoomBy;

            // Limit viewport zoom
            if (nodemap.settings.viewportZoom > 1.85) { nodemap.settings.viewportZoom = 1.85; }
            if (nodemap.settings.viewportZoom < 0.10) { nodemap.settings.viewportZoom = 0.10; }

            // Get the new mouse position relative to the sandbox CENTER after zoom change
            const mousePosAfterZoom = getMousePos(e);

            // Calculate new viewportPos such that the zoom is centered on the mouse position
            nodemap.settings.viewportPosition.x += (mousePosBeforeZoom.x - mousePosAfterZoom.x);
            nodemap.settings.viewportPosition.y += (mousePosBeforeZoom.y - mousePosAfterZoom.y);

            nodemap.updateViewportZoom(nodemap.settings.viewportZoom);
            nodemap.updateViewportPosition(nodemap.settings.viewportPosition);
        }
    }

    // Set sandbox scale without re-rendering component
    function setSandboxScale() {
        sandboxAnchorRef.current.style.transform = `scale(${nodemap.settings.viewportZoom})`;
    }

    useEffect(() => {
        setSandboxScale();
    }, [sandboxCenterRef]);

    // Component
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={s['sandbox']} ref={sandboxRef} onMouseDown={beginDragging} onKeyDown={onKeyDown} onContextMenu={showContext} onDragEnter={onDragEnter} onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop} tabIndex="0">
            <div className={s['sandbox-anchor']} ref={sandboxAnchorRef} style={{ transform: `scale(${nodemap.settings.viewportZoom})` }}>
                <div className={s['sandbox-center']} ref={sandboxCenterRef} style={{ transform: `translate(${-1 * nodemap.settings.viewportPosition.x}px, ${nodemap.settings.viewportPosition.y}px)` }}>
                    <SandboxConnections nodemap={nodemap} />
                    <SandboxNodes nodemap={nodemap} />
                </div>
            </div>
        </div>
    );
}