// Modules
import { useEffect } from 'react';
import FrostedGlass from '@Utils/components/FrostedGlass/FrostedGlass.js';

// State
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { VisualEditorState } from '@State/VisualEditor/VisualEditorState.js';

// Resources
import DeleteIcon from './Icons/delete.svg';

// Styles
import s from './Notifications.module.css';
import fadeIn from '@Styles/Animations/FadeIn/FadeIn.module.css';

// Component function
export default function Notifications() {

    const refresh = useRefresh();
    useEffect(() => { return VisualEditorState.listen('updateNotifications', refresh); }, []);

    // Component HTML
    //--------------------------------------------------------------------------------

    const mapArray = Array.from(VisualEditorState.notifications || []).reverse();

    return (
        <div className={s['notifications-container']}>
            <div className={s['notifications']}>
                {mapArray.map((notification, i) => {

                    return (
                        <FrostedGlass className={s['notification'] + ' ' + fadeIn['p5s']} key={notification.timeStamp}>

                            {notification.color ? <div className={s['notification-color']} style={{ background: notification.color }}></div> : null}

                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '8px 4px 12px 10px' }}>
                                <span className={s['notification-title']}>{notification.title}</span>
                                <span className={s['notification-text']}>{notification.text}</span>
                            </div>

                            <img className={s['notification-resolve-button']} src={DeleteIcon} onClick={() => { console.log('test'); notification.resolve(); }} onDragStart={(e) => { e.preventDefault(); }}></img>
                        </FrostedGlass>
                    );
                })}
            </div>
        </div>
    );
}