// Modules
import modular from '@edisonai/modular';
import { useEffect, useState } from 'react';
import { useSmoothScroll } from '../../../Utils/hooks/useSmoothScroll';
import { useStatePersist } from '@Utils/hooks/useStatePersist.js';

// State
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { VisualEditorState } from '@State/VisualEditor/VisualEditorState.js';
import { CognitoSessionState } from '@State/CognitoSession/CognitoSessionState.js';

// Components
import Slider from '@Shared-Components/Slider/Slider.js';
import ExpandableV from '@Shared-Components/ExpandableV/ExpandableV.js';

// Resources
import ChevronIcon from './Icons/chevron.svg';

// Styles
import s from './Edit.module.css';

// Component function
export default function Edit() {

    const nodemap = VisualEditorState.nodemap.instance;

    const refresh = useRefresh();
    useEffect(() => { return VisualEditorState.listen('updateNodeModules', refresh) }, []);

    const editRef = useSmoothScroll(null);
    const categories = {};

    for (const key in modular.modules) {

        const module = modular.modules[key];

        if (!module.constructor || !module.template) {
            continue;
        }

        let { category } = module.template;
        if (!category) { category = 'misc'; }

        if (!categories[category]) { categories[category] = []; }
        categories[category].push(modular.modules[key]);
    }

    // Load node sources
    //--------------------------------------------------------------------------------

    // Load nodemap
    useEffect(() => {
        addNodeSources();
    }, []);

    async function addNodeSources() {

        const userSources = CognitoSessionState.userData.nodePacks || [];
        const nodemapSources = nodemap.settings.nodePacks || [];

        for (const source of [...userSources, ...nodemapSources]) {

            // Turn github url into jsdelivr url
            const url = ghToJsd(source);

            // Tell the visual editor to add the node modules from the url 
            await VisualEditorState.addNodeModules(url);
        }
    }

    // Convert a github url to a js-delivr url (authored by ChatGPT)
    function ghToJsd(url) {

        // Remove trailing slash if present
        url = url.replace(/\/$/, "");

        // Extract necessary parts from the GitHub URL
        const regex = /https:\/\/github\.com\/([^/]+)\/([^/]+)(\/tree\/([^/]+))?/;
        const match = url.match(regex);

        if (match) {
            const user = match[1];
            const repo = match[2];
            let version = match[4] || "main"; // Fallback to main if no branch is specified

            // Construct the jsDelivr URL
            const jsdelivrUrl = `https://cdn.jsdelivr.net/gh/${user}/${repo}@${version}/`;
            return jsdelivrUrl;
        } else {
            return "Invalid GitHub URL";
        }
    }

    // Drag / drop new nodes
    //--------------------------------------------------------------------------------

    function onDragEnter(e) {
        e.preventDefault();
        editRef.current.classList.add(s['dragging']);
    }

    function onDragOver(e) {
        e.preventDefault();
    }

    function onDragLeave(e) {
        e.preventDefault();
        editRef.current.classList.remove(s['dragging']);
    }

    function onDrop(e) {

        // Prevent default and remove dragging highlight
        e.preventDefault();
        editRef.current.classList.remove(s['dragging']);

        // Get files
        const { files } = e.dataTransfer;
        if (!files || files.length < 1) { return; }

        [...files].forEach(async (file) => {
            if (!(file?.type?.includes('javascript') || file?.name?.endsWith('.node'))) { return; }
            await VisualEditorState.addNodeModule(file);
        });
    }

    // Component HTML
    //--------------------------------------------------------------------------------

    return (
        <div className={s['edit']} ref={editRef} {...{ onDragEnter, onDragOver, onDragLeave, onDrop }}>
            <NodemapDetails />

            {Object.keys(categories).map((key) => {
                return (<NodeCategoryDropdown category={key} nodes={categories[key]} key={key} />);
            })}
        </div>
    );
}

// Nodemap Details Menu
//--------------------------------------------------------------------------------

// Component for nodemap details
function NodemapDetails() {

    // Establish refresh conditions
    const refresh = useRefresh();
    useEffect(() => { return VisualEditorState.nodemap?.instance?.listen('updateSetting', refresh); }, []);
    useEffect(() => { return VisualEditorState.nodemap?.instance?.listen('updateName', refresh); }, []);

    // When name changes
    function nameChange(e) {
        VisualEditorState.nodemap?.instance?.updateName(e.target.value);
    }

    // When flow speed changes
    function flowSpeedChange(flowSpeed) {
        VisualEditorState.nodemap?.instance?.updateSetting({ setting: 'flowSpeed', value: flowSpeed });
    }

    // When max recursion changes
    function maxRecursionChange(maxRecursion) {
        VisualEditorState.nodemap?.instance?.updateSetting({ setting: 'maxRecursion', value: maxRecursion });
    }

    const nodemap = VisualEditorState.nodemap.instance || {};
    const { name } = nodemap.info || {};
    const { flowSpeed, maxRecursion } = nodemap.settings || {};

    // Component HTML
    //--------------------------------------------------------------------------------

    return (
        <div className={s['nodemap-details']}>

            <div className={s['nodemap-details-field']}>
                <label className={s['nodemap-details-field-label']}>Nodemap Name</label>
                <input className={s['nodemap-details-name-input']} value={name} onChange={nameChange}></input>
            </div>

            <div className={s['nodemap-details-field']}>
                <label className={s['nodemap-details-field-label']}>{`Flow Speed: ${(flowSpeed || 0.5).toFixed(2)}`}</label>
                <Slider className={s['nodemap-details-range-slider']} value={(flowSpeed || 0.5)} min={0} max={1} step={0.01} onChange={flowSpeedChange}></Slider>
            </div>

            <div className={s['nodemap-details-field']}>
                <label className={s['nodemap-details-field-label']}>{`Max Recursion: ${(maxRecursion || 25).toFixed(0)}`}</label>
                <Slider className={s['nodemap-details-range-slider']} value={(maxRecursion || 25)} min={0} max={100} step={1} onChange={maxRecursionChange}></Slider>
            </div>
        </div>
    );
}

// Node Category Dropdown
//--------------------------------------------------------------------------------

// Component function
function NodeCategoryDropdown({ category, nodes }) {

    const [expanded, setExpanded] = useStatePersist(true, category);

    async function addNode(node) {

        try {

            //console.log('Editor | Adding node...', node);

            if (!node.constructor) { throw new Error('Missing node constructor'); }
            if (!node.template) { throw new Error('Missing node template'); }

            await VisualEditorState.nodemap.instance.addNode(structuredClone(node.template));
        }

        catch (e) {
            console.error(e);
            VisualEditorState.error({ title: 'Could Not Add Node', text: e.message });
        }
    }

    // Component HTML
    //--------------------------------------------------------------------------------

    return (

        <div className={s['node-category']}>

            <div className={s['node-category-label']} onClick={() => setExpanded(!expanded)}>

                <img className={s['node-category-label-icon']} src={ChevronIcon} alt={''} style={{ transform: expanded ? undefined : 'rotate(-90deg)' }} onDragStart={(e) => { e.preventDefault(); }}></img>
                <div className={s['node-category-label-text']}>{category}</div>
                <div className={s['node-category-label-line']}></div>
            </div>

            <ExpandableV expanded={expanded} id={category}>

                <div className={s[`node-category-buttons`]}>

                    {Object.keys(nodes).map((key) => {

                        const { template, buttonStyles } = nodes[key];

                        return (
                            <button className={`${s['add-node-button']} ${s[`add-${category}-node-button`]}`} title={`Add ${template.title || template.name} node`} onMouseDown={() => { addNode(nodes[key]); }} style={{ ...(buttonStyles || {}) }} key={key}>
                                <img className={s['add-node-button-icon']} src={template.icon} alt={''} onDragStart={(e) => { e.preventDefault(); }}></img>
                                <div className={s['add-node-button-text']}>{template.title || template.name}</div>
                            </button>
                        )
                    })}

                </div>

            </ExpandableV>
        </div>
    );
}