// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.range-input-custom {

    position: relative;

    /* dimentions */
    width: 100%;
    height: 15px;

    /* flex options */
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

    .range-input-custom-bar {

        /* position */
        position: absolute;

        /* dimentions */
        width: 100%;
        height: 1px;

        /* background */
        background: var(--line-color);
    }

    .range-input-custom-thumb-container {

        /* position */
        position: absolute;

        /* dimentions */
        width: 0px;
        height: 100%;
        overflow: visible;

        /* flex options */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .range-input-custom-thumb {

        /* position */
        position: relative;

        /* dimentions */
        width: 3px;
        height: 100%;

        /* background */
        background: var(--strong-text);
    }`, "",{"version":3,"sources":["webpack://./src/Shared-Components/Gui/Elements/NumberSlider/NumberSlider.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;;IAElB,eAAe;IACf,WAAW;IACX,YAAY;;IAEZ,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,eAAe;AACnB;;IAEI;;QAEI,aAAa;QACb,kBAAkB;;QAElB,eAAe;QACf,WAAW;QACX,WAAW;;QAEX,eAAe;QACf,6BAA6B;IACjC;;IAEA;;QAEI,aAAa;QACb,kBAAkB;;QAElB,eAAe;QACf,UAAU;QACV,YAAY;QACZ,iBAAiB;;QAEjB,iBAAiB;QACjB,aAAa;QACb,uBAAuB;QACvB,mBAAmB;IACvB;;IAEA;;QAEI,aAAa;QACb,kBAAkB;;QAElB,eAAe;QACf,UAAU;QACV,YAAY;;QAEZ,eAAe;QACf,8BAA8B;IAClC","sourcesContent":[".range-input-custom {\n\n    position: relative;\n\n    /* dimentions */\n    width: 100%;\n    height: 15px;\n\n    /* flex options */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    cursor: pointer;\n}\n\n    .range-input-custom-bar {\n\n        /* position */\n        position: absolute;\n\n        /* dimentions */\n        width: 100%;\n        height: 1px;\n\n        /* background */\n        background: var(--line-color);\n    }\n\n    .range-input-custom-thumb-container {\n\n        /* position */\n        position: absolute;\n\n        /* dimentions */\n        width: 0px;\n        height: 100%;\n        overflow: visible;\n\n        /* flex options */\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n\n    .range-input-custom-thumb {\n\n        /* position */\n        position: relative;\n\n        /* dimentions */\n        width: 3px;\n        height: 100%;\n\n        /* background */\n        background: var(--strong-text);\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
