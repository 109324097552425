// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PositionIndicator_position-indicator__7Pclo {

    /* position */
    position: absolute;
    left: 0px;
    bottom: 0px;

    /* flex options */
    display: flex;
    justify-content: center;
    align-items: center;

    /* dimentions */
    min-width: 14ch;

    /* margins & padding */
    margin-left: 6px;
    padding: 12px;

    /* border */
    border: solid 1px var(--line-color);
    border-radius: 4px;

    /* background */
    background: rgba(0, 0, 0, 0.66);
    -webkit-backdrop-filter: brightness(333%) blur(10px);
            backdrop-filter: brightness(333%) blur(10px);

    /* text */
    color: var(--medium-text);
    font-family: 'Ubuntu Mono', 'monospace';
    font-size: 16px;
    white-space: nowrap;

    /* pre-hover */
    transition: background 0.25s;

}   .PositionIndicator_position-indicator__7Pclo:hover {

    /* post-hover */
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/VisualEditor/LeftMenu/PositionIndicator/PositionIndicator.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,WAAW;;IAEX,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,eAAe;IACf,eAAe;;IAEf,sBAAsB;IACtB,gBAAgB;IAChB,aAAa;;IAEb,WAAW;IACX,mCAAmC;IACnC,kBAAkB;;IAElB,eAAe;IACf,+BAA+B;IAC/B,oDAA4C;YAA5C,4CAA4C;;IAE5C,SAAS;IACT,yBAAyB;IACzB,uCAAuC;IACvC,eAAe;IACf,mBAAmB;;IAEnB,cAAc;IACd,4BAA4B;;AAEhC,IAAI;;IAEA,eAAe;IACf,8BAA8B;IAC9B,eAAe;AACnB","sourcesContent":[".position-indicator {\n\n    /* position */\n    position: absolute;\n    left: 0px;\n    bottom: 0px;\n\n    /* flex options */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    /* dimentions */\n    min-width: 14ch;\n\n    /* margins & padding */\n    margin-left: 6px;\n    padding: 12px;\n\n    /* border */\n    border: solid 1px var(--line-color);\n    border-radius: 4px;\n\n    /* background */\n    background: rgba(0, 0, 0, 0.66);\n    backdrop-filter: brightness(333%) blur(10px);\n\n    /* text */\n    color: var(--medium-text);\n    font-family: 'Ubuntu Mono', 'monospace';\n    font-size: 16px;\n    white-space: nowrap;\n\n    /* pre-hover */\n    transition: background 0.25s;\n\n}   .position-indicator:hover {\n\n    /* post-hover */\n    background: rgba(0, 0, 0, 0.5);\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"position-indicator": `PositionIndicator_position-indicator__7Pclo`
};
export default ___CSS_LOADER_EXPORT___;
