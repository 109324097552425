// Modules
import { useEffect, useRef, useState } from 'react';

// Styles
import s from './ExpandableH.module.css';

// Component function
export default function ExpandableH({ className, expanded = true, time = 350, children, id, style }) {

    const wrapperRef = useRef(null);
    const childrenRef = useRef(null);

    // Render children?
    //--------------------------------------------------------------------------------

    const [counter, setCounter] = useState(0);
    const [renderChildren, setRenderChildren] = useState(expanded);
    const [fullyExpanded, setFullyExpanded] = useState(expanded);

    const firstRender = useRef(true);

    useEffect(() => {

        if (firstRender.current) { firstRender.current = false; return; }

        if (expanded) {
            setRenderChildren(true);
            setCounter(counter + 1);
            setTimeout(() => { setFullyExpanded(true); }, time);
        }

        else {
            setFullyExpanded(false);
            setCounter(counter + 1);
            setTimeout(() => { setRenderChildren(false); }, time);
        }

    }, [expanded]);

    useEffect(() => {
        try {

            if (firstRender.current) { firstRender.current = false; return; }

            // When we're expanding
            if (expanded) {

                // Set min/max widths to 0px
                wrapperRef.current.style.minWidth = '0px';
                wrapperRef.current.style.maxWidth = '0px';
                wrapperRef.current.style.minWidth = childrenRef.current.clientWidth + 'px';
                wrapperRef.current.style.maxWidth = childrenRef.current.clientWidth + 'px';


                // Remove and set width to auto (after expected completion time)
                setTimeout(() => {
                    try {
                        wrapperRef.current.style.minWidth = '';
                        wrapperRef.current.style.maxWidth = '';
                        wrapperRef.current.style.width = 'auto';
                    } catch { }
                }, time);
            }

            // When we're collapsing
            else {

                // Set min/max widths to current width
                wrapperRef.current.style.minWidth = childrenRef.current.clientWidth + 'px';
                wrapperRef.current.style.maxWidth = childrenRef.current.clientWidth + 'px';

                // Animate to 0px for each
                wrapperRef.current.style.minWidth = '0px';
                wrapperRef.current.style.maxWidth = '0px';
            }
        }

        catch (e) {

        }
    }, [counter]);


    // Component HTML
    //--------------------------------------------------------------------------------

    return (
        <div className={`${renderChildren ? className : null}` + ' ' + s['expandable']} ref={wrapperRef} first={String(firstRender.current)}
            style={{
                margin: expanded ? undefined : '0px',
                overflow: fullyExpanded ? undefined : 'hidden',
                transition: !firstRender.current ? `all ${time}ms ease, outline ${1.5*time}ms linear` : undefined
            }}>
            <div className={s['children']} ref={childrenRef} style={style}>
                {renderChildren ? children : null}
            </div>
        </div>
    );
}