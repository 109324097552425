// Modules
import { superFetch } from '@edisonai/superfetch';
import { attachEvents } from '@Utils/functions/events.js';
import { Nodemap, util } from '@edisonai/nodemap';
import { defaultNodemap } from './Resources/defaultNodemap.js';
import { Notification } from './Notification/Notification.js';
import { hibernate, revive } from '@Utils/functions/hibernate.js';
import CryptoJS from 'crypto-js';
import modular from '@edisonai/modular';
import * as DataTypes from '@edisonai/datatypes';

// Visual Editor Class
export class VisualEditor {

    constructor(params = {}) {

        attachEvents(this);

        this.nodemapsEndpoint = params.nodemapsEndpoint;

        this.loadingProgress = { progress: 0.001 };

        this.options = {
            nodes: {}
        };

        this.nodemaps = {}

        // Nodemap
        this.nodemap = {
            url: undefined,
            file: undefined,
            dbObject: undefined,
            object: undefined,
            instance: undefined,
        }

        // Init callback functions
        this.notifyComponent = () => { };

        console.log(this);
    }

    // Event management
    //--------------------------------------------------

    setRefresh(callback) {
        this.notifyComponent = callback;
    }

    // Notifications
    //--------------------------------------------------

    notification({ color, title, text, time } = {}) {

        // Make path
        if (!this.notifications) { this.notifications = new Set(); }

        // Add notification and emit
        this.notifications.add(new Notification({ color, title, text, time, resolve: this.resolveNotification.bind(this) }));
        this.emit('updateNotifications');
    }

    error({ title, text, time } = {}) {
        this.notification({ color: 'error', title, text, time });
    }

    // Resolve (delete) a specific notification
    resolveNotification(notification) {
        this.notifications?.delete(notification);
        this.emit('updateNotifications');
    }

    // Fetching
    //--------------------------------------------------

    async getNodemapsByAuthor(email) {

        console.log('Visual Editor | Getting nodemaps by author...', email);

        const response = await fetch(`${this.nodemapsEndpoint}/author/${email}`, { credentials: 'include' });
        if (!response.ok) { throw new Error((await response.json()).message); }

        const result = await response.json();

        return result;
    }

    setUserMaps(userMaps) {

        // Make path
        if (!this.nodemaps) { this.nodemaps = {}; }
        if (!this.nodemaps.user) { this.nodemaps.user = {}; }

        // Set user maps and emit
        this.nodemaps.user = userMaps;
        this.emitMulti(['updateUserMaps', 'updateMaps']);
    }

    // Loading
    //--------------------------------------------------

    async setLoadingProgress(newProgress) {
        this.loadingProgress = newProgress;
        this.emit('update');
    }

    async setNodemap(nodemapObj, options) {

        if (!nodemapObj) { nodemapObj = defaultNodemap; }

        console.log('Visual Editor | Setting new nodemap: ', nodemapObj, options);

        this.nodemap.object = structuredClone(nodemapObj);
        this.nodemap.instance = await Nodemap(nodemapObj, { ...options, nodes: this.options.nodes });

        this.loadingProgress = {};

        this.emit('update');
    }

    async loadNodemap(id, options, onProgress) {

        // Check to make sure errors don't work
        if (!this.nodemapsEndpoint) { throw new Error('Cannot load nodemap (this.nodemapsEndpoint is missing)') }

        console.log(`Visual Editor | Loading nodemap (${id || '{}'})`);

        let nodemapObj = defaultNodemap;

        if (id) {

            // Get basic nodemap info
            const nodemapResponse = await superFetch(`${this.nodemapsEndpoint}/id/${id}`, { credentials: 'include' });
            const { url } = await nodemapResponse.json();

            // Fetch file and track progress
            const nodemapObjResponse = await superFetch(url, { track: true });
            nodemapObjResponse.onProgress(onProgress || (() => { }));

            // Get object and revive
            nodemapObj = await nodemapObjResponse.json();
        }

        const revived = await revive(nodemapObj, DataTypes);

        // Instantiate nodemap
        this.nodemap.object = nodemapObj;
        this.nodemap.instance = await Nodemap(revived, { ...options, nodes: this.options.nodes });
        this.nodemap.instance.listen('update', () => { this.nodemap.needsSaving = true; });

        this.emit('update');
    }

    // Nodemap CRUD
    //--------------------------------------------------

    async saveNodemap(userData) {
        return new Promise(async (resolve, reject) => {

            try {

                // Get nodemap object, json, blob, and file
                const nodemapObj = await hibernate(this.nodemap.instance);

                console.log(this.nodemap.instance)
                console.log(nodemapObj);

                // Check if nodemap exists
                //--------------------------------------------------

                const { ok: exists } = await fetch(`${this.nodemapsEndpoint}/id/${nodemapObj.id}`, { credentials: 'include' });

                // If no existing nodemap or we are not the author, we must create a new one
                const createNew = !exists || nodemapObj.info.author !== userData.email;

                // If nodemap does not exist yet
                if (createNew) {

                    const { id, info } = await this.createNodemap();

                    // Set new id and overwrite info
                    nodemapObj.id = id;
                    nodemapObj.info = {
                        ...nodemapObj.info,
                        name: `Copy of ${nodemapObj.info.name}`,
                        author: userData.email,
                        created_on: info.created_on,
                        last_modified: info.last_modified
                    };
                }

                // Create file and save
                //--------------------------------------------------

                const nodemapInfo = JSON.stringify(nodemapObj.info || {});
                const nodemapJson = JSON.stringify(nodemapObj);
                const nodemapBlob = new Blob([nodemapJson], { type: 'application/json' });
                const nodemapFile = new File([nodemapBlob], 'nodemap.nm', { type: 'application/json' });

                // Prepare FormData
                const formData = new FormData();
                formData.append('info', nodemapInfo);
                formData.append('file', nodemapFile);

                const res = await superFetch(`${this.nodemapsEndpoint}/id/${nodemapObj.id}/update`, {
                    method: 'POST',
                    body: formData,
                    credentials: 'include',
                    stream: { decode: 'event-stream' }
                });

                if (res.status === 404) {
                    throw new Error('Could not save nodemap: nodemap with ID not found');
                }

                if (!res.ok) {
                    throw new Error(res.statusText);
                }

                res.stream.onChunk((chunk) => { });
                res.stream.onError((e) => { throw new Error(e) });

                res.stream.onFinish(() => {
                    this.emit('savedNodemap');
                    resolve({ newId: createNew ? nodemapObj.id : undefined });
                });

                this.nodemap.instance.info = nodemapObj.info;
                this.nodemap.instance.emit('update');
            }

            catch (e) {
                reject(e);
            }
        });
    }

    async copyNodemap() {
        return new Promise(async (resolve, reject) => {

            try {

                const { id } = this.nodemap.instance;

                const res = await fetch(`${this.nodemapsEndpoint}/id/${id}/copy`, { method: 'POST', credentials: 'include' });
                if (!res.ok) { throw new Error(res.statusText); }

                resolve(await res.json());
            }

            catch (e) {
                console.error(e);
                reject(e);
            }
        });
    }

    async deleteNodemap(id) {
        return new Promise(async (resolve, reject) => {

            try {

                const res = await fetch(`${this.nodemapsEndpoint}/id/${id}/delete`, { method: 'POST', credentials: 'include' });
                if (!res.ok) { throw new Error(res.statusText); }

                resolve(await res.json());
            }

            catch (e) {
                console.error(e);
                reject(e);
            }
        });
    }

    async createNodemap() {
        return new Promise(async (resolve, reject) => {

            try {

                const res = await fetch(`${this.nodemapsEndpoint}/create`, { method: 'POST', credentials: 'include' });
                if (!res.ok) { const e = new Error((await res.json())?.message); e.name = e.statusText; throw e; }

                resolve(await res.json());
            }

            catch (e) {
                console.error(e);
                reject(e);
            }
        });
    }

    // Source management
    //--------------------------------------------------

    // Expect a link to a json file, look for other sources
    async addNodeModules(url) {

        try {

            if (this.loadedSources?.[url]) { return; }

            console.log('Editor | Loading nodes from url: ', url + 'nodes.json');

            const request = await fetch(url + 'nodes.json');
            const result = await request.text();
            const next = await JSON.parse(result);

            if (!this.loadedSources) { this.loadedSources = {}; }
            this.loadedSources[url] = true;

            for (const source of next) {

                const nextUrl = (new URL(url + source)).href;

                if (nextUrl.endsWith('.json')) { await this.addNodeModules(nextUrl); }
                if (nextUrl.endsWith('.js')) { await this.addNodeModule(nextUrl); }
            }
        }

        catch (e) {
            console.warn(e);
        }
    }

    async addNodeModule(source) {

        try {

            //console.log('Visual Editor | Adding node module...', source);

            // Import module and set hash of template
            const { module, old } = await modular.require(source, { inspectOld: true, requireSafe: true });
            module.template.source = module._version || source;

            // Apply 
            if (old?._hotupdate) {

                console.log({ old, module });

                // Init hotUpdateMap
                if (!this.hotUpdateMap) { this.hotUpdateMap = {}; }
                if (!this.hotUpdateMap[module._version]) { this.hotUpdateMap[module._version] = new Set(); }

                // Add to set and run hotUpdate on all of them
                this.hotUpdateMap[module._version].add(old);
                this.hotUpdateMap[module._version].forEach((oldModule) => {

                    // Provide old module with the methods of the new one
                    Object.getOwnPropertyNames(module.prototype).forEach((prop) => {
                        oldModule.prototype[prop] = module.prototype[prop];
                    });

                    // Delete methods if they can't be found in the new list
                    Object.getOwnPropertyNames(oldModule.prototype).forEach((prop) => {
                        if (prop in oldModule.prototype) { return; }
                        delete oldModule.prototype[prop];
                    });
                });
            }

            // Add node to options
            if (!this.options) { this.options = {}; }
            if (!this.options.nodes) { this.options.nodes = {}; }
            this.options.nodes[module._version] = module;

            // Emit and return node module
            this.emit('updateNodeModules');
            return module;
        }

        catch (e) {
            console.warn(e);
        }
    }
}