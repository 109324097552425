// State
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { CognitoSessionState } from '@State/CognitoSession/CognitoSessionState.js';
import { VisualEditorState } from '@State/VisualEditor/VisualEditorState.js';

// Resources
import HaltIcon from './Halt.svg';
import PlayIcon from './Play.svg';
import ResetIcon from './Reset.svg';

// Styles
import './NodeMapControls.css';

// Component function
export default function NodeMapControls() {

    const nodemap = VisualEditorState.nodemap.instance;

    // Component HTML
    //--------------------------------------------------

    return (
        <div className={'nodemap-controls'}>

            {/* play button */}
            <div className={'nodemap-control-button nodemap-play-button'} title={'Start'} onClick={() => { nodemap.start(); }}>
                <img className={'nodemap-control-button-icon'} src={PlayIcon} alt={''} onDragStart={(e) => { e.preventDefault(); }}></img>
            </div>

            <div style={{ marginLeft: '4px', marginRight: '4px' }}></div>

            {/* halt button */}
            <div className={'nodemap-control-button nodemap-halt-button'} title={'Halt'} onClick={() => { nodemap.halt(); }}>
                <img className={'nodemap-control-button-icon'} src={HaltIcon} alt={''} onDragStart={(e) => { e.preventDefault(); }}></img>
            </div>

            <div style={{ marginLeft: '4px', marginRight: '4px' }}></div>

            {/* reset button */}
            <div className={'nodemap-control-button nodemap-reset-button'} title={'Reset'} onClick={() => { nodemap.reset(); }}>
                <img className={'nodemap-control-button-icon'} src={ResetIcon} alt={''} onDragStart={(e) => { e.preventDefault(); }}></img>
            </div>

        </div>
    );
};