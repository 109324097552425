// Modules
import { useRef, useState, useEffect, useContext } from 'react';

// State
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { VisualEditorState } from '@State/VisualEditor/VisualEditorState.js';

// Components
import DivInput from '@Shared-Components/DivInput/DivInput.js';

// Styles
import s from './NodeInput.module.css';

// Component function
export default function NodeInput({ input }) {

    const editor = VisualEditorState;

    const refresh = useRefresh();

    const [showDeleteButton, setShowDeleteButton] = useState(false);
    const [strikeThroughName, setStrikeThroughName] = useState(false);

    // Get own position
    //----------------------------------------------------------------------------------------------------

    const inputMarkerRef = useRef(null);
    const getPosInMap = useRef(VisualEditorState.getPosInMap);
    const getMousePosInMap = useRef(VisualEditorState.getMousePosInMap);

    // Get own position in nodemap
    function getOwnCenter() {

        if (!inputMarkerRef.current) { return; }

        const rect = inputMarkerRef.current.getBoundingClientRect();

        input.hidden.markerPos = getPosInMap.current({ x: (rect.left + rect.width / 2), y: (rect.top + 0.5 * rect.height), });

        return input.hidden.markerPos;
    }

    input.hidden.getOwnCenter = getOwnCenter;

    useEffect(() => { return input.options.node.listen('updatePosition', getOwnCenter); }, []);
    useEffect(() => { getOwnCenter(); input.redrawConnections(); }, [inputMarkerRef]);

    // Dragging connection(s)
    //----------------------------------------------------------------------------------------------------

    function beginDraggingConnection(e) {

        if (e.button !== 0) { return; }

        // Connect output to dummy input with just a position
        if (!editor.newConnections) { editor.newConnections = {}; }

        // Create new connections connected to dummy output
        [...input.connections].forEach((connection) => {
            input.options.nodemap.connections.add(connection.output.connect({ dragging: true, hidden: { markerPos: getMousePosInMap.current(e) } }));
            connection.delete();
        });

        // Set dragging event listeners
        window.addEventListener('mousemove', continueDraggingConnection);
        window.addEventListener('mouseup', endDraggingConnection);
    }

    function continueDraggingConnection(e) {

        [...input.options.nodemap.connections].forEach((connection) => {
            connection.input.hidden.markerPos = getMousePosInMap.current(e);
            connection.redraw();
        });
    }

    function endDraggingConnection(e) {

        [...input.options.nodemap.connections].forEach((connection) => {
            if (!connection.input.dragging) { return; }
            input.options.nodemap.connections.delete(connection);
            connection.delete();
        });

        refresh();

        // Remove dragging event listeners
        window.removeEventListener('mousemove', continueDraggingConnection);
        window.removeEventListener('mouseup', endDraggingConnection);
    }

    // Connect dragging connection
    function markerMouseUp() {

        [...input.options.nodemap.connections].forEach((connection) => {
            if (!connection.input.dragging) { return; }
            connection.output.connect(input);
            connection.delete();
        });
    }

    // Changing input accept
    //----------------------------------------------------------------------------------------------------

    function handleUpdateRequired(e) {

        if (!(input.canEditAccept || input.options.node.settings?.inputs?.canEditAccept)) { return; }

        if (input.accept === 'complete') {
            if (input.canAcceptStream || input.options.node.settings?.inputs?.canAcceptStream) { input.updateAccept('stream'); refresh(); return; }
            input.updateAccept('empty');
            refresh();
            return;
        }

        if (input.accept === 'stream') {
            input.updateAccept('empty');
            refresh();
            return;
        }

        if (input.accept === 'empty') {
            input.updateAccept('complete');
            refresh();
            return;
        }

        input.updateAccept('complete');
        refresh();
    }

    // Component function
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={s['node-input']} onMouseEnter={() => { setShowDeleteButton(true); }} onMouseLeave={() => { setShowDeleteButton(false); }}>

            <div className={s['node-input-marker']} ref={inputMarkerRef} title={`Input ${input.id}`} onMouseDown={beginDraggingConnection} onMouseUp={markerMouseUp}>
                <svg width="100%" height="100%">
                    <circle cx="50%" cy="50%" r="50%" fill={input.options.node.color || 'white'} />
                </svg>
            </div>

            {/* Accept complete, stream, or empty */}
            {input.accept || input.canEditAccept || input.options.node.settings?.inputs?.canEditAccept ? <div className={s['node-input-requirecontent-container']} onClick={handleUpdateRequired} style={{ cursor: input.canEditAccept || input.options.node.settings?.inputs?.canEditAccept ? 'pointer' : 'no-drop' }}>
                {input.accept === 'complete' ? <div className={s['require-content']} title={'complete input required'} style={{ color: input.options?.node?.color || 'white' }}>✓</div>
                :
                    input.accept === 'stream' ? <div className={s['allow-stream']} title={'partial / streamed data allowed'}>S</div>
                :
                <div className={s['not-required']} title={'no data required'}>O</div>}
            </div> : null}

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>

                {/* Input name field */}
                {typeof input.canEditName === 'string' || input.canEditName || input.options.node.settings?.inputs?.canEditName ? <DivInput value={input.name} options={input.nameOptions || input.options?.node?.settings?.inputs?.nameOptions} placeholder={input.placeholder || input.options?.node?.settings?.inputs?.placeholder || 'name'} className={s['node-input-name']} style={{ textDecoration: strikeThroughName ? 'line-through' : undefined, fontStyle: input.options.node.hasFunction(input.name) ? 'italic' : undefined, color: input.options.node.hasFunction(input.name) ? input.options.node.color || 'white' : undefined }} editable={input.canEditName} onChange={(newName) => { input.updateName(newName); refresh(); }} /> : null}

                {(input.options?.node?.settings?.inputs?.canAdd && input.options.node.settings?.inputs?.canRemove !== false && input.canRemove !== false) ?
                    <div className={s['node-input-delete-button']} style={{ width: showDeleteButton ? undefined : '0px', margin: showDeleteButton ? undefined : '0px', transitionDelay: showDeleteButton ? '0s' : undefined }} title={'Delete'} onMouseEnter={() => { setStrikeThroughName(true); }} onMouseLeave={() => { setStrikeThroughName(false); }} onClick={input.delete.bind(input)}>
                        <svg fill="white" height="10px" width="10px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 460 460" ><path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55 c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55 c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505 c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55 l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719 c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"></path></svg>
                    </div>
                    : null}
            </div>
        </div>
    );
}