// Modules
import { useState, useCallback } from 'react';

// useRefresh hook
export function useRefresh() {

    // Init state
    const [, setState] = useState({});

    // Define refresh function
    const refreshCallback = useCallback(refresh, []);

    function refresh() {
        setState({});
    }

    // Return refresh function
    return refreshCallback;
};
