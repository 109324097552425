// Modules
import { useEffect, useRef } from 'react';
import { useSmoothScroll } from '@Utils/hooks/useSmoothScroll.js';
import {format as prettyFormat} from 'pretty-format'
import Prism from "prismjs";
import './okaidia-theme.css';

// Components
import ElementHeader from '../../SubElements/Header/ElementHeader.js';
import ElementErrorMessage from '../../SubElements/ErrorMessage/ElementErrorMessage.js';

// Styles
import './CodeEditor.css';

// Component
export default function CodeEditor({ element, editing }) {

    useEffect(() => {
        Prism.highlightAll();
    }, []);

    // Events
    //----------------------------------------------------------------------------------------------------

    function keyDown(e) {

        if (e.key === 'Tab') {

            e.preventDefault();
            document.execCommand('insertHTML', false, '\u00A0\u00A0\u00A0\u00A0')
            return;
        }

        if (e.key === 'Enter') {

            e.preventDefault();
            document.execCommand('insertHTML', false, '\n')
            return;
        }

        if (e.ctrlKey && e.key === 's') {
            e.stopPropagation();
            e.preventDefault();
            saveCode();
        }

        if (e.ctrlKey && e.key === 'a') {
            e.stopPropagation();
        }

        if (e.ctrlKey && e.shiftKey && e.key === 'I') {
            e.preventDefault();
            e.stopPropagation();
            formatCode();
        }
    }

    // "Save" the code
    function saveCode() {

        // Fire 'change' event
        textareaRef.current.dispatchEvent(new Event('save'));
    }

    // Display value
    //----------------------------------------------------------------------------------------------------

    const displayValue = getDisplayValue();

    // Get display value as string
    function getDisplayValue() {

        try {
            return String(element.getValue() || '');
        }

        catch (e) {
            console.error(e);
            element.error(new Error(`Could not convert value to String`));
        }
    }

    // Updating value
    //----------------------------------------------------------------------------------------------------

    const textareaRef = useSmoothScroll(null);

    // Update value of element
    function updateValue(e) {

        //savePosition();

        const newString = codeEditorTextRef.current.value;
        element.updateValue(newString);
        window.requestAnimationFrame(Prism.highlightAll);
        textareaRef.current.dispatchEvent(new Event('change'));
    }

    function formatCode() {

        savePosition();

        const formattedCode = prettyFormat(codeEditorTextRef.current.value, {
            escapeString: false,
            indent: true,
        }).replace(/^"|"$/g, '');

        element.updateValue(formattedCode);
        window.requestAnimationFrame(Prism.highlightAll);
        textareaRef.current.dispatchEvent(new Event('change'));
    }

    // Saving position on input
    //--------------------------------------------------

    const codeEditorTextRef = useRef(null);
    const selection = useRef(null);
    const position = useRef(null);

    // Save karet (cursor) position
    function savePosition() {

        selection.current = window.getSelection();
        const range = selection.current.getRangeAt(0);
        const start = range.startOffset;
        position.current = start;

        //window.requestAnimationFrame(setPosition);
    }

    function setPosition() {

        // Error occurs when no value
        if (!codeEditorTextRef.current?.firstChild) { return; }
        if (!selection.current) { return; }
        if (!position.current) { return; }

        const newRange = document.createRange();
        newRange.setStart(codeEditorTextRef.current.firstChild, position.current);
        newRange.collapse(true);
        selection.current.removeAllRanges();
        selection.current.addRange(newRange);
    }

    // Resize
    //----------------------------------------------------------------------------------------------------

    function resizeTextArea(e) {

        if (!element.style) { element.style = {}; }

        const verticalScrollbar = textareaRef.current.scrollHeight > textareaRef.current.clientHeight;
        const horizontalScrollbar = textareaRef.current.scrollWidth > textareaRef.current.clientWidth;

        element.style.height = textareaRef.current.clientHeight + 2 + (horizontalScrollbar ? 15 : 0) + 'px';
        element.style.width = textareaRef.current.clientWidth + 2 + (verticalScrollbar ? 15 : 0) + 'px';

        element.emit('update');
    }

    // Auto-resize
    //----------------------------------------------------------------------------------------------------

    useEffect(() => { if (element.autoResize) { resize(); } }, [displayValue]);

    function getHeight() { return textareaRef.current.clientHeight; }
    function setHeight(newHeight) { textareaRef.current.style.height = newHeight + 2 + 'px'; }

    // We must always set the height to zero before getting the scrollheight
    function getScrollHeight() {

        const originalHeight = getHeight();
        setHeight(0);
        const scrollHeight = textareaRef.current.scrollHeight;
        setHeight(originalHeight);

        return scrollHeight;
    }

    // Automatically resize text area
    function resize() {

        const height = getHeight();
        const scrollHeight = getScrollHeight();

        // If scroll content is greater than height
        if (scrollHeight - height > 0) { setHeight(scrollHeight); }

        // If scroll content is less than height with a slight margin
        if (scrollHeight - height < 0 && scrollHeight - height > - 25) { setHeight(scrollHeight); }
    }

    // Element specific events
    //----------------------------------------------------------------------------------------------------

    // Attach listed events to element
    useEffect(() => {

        // Requires valid inputRef
        if (!textareaRef.current) { return; }

        const functions = {};

        // Define and attach functions, add event listener
        for (const eventName in element.events) { functions[eventName] = (e) => { element.onEvent(eventName, e, element.events[eventName]); } }
        for (const eventName in element.events) { textareaRef.current.addEventListener(eventName, functions[eventName]); }

        return () => {

            // Remove event listenersw
            if (!textareaRef.current) { return; }
            for (const eventName in element.events) { textareaRef.current.removeEventListener(eventName, functions[eventName]); }
        };
    }, []);

    // Attach events
    //----------------------------------------------------------------------------------------------------

    const containerRef = useRef(null);

    function showContainerHighlight() { showElementHighlight(containerRef); }
    function hideContainerHighlight() { hideElementHighlight(containerRef); }

    function showTextAreaHighlight() { showElementHighlight(textareaRef); }
    function hideTextAreaHighlight() { hideElementHighlight(textareaRef); }

    // Show / hide element highlight
    function showElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'true'); } catch { } }
    function hideElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'false'); } catch { } }

    useEffect(() => {
        element.extend({ showContainerHighlight, hideContainerHighlight, showTextAreaHighlight, hideTextAreaHighlight });
    }, []);

    // Component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={`gui-element ${element.id}-container`} ref={containerRef}>

            <ElementHeader element={element} editing={editing} />

            <div

                language="javascript"

                className={`code-editor ${element.errorMessage ? 'code-editor' : ''} ${element.id}-code-editor`}
                ref={textareaRef}

                style={{
                    overflow: 'auto',
                    resize: element.resize,
                    minHeight: element.minHeight || undefined,
                    maxHeight: element.maxHeight || undefined,
                    ...element.style,
                }}

                onMouseUp={resizeTextArea}
                onMouseLeave={resizeTextArea}

                {...element.properties}
            >

                <div className={'code-editor-line-numbers'}>

                    {displayValue.split('\n').map((_, index) => {
                        return (
                            <span key={index}>
                                {index + 1}
                            </span>
                        );
                    })}
                </div>

                <div style={{position: 'relative', width: '100%', minHeight: '100%'}}>
                    <pre className='code-editor-text-display'><code className={`language-javascript code-editor-text-display`}>{displayValue}</code></pre>
                    <textarea ref={codeEditorTextRef} spellCheck={false} contentEditable={true} suppressContentEditableWarning={true} onInput={updateValue} onKeyDown={keyDown} className={'code-editor-text-area'} value={displayValue}></textarea>
                </div>
                <div className={`code-editor-save-button ${element.options.settings.outOfDate ? 'code-editor-save-button-ood' : undefined}`} onClick={saveCode}>Save</div>
            </div>


            <ElementErrorMessage message={element.errorMessage} />

            {/* custom style tags */}
            <style>{`.${element.id}-container {${element.containerStyle}}`}</style>
            <style>{`.${element.id}-text-area {${element.textareaStyle}}`}</style>
        </div>
    );
}