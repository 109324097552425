// Modules
import { useEffect, useRef } from 'react';
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { Image } from '@edisonai/datatypes';

// Components
import ElementHeader from '../../SubElements/Header/ElementHeader.js';
import ElementErrorMessage from '../../SubElements/ErrorMessage/ElementErrorMessage.js';

// Resources
import imageIcon from "../../Icons/image.svg";

// Styles
import './Image.css';

// Component
export default function ImageComponent({ element, editing }) {

    const refresh = useRefresh();

    // Display value
    //----------------------------------------------------------------------------------------------------

    const image = getDisplayValue();

    // Get display value as string
    function getDisplayValue() {

        try {
            const value = element.getValue();
            if (!value) { return null; }
            return Image(element.getValue());
        }

        catch (e) {
            console.error(e);
            element.error(new Error(`Could not convert value to Image`));
        }
    }


    // Update nodemap variable value
    //----------------------------------------------------------------------------------------------

    async function handleUpdateValue(e) {

        const image = e.currentTarget.files[0];
        const imageMessage = await Image(image);
        element.updateValue(imageMessage);
    }

    // Attach events
    //----------------------------------------------------------------------------------------------------

    const containerRef = useRef(null);
    const elementRef = useRef(null);
    const imageRef = useRef(null);
    const placeholderRef = useRef(null);
    const placeholderIconRef = useRef(null);


    function showContainerHighlight() { showElementHighlight(containerRef); }
    function hideContainerHighlight() { hideElementHighlight(containerRef); }

    function showImageElementHighlight() { showElementHighlight(elementRef); }
    function hideImageElementHighlight() { hideElementHighlight(elementRef); }

    function showImageHighlight() { showElementHighlight(imageRef); }
    function hideImageHighlight() { hideElementHighlight(imageRef); }

    function showPlaceholderHighlight() { showElementHighlight(placeholderRef); }
    function hidePlaceholderHighlight() { hideElementHighlight(placeholderRef); }

    function showPlaceholderIconHighlight() { showElementHighlight(placeholderIconRef); }
    function hidePlaceholderIconHighlight() { hideElementHighlight(placeholderIconRef); }

    // Show / hide element highlight
    function showElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'true'); } catch { } }
    function hideElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'false'); } catch { } }

    useEffect(() => {
        element.extend({ showContainerHighlight, hideContainerHighlight, showImageElementHighlight, hideImageElementHighlight, showImageHighlight, hideImageHighlight, showPlaceholderHighlight, hidePlaceholderHighlight, showPlaceholderIconHighlight, hidePlaceholderIconHighlight });
    }, []);

    // component
    //----------------------------------------------------------------------------------------------

    return (
        <div className={`gui-element ${element.id}-container`} ref={containerRef}>

            <ElementHeader element={element} editing={editing} />

            <div className={`image ${element.id}-element`} ref={elementRef}>

                {image ? <img className={`image-image ${element.id}-image`} ref={imageRef} src={image} alt={''}></img> : null}

                {!image ?
                    <>
                        <div className={`image-upload-placeholder ${element.id}-placeholder`} ref={placeholderRef}>
                            <img className={`image-upload-placeholder-icon ${element.id}-placeholder-icon`} ref={placeholderIconRef} src={imageIcon} alt={''}></img>
                            {String(element.placeholder) || 'Click to Upload'}
                        </div>

                        <input className={`image-upload-input`} type={'file'} accept={'image/*'} onChange={handleUpdateValue}></input>
                    </>
                    : null}

            </div>

            <ElementErrorMessage message={element.errorMessage} />

            {/* custom style tags */}
            <style>{`.${element.id}-container {${element.containerStyle}}`}</style>
            <style>{`.${element.id}-element {${element.elementStyle}}`}</style>
            <style>{`.${element.id}-image {${element.imageStyle}}`}</style>
            <style>{`.${element.id}-placeholder {${element.placeholderStyle}}`}</style>
            <style>{`.${element.id}-placeholder-icon {${element.placeholderIconStyle}}`}</style>
        </div>
    );
}