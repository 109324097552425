// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-history-element {

    /* flex options */
    height: 100% !important;
    max-height: 100% !important;
    flex-grow: 1;
}

    .chat-history-relative {

        /* position */
        position: relative;

        /* dimentions */
        width: 100%;
        height: 100%;
        max-height: 100%;
        overflow: hidden;
        resize: vertical;

        /* flex options */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        /* margins */
        margin: 0px;

        /* border */
        border: solid;
        border-width: 1px;
        border-color: var(--distinguish-0p03);
        border-radius: 4px;

        /* background */
        background: var(--distinguish-0p03);

    }   .chat-history-error {

        /* border */
        border-color: rgba(255, 128, 128, 0.75);
    }

        .chat-history-absolute {

            /* position */
            position: absolute;
            inset: 0px;

            /* dimentions */
            overflow: scroll;
            resize: none;

            /* padding */
            padding: 10px;
        }`, "",{"version":3,"sources":["webpack://./src/Shared-Components/Gui/Elements/ChatHistory/ChatHistory.css"],"names":[],"mappings":"AAAA;;IAEI,iBAAiB;IACjB,uBAAuB;IACvB,2BAA2B;IAC3B,YAAY;AAChB;;IAEI;;QAEI,aAAa;QACb,kBAAkB;;QAElB,eAAe;QACf,WAAW;QACX,YAAY;QACZ,gBAAgB;QAChB,gBAAgB;QAChB,gBAAgB;;QAEhB,iBAAiB;QACjB,aAAa;QACb,sBAAsB;QACtB,2BAA2B;QAC3B,mBAAmB;;QAEnB,YAAY;QACZ,WAAW;;QAEX,WAAW;QACX,aAAa;QACb,iBAAiB;QACjB,qCAAqC;QACrC,kBAAkB;;QAElB,eAAe;QACf,mCAAmC;;IAEvC,IAAI;;QAEA,WAAW;QACX,uCAAuC;IAC3C;;QAEI;;YAEI,aAAa;YACb,kBAAkB;YAClB,UAAU;;YAEV,eAAe;YACf,gBAAgB;YAChB,YAAY;;YAEZ,YAAY;YACZ,aAAa;QACjB","sourcesContent":[".chat-history-element {\n\n    /* flex options */\n    height: 100% !important;\n    max-height: 100% !important;\n    flex-grow: 1;\n}\n\n    .chat-history-relative {\n\n        /* position */\n        position: relative;\n\n        /* dimentions */\n        width: 100%;\n        height: 100%;\n        max-height: 100%;\n        overflow: hidden;\n        resize: vertical;\n\n        /* flex options */\n        display: flex;\n        flex-direction: column;\n        justify-content: flex-start;\n        align-items: center;\n\n        /* margins */\n        margin: 0px;\n\n        /* border */\n        border: solid;\n        border-width: 1px;\n        border-color: var(--distinguish-0p03);\n        border-radius: 4px;\n\n        /* background */\n        background: var(--distinguish-0p03);\n\n    }   .chat-history-error {\n\n        /* border */\n        border-color: rgba(255, 128, 128, 0.75);\n    }\n\n        .chat-history-absolute {\n\n            /* position */\n            position: absolute;\n            inset: 0px;\n\n            /* dimentions */\n            overflow: scroll;\n            resize: none;\n\n            /* padding */\n            padding: 10px;\n        }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
