// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExpandableV_expandable__dS5Ov {

    /* position */
    position: relative;

    /* flex options */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    /* dimentions */
    width: 100%;
    flex-grow: 0;
}

    .ExpandableV_children__rpmtT {

        /* position */
        position: relative;

        /* flex options */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        /* dimentions */
        width: 100%;
        flex-grow: 1;
    }`, "",{"version":3,"sources":["webpack://./src/Shared-Components/ExpandableV/ExpandableV.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,kBAAkB;;IAElB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;;IAEnB,eAAe;IACf,WAAW;IACX,YAAY;AAChB;;IAEI;;QAEI,aAAa;QACb,kBAAkB;;QAElB,iBAAiB;QACjB,aAAa;QACb,sBAAsB;QACtB,2BAA2B;QAC3B,mBAAmB;;QAEnB,eAAe;QACf,WAAW;QACX,YAAY;IAChB","sourcesContent":[".expandable {\n\n    /* position */\n    position: relative;\n\n    /* flex options */\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n\n    /* dimentions */\n    width: 100%;\n    flex-grow: 0;\n}\n\n    .children {\n\n        /* position */\n        position: relative;\n\n        /* flex options */\n        display: flex;\n        flex-direction: column;\n        justify-content: flex-start;\n        align-items: center;\n\n        /* dimentions */\n        width: 100%;\n        flex-grow: 1;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expandable": `ExpandableV_expandable__dS5Ov`,
	"children": `ExpandableV_children__rpmtT`
};
export default ___CSS_LOADER_EXPORT___;
