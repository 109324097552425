// Modules
import { useEffect, useRef } from 'react';
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { frameThrottle } from '@Utils/functions/frameThrottle.js';

// Component function
export default function SandboxConnection({ connection }) {

    const refresh = useRefresh();
    function doRedraw() { frameThrottle(refresh); }

    useEffect(() => { return connection.listen('update', doRedraw); }, [connection]);

    // Getting state
    //----------------------------------------------------------------------------------------------------

    const basePath = useRef(null);
    const progressPath = useRef(null);
    const streamingPath = useRef(null);

    const inactiveColor = 'rgba(255, 255, 255, 0.25)';
    const activeColor = 'rgba(255, 255, 255, 0.75)';

    // Tell connection to compute state
    connection.computeState();

    // Getting start and end points
    //----------------------------------------------------------------------------------------------------

    const outputPos = connection.output?.hidden.getOwnCenter?.() || connection.output?.hidden.markerPos;
    const inputPos = connection.input?.hidden.getOwnCenter?.() || connection.input?.hidden.markerPos;

    if (!outputPos || !inputPos) { return; }

    const startPos = {
        x: outputPos.x,
        y: -1 * outputPos.y
    };
    const endPos = {
        x: inputPos.x,
        y: -1 * inputPos.y
    };

    // Getting connection path
    //----------------------------------------------------------------------------------------------------

    const originOffset = 15;
    const originRotation = connection.output?.options?.node?.rotation || 0;

    const targetOffset = 15;
    const targetRotation = connection.input?.options?.node?.rotation || 0;


    const connectionPath = drawConnectionPath(startPos, endPos);

    // Set path length for progressPath
    if (progressPath.current) {
        const pathLength = progressPath.current.getTotalLength();
        progressPath.current.style.strokeDasharray = `${pathLength} ${pathLength}`;
        progressPath.current.style.strokeDashoffset = String(pathLength);
    }

    function drawConnectionPath(start, end) {

        let s = {
            x: start.x + (originOffset * Math.cos(Math.PI / 180 * originRotation)),
            y: start.y + (originOffset * Math.sin(Math.PI / 180 * originRotation))
        }

        let e = {
            x: end.x - (targetOffset * Math.cos(Math.PI / 180 * targetRotation)),
            y: end.y - (targetOffset * Math.sin(Math.PI / 180 * targetRotation))
        }

        const totalDist = Math.pow((e.x - s.x) * (e.x - s.x) + (e.y - s.y) * (e.y - s.y), 0.4);
        const cpOffset = totalDist + 25;

        let cp1 = {
            x: start.x + (cpOffset * Math.cos(Math.PI / 180 * originRotation)),
            y: start.y + (cpOffset * Math.sin(Math.PI / 180 * originRotation))
        };

        let cp2 = {
            x: end.x - (1 * cpOffset * Math.cos(Math.PI / 180 * targetRotation)),
            y: end.y - (1 * cpOffset * Math.sin(Math.PI / 180 * targetRotation))
        };

        return `M${s.x},${s.y} C${cp1.x},${cp1.y} ${cp2.x},${cp2.y} ${e.x},${e.y}`;
    }

    // Component html
    //----------------------------------------------------------------------------------------------------

    return (
        <>
            <path
                ref={basePath}
                id="inactiveGradientPath"
                d={connectionPath}
                vectorEffect="non-scaling-stroke"
                stroke={connection.state !== 'streaming' ? inactiveColor : 'transparent'}
                fill="none"
                strokeWidth="1.5"
            />

            <path
                ref={progressPath}
                id="activeGradientPath"
                d={connectionPath}
                vectorEffect="non-scaling-stroke"
                stroke={connection.state === 'active' ? activeColor : 'transparent'}
                fill="none"
                strokeWidth="1.5"
                className={connection.state === 'active' ? 'active' : ''}
            />

            <path
                ref={streamingPath}
                id="activeGradientPath"
                d={connectionPath}
                vectorEffect="non-scaling-stroke"
                stroke={connection.state === 'streaming' ? activeColor : 'transparent'}
                fill="none"
                strokeWidth="1.5"
                className={connection.state === 'streaming' ? 'streaming' : ''}
            />
        </>
    );
}