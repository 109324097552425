// Modules
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// State
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { VisualEditorState } from '@State/VisualEditor/VisualEditorState.js';
import { CognitoSessionState } from '@State/CognitoSession/CognitoSessionState.js';

// Resources
import saveIcon from './saveIcon.svg';
import copyIcon from './copyIcon.svg';
import deployIcon from './deployIcon.svg';

// Styles
import styles from './SaveMenu.module.css';

// Component function
export default function SaveMenu() {

    const navigate = useNavigate();
    const refresh = useRefresh();

    const nodemap = VisualEditorState.nodemap.instance;

    // Saving nodemap
    //----------------------------------------------------------------------------------------------------

    // Listen for ctrl + s to save nodemap
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 's') {
                event.preventDefault();
                saveNodemap();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => { window.removeEventListener('keydown', handleKeyDown); };
    }, []);

    useEffect(() => {
        return nodemap.listen('save', saveNodemap);
    }, []);

    useEffect(() => { return nodemap.listen('outOfDate', () => { VisualEditorState.nodemap.outOfDate = true; refresh(); }); }, []);
    useEffect(() => { return nodemap.listen('update', () => { VisualEditorState.nodemap.outOfDate = true; refresh(); }); }, []);

    // Make the user confirm they want to leave
    useEffect(() => {

        const interceptLeave = (e) => {

            if (VisualEditorState.nodemap.outOfDate) {
                e.preventDefault();
                e.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
            }
        }

        window.addEventListener('beforeunload', interceptLeave);

        return () => {
            window.removeEventListener('beforeunload', interceptLeave);
        }
    }, []);

    // Save nodemap to database
    async function saveNodemap() {

        try {

            document.body.classList.add('cursor-progress');

            const { newId } = await VisualEditorState.saveNodemap(CognitoSessionState.userData);

            if (newId) {
                VisualEditorState.nodemap.instance.id = newId;
                navigate(`/${newId}`);
            }

            VisualEditorState.nodemap.outOfDate = false;
            VisualEditorState.notification({ color: 'success', title: 'Saved Nodemap' });

            document.body.classList.remove('cursor-progress');

            refresh();
        }

        catch (e) {
            console.error(e);
            document.body.classList.remove('cursor-progress');
            VisualEditorState.notification({ color: 'error', title: 'Could Not Save Nodemap', text: e.message });
        }
    }

    // Save copy of nodemap to database
    async function copyNodemap() {

        try {

            const result = await VisualEditorState.copyNodemap();
            VisualEditorState.nodemap.outOfDate = false;

            if (result.newNodemapId) {
                window.open(`${process.env.REACT_APP_ORIGIN}/${result.newNodemapId}`);
            }

            refresh();
            //if (result.nodemapId) { window.open(`${process.env.REACT_APP_ORIGIN}/${result.nodemapId}`); }
        }

        catch (e) {
            console.error(e);
            VisualEditorState.notification({ color: 'error', title: 'Could Not Copy Nodemap', text: e.message });
        }
    }

    // Component html
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={styles['save-menu']}>

            <img className={styles['save-menu-button']} src={saveIcon} title={'Save'} style={{ opacity: VisualEditorState.nodemap.outOfDate ? '1' : undefined }} onClick={saveNodemap} onDragStart={(e) => { e.preventDefault(); }}></img>
            <img className={styles['save-menu-button']} src={copyIcon} title={'Copy'} onClick={copyNodemap} onDragStart={(e) => { e.preventDefault(); }}></img>
            <img className={styles['save-menu-button']} src={deployIcon} title={'Deploy'} onDragStart={(e) => { e.preventDefault(); }}></img>

        </div>
    );
}