// Modules
import { useEffect, useState } from 'react';

// Components
import ExpandableH from '@Shared-Components/ExpandableH/ExpandableH.js';
import PositionIndicator from './PositionIndicator/PositionIndicator.js';
import Nodemaps from './Nodemaps/Nodemaps.js';
import Edit from './Edit/Edit.js';
import Settings from './Settings/Settings.js';

// Resources
import NodemapsIcon from './Icons/nodemaps.svg';
import EditIcon from './Icons/edit.svg';
import SettingsIcon from './Icons/settings.svg';
import UsageIcon from './Icons/usage.svg';
import AccountIcon from './Icons/account.svg';

// Styles
import s from './LeftMenu.module.css';
import fadeIn from '@Styles/Animations/FadeIn/FadeIn.module.css';

// Component function
export default function LeftMenu() {

    // Manage selected
    //--------------------------------------------------------------------------------

    const [selected, setSelected] = useState('edit');

    function selectorButtonClick(option) {

        if (option === selected) { setSelected(null); }
        else { setSelected(option); }
    }

    // Component HTML
    //--------------------------------------------------------------------------------

    return (
        <div className={s['left-menu-container']}>

            <div className={s['left-menu-background']}>
                <ExpandableH className={s['left-menu']} expanded={selected === 'maps'} time={300}> <Nodemaps /> </ExpandableH>
                <ExpandableH className={s['left-menu']} expanded={selected === 'edit'} time={300}> <Edit /> </ExpandableH>
                <ExpandableH className={s['left-menu']} expanded={selected === 'settings'} time={300}> <Settings /> </ExpandableH>
                <ExpandableH className={s['left-menu']} expanded={selected === 'usage'} time={300}> <Settings /> </ExpandableH>
            </div>

            <div className={s['selector-and-position-indicator']}>

                <div className={s['left-menu-selector'] + ' ' + fadeIn['one-s']}>

                    <div className={s['left-menu-selector-button']} highlight={String(selected === 'maps')} onClick={() => { selectorButtonClick('maps'); }}>
                        <img className={s['left-menu-selector-button-icon']} src={NodemapsIcon} onDragStart={(e) => { e.preventDefault(); }}></img>
                        <span className={s['left-menu-selector-button-text']}>Maps</span>
                    </div>

                    <div style={{ marginTop: '8px', marginBottom: '8px' }}></div>

                    <div className={s['left-menu-selector-button']} highlight={String(selected === 'edit')} onClick={() => { selectorButtonClick('edit'); }}>
                        <img className={s['left-menu-selector-button-icon']} src={EditIcon} onDragStart={(e) => { e.preventDefault(); }}></img>
                        <span className={s['left-menu-selector-button-text']}>Edit</span>
                    </div>

                    <div style={{ marginTop: '8px', marginBottom: '8px' }}></div>

                    <div className={s['left-menu-selector-button']} highlight={String(selected === 'settings')} onClick={() => { selectorButtonClick('settings'); }}>
                        <img className={s['left-menu-selector-button-icon']} src={SettingsIcon} onDragStart={(e) => { e.preventDefault(); }}></img>
                    </div>

                    <div style={{ marginTop: '8px', marginBottom: '8px' }}></div>

                    <div className={s['left-menu-selector-button']} highlight={String(selected === 'usage')} onClick={() => { selectorButtonClick('usage'); }}>
                        <img className={s['left-menu-selector-button-icon']} src={UsageIcon} onDragStart={(e) => { e.preventDefault(); }}></img>
                    </div>

                    <div style={{ marginTop: '8px', marginBottom: '8px' }}></div>

                    <div className={s['left-menu-selector-button']} highlight={String(selected === 'account')} onClick={() => { selectorButtonClick('account'); }}>
                        <img className={s['left-menu-selector-button-icon']} src={AccountIcon} onDragStart={(e) => { e.preventDefault(); }}></img>
                    </div>
                </div>

                <PositionIndicator />
            </div>
        </div>
    );
}