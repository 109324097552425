export const defaultNodemap = {

    info: {
        "isPublic": true,
    },

    settings: {
        nodePacks: [
            'https://github.com/Edison-AI-Systems/Basic-Nodes-Pack/',
            'https://github.com/Edison-AI-Systems/Utility-Nodes-Pack/',
            'https://github.com/Edison-AI-Systems/Primitives-Node-Pack/',
            'https://github.com/Edison-AI-Systems/OpenAI-Nodes-Pack/',
        ]
    },
    
    gui: {
        "elements": [
            {
                "name": "Text Input",
                "type": "textarea",
                "icon": "/static/media/text.f548d448ade35e182d4b8475d4ebf1ae.svg",
                "showName": true,
                "showCount": true,
                "showIndex": true,
                "showClearButton": true,
                "editable": true,
                "resizable": true,
                "minLength": 0,
                "maxLength": 500,
                "count": "characters",
                "requireContent": true,
                "placeholder": null,
                "onSubmit": [
                    {
                        "command": "MESSAGE_NODE",
                        "parameter": "same_as_field"
                    }
                ],
                "onRecieve": "createNewItem",
                "gui": {
                    "type": "group",
                    "direction": "vertical",
                    "elements": [
                        {
                            "type": "group",
                            "direction": "horizontal",
                            "name": "Name",
                            "showLine": true,
                            "elements": [
                                {
                                    "type": "text",
                                    "name": "Name",
                                    "setting": "name",
                                    "id": "BXYYBM"
                                },
                                {
                                    "type": "text",
                                    "name": "Placeholder",
                                    "setting": "placeholder",
                                    "id": "XOTPUX"
                                },
                                {
                                    "type": "checkBox",
                                    "name": "Show name",
                                    "setting": "showName",
                                    "id": "RXZBNF"
                                }
                            ],
                            "id": "JRTLHS"
                        },
                        {
                            "type": "group",
                            "direction": "horizontal",
                            "name": "Size",
                            "showLine": true,
                            "elements": [
                                {
                                    "type": "number",
                                    "name": "Min height",
                                    "setting": "minHeight",
                                    "id": "XWFGHP"
                                },
                                {
                                    "type": "number",
                                    "name": "Max height",
                                    "setting": "maxHeight",
                                    "id": "WRAOBX"
                                },
                                {
                                    "type": "number",
                                    "name": "Height",
                                    "setting": "height",
                                    "id": "PDTNES"
                                },
                                {
                                    "type": "checkBox",
                                    "name": "Resizable",
                                    "setting": "resizable",
                                    "id": "UIJVDH"
                                },
                                {
                                    "type": "checkBox",
                                    "name": "Auto resize",
                                    "setting": "autoResize",
                                    "id": "NBEZAR"
                                }
                            ],
                            "id": "CHKPFH"
                        },
                        {
                            "type": "group",
                            "direction": "horizontal",
                            "name": "Content / count",
                            "showLine": true,
                            "elements": [
                                {
                                    "type": "number",
                                    "name": "Min length",
                                    "setting": "minLength",
                                    "minValue": 0,
                                    "step": 1,
                                    "id": "FNSGXR"
                                },
                                {
                                    "type": "number",
                                    "name": "Max length",
                                    "setting": "maxLength",
                                    "minValue": 0,
                                    "step": 1,
                                    "id": "OHRZDN"
                                },
                                {
                                    "type": "dropdown",
                                    "name": "Count",
                                    "setting": "count",
                                    "values": [
                                        "",
                                        "characters",
                                        "words",
                                        "items"
                                    ],
                                    "id": "JPDGRT"
                                },
                                {
                                    "type": "checkBox",
                                    "name": "Require content",
                                    "setting": "requireContent",
                                    "id": "RWDMJP"
                                }
                            ],
                            "id": "GIJAMK"
                        },
                        {
                            "type": "group",
                            "direction": "horizontal",
                            "name": "Value / Items",
                            "showLine": true,
                            "elements": [
                                {
                                    "type": "dropdown",
                                    "name": "When element recieves data",
                                    "setting": "onRecieve",
                                    "values": [
                                        {
                                            "name": "Create new item",
                                            "value": "createNewItem"
                                        },
                                        {
                                            "name": "Add to last item",
                                            "value": "addToLast"
                                        },
                                        {
                                            "name": "Replace last item",
                                            "value": "replaceLast"
                                        }
                                    ],
                                    "id": "CRWLQN"
                                },
                                {
                                    "type": "checkBox",
                                    "name": "Item list",
                                    "setting": "showIndex",
                                    "id": "IIVQSA"
                                },
                                {
                                    "type": "checkBox",
                                    "name": "Clear button",
                                    "setting": "showClearButton",
                                    "id": "FSOSKU"
                                }
                            ],
                            "id": "HRWOPL"
                        },
                        {
                            "type": "group",
                            "direction": "vertical",
                            "name": "Actions",
                            "showLine": true,
                            "elements": [
                                {
                                    "type": "submitactions",
                                    "name": "When field is submitted",
                                    "setting": "onSubmit",
                                    "id": "PHZFXZ"
                                }
                            ],
                            "id": "XLAXXD"
                        },
                        {
                            "type": "group",
                            "direction": "vertical",
                            "name": "General styles (insert custom CSS)",
                            "showLine": true,
                            "collapsed": true,
                            "elements": [
                                {
                                    "type": "textarea",
                                    "name": "Element Container",
                                    "placeholder": "Write custom CSS here",
                                    "setting": "containerStyle",
                                    "events": {
                                        "mouseenter": "showContainerHighlight",
                                        "mouseleave": "hideContainerHighlight"
                                    },
                                    "id": "SXVOAH"
                                },
                                {
                                    "type": "textarea",
                                    "name": "Element Name",
                                    "placeholder": "Write custom CSS here",
                                    "setting": "nameStyle",
                                    "events": {
                                        "mouseenter": "showNameHighlight",
                                        "mouseleave": "hideNameHighlight"
                                    },
                                    "id": "ONFXZS"
                                },
                                {
                                    "type": "textarea",
                                    "name": "Character Counter",
                                    "placeholder": "Write custom CSS here",
                                    "setting": "countStyle",
                                    "events": {
                                        "mouseenter": "showCountHighlight",
                                        "mouseleave": "hideCountHighlight"
                                    },
                                    "id": "YLEEEN"
                                },
                                {
                                    "type": "textarea",
                                    "name": "Clear Button",
                                    "placeholder": "Write custom CSS here",
                                    "setting": "clearButtonStyle",
                                    "events": {
                                        "mouseenter": "showClearButtonHighlight",
                                        "mouseleave": "hideClearButtonHighlight"
                                    },
                                    "id": "HMCLRC"
                                }
                            ],
                            "id": "UIOTOF"
                        },
                        {
                            "type": "group",
                            "direction": "vertical",
                            "name": "Text area style",
                            "showLine": true,
                            "collapsed": true,
                            "elements": [
                                {
                                    "type": "textarea",
                                    "name": "Text area",
                                    "placeholder": "Write custom CSS here",
                                    "setting": "textareaStyle",
                                    "events": {
                                        "mouseenter": "showTextAreaHighlight",
                                        "mouseleave": "hideTextAreaHighlight"
                                    },
                                    "id": "EGLTHG"
                                }
                            ],
                            "id": "PYJORA"
                        }
                    ],
                    "id": "OVRHNB"
                },
                "id": "BQJDBA",
                "style": {
                    "height": "50px",
                    "width": "366px"
                }
            },
            {
                "name": "Button",
                "type": "button",
                "icon": "/static/media/button.4bd7bd651f8d09783ed201b86962ecd0.svg",
                "editable": true,
                "showLines": true,
                "hideName": true,
                "onSubmit": [
                    {
                        "command": "SUBMIT_FIELD",
                        "parameter": "Text Input"
                    }
                ],
                "gui": {
                    "type": "group",
                    "direction": "vertical",
                    "elements": [
                        {
                            "type": "group",
                            "direction": "horizontal",
                            "name": "Name",
                            "showLine": true,
                            "elements": [
                                {
                                    "type": "text",
                                    "name": "Name",
                                    "setting": "name",
                                    "id": "JPUTXW"
                                },
                                {
                                    "type": "text",
                                    "name": "Width",
                                    "setting": "width",
                                    "values": [
                                        "auto",
                                        "100%",
                                        "50%"
                                    ],
                                    "id": "DKTWLN"
                                },
                                {
                                    "type": "checkBox",
                                    "name": "Show lines",
                                    "setting": "showLines",
                                    "id": "LPPWUS"
                                }
                            ],
                            "id": "WCWFMC"
                        },
                        {
                            "type": "group",
                            "direction": "vertical",
                            "name": "Actions",
                            "showLine": true,
                            "elements": [
                                {
                                    "type": "submitactions",
                                    "name": "When button is clicked",
                                    "setting": "onSubmit",
                                    "id": "DYOSWK"
                                }
                            ],
                            "id": "KXOMJP"
                        },
                        {
                            "type": "group",
                            "direction": "vertical",
                            "name": "General styles (insert custom CSS)",
                            "showLine": true,
                            "collapsed": true,
                            "elements": [
                                {
                                    "type": "textarea",
                                    "name": "Element Container",
                                    "placeholder": "",
                                    "setting": "nameStyle",
                                    "events": {
                                        "mouseenter": "showContainerHighlight",
                                        "mouseleave": "hideContainerHighlight"
                                    },
                                    "id": "USTEII"
                                }
                            ],
                            "id": "DGWTDN"
                        },
                        {
                            "type": "group",
                            "direction": "vertical",
                            "name": "Element styles (insert custom CSS)",
                            "showLine": true,
                            "collapsed": true,
                            "elements": [
                                {
                                    "type": "textarea",
                                    "name": "Button",
                                    "placeholder": "",
                                    "setting": "buttonStyle",
                                    "events": {
                                        "mouseenter": "showButtonHighlight",
                                        "mouseleave": "hideButtonHighlight"
                                    },
                                    "id": "TMPCOV"
                                },
                                {
                                    "type": "textarea",
                                    "name": "Side lines",
                                    "placeholder": "",
                                    "setting": "sideLineStyle",
                                    "events": {
                                        "mouseenter": "showSideLineHighlight",
                                        "mouseleave": "hideSideLineHighlight"
                                    },
                                    "id": "DKFMOX"
                                }
                            ],
                            "id": "EBJZQS"
                        }
                    ],
                    "id": "HTSOJL"
                },
                "id": "GTPFIT"
            },
            {
                "name": "Text Output",
                "type": "textarea",
                "icon": "/static/media/text.f548d448ade35e182d4b8475d4ebf1ae.svg",
                "showName": true,
                "showCount": true,
                "showIndex": true,
                "showClearButton": true,
                "editable": true,
                "resizable": true,
                "minLength": 0,
                "maxLength": 500,
                "count": "characters",
                "requireContent": true,
                "placeholder": null,
                "onSubmit": [
                    {
                        "command": "MESSAGE_NODE",
                        "parameter": "same_as_field"
                    }
                ],
                "onRecieve": "createNewItem",
                "gui": {
                    "type": "group",
                    "direction": "vertical",
                    "elements": [
                        {
                            "type": "group",
                            "direction": "horizontal",
                            "name": "Name",
                            "showLine": true,
                            "elements": [
                                {
                                    "type": "text",
                                    "name": "Name",
                                    "setting": "name",
                                    "id": "BCABPL"
                                },
                                {
                                    "type": "text",
                                    "name": "Placeholder",
                                    "setting": "placeholder",
                                    "id": "IAPKKJ"
                                },
                                {
                                    "type": "checkBox",
                                    "name": "Show name",
                                    "setting": "showName",
                                    "id": "ZSIQNF"
                                }
                            ],
                            "id": "OWPSTT"
                        },
                        {
                            "type": "group",
                            "direction": "horizontal",
                            "name": "Size",
                            "showLine": true,
                            "elements": [
                                {
                                    "type": "number",
                                    "name": "Min height",
                                    "setting": "minHeight",
                                    "id": "PANVRS"
                                },
                                {
                                    "type": "number",
                                    "name": "Max height",
                                    "setting": "maxHeight",
                                    "id": "SKZEPO"
                                },
                                {
                                    "type": "number",
                                    "name": "Height",
                                    "setting": "height",
                                    "id": "FYXDTC"
                                },
                                {
                                    "type": "checkBox",
                                    "name": "Resizable",
                                    "setting": "resizable",
                                    "id": "KBMDXP"
                                },
                                {
                                    "type": "checkBox",
                                    "name": "Auto resize",
                                    "setting": "autoResize",
                                    "id": "AMZQNA"
                                }
                            ],
                            "id": "LMLCHR"
                        },
                        {
                            "type": "group",
                            "direction": "horizontal",
                            "name": "Content / count",
                            "showLine": true,
                            "elements": [
                                {
                                    "type": "number",
                                    "name": "Min length",
                                    "setting": "minLength",
                                    "minValue": 0,
                                    "step": 1,
                                    "id": "DALZCX"
                                },
                                {
                                    "type": "number",
                                    "name": "Max length",
                                    "setting": "maxLength",
                                    "minValue": 0,
                                    "step": 1,
                                    "id": "SQRGLN"
                                },
                                {
                                    "type": "dropdown",
                                    "name": "Count",
                                    "setting": "count",
                                    "values": [
                                        "",
                                        "characters",
                                        "words",
                                        "items"
                                    ],
                                    "id": "IEHOJW"
                                },
                                {
                                    "type": "checkBox",
                                    "name": "Require content",
                                    "setting": "requireContent",
                                    "id": "CSAXQG"
                                }
                            ],
                            "id": "GNNBJA"
                        },
                        {
                            "type": "group",
                            "direction": "horizontal",
                            "name": "Value / Items",
                            "showLine": true,
                            "elements": [
                                {
                                    "type": "dropdown",
                                    "name": "When element recieves data",
                                    "setting": "onRecieve",
                                    "values": [
                                        {
                                            "name": "Create new item",
                                            "value": "createNewItem"
                                        },
                                        {
                                            "name": "Add to last item",
                                            "value": "addToLast"
                                        },
                                        {
                                            "name": "Replace last item",
                                            "value": "replaceLast"
                                        }
                                    ],
                                    "id": "SQGKJV"
                                },
                                {
                                    "type": "checkBox",
                                    "name": "Item list",
                                    "setting": "showIndex",
                                    "id": "ABSIAK"
                                },
                                {
                                    "type": "checkBox",
                                    "name": "Clear button",
                                    "setting": "showClearButton",
                                    "id": "ZIUSLZ"
                                }
                            ],
                            "id": "RQKKAN"
                        },
                        {
                            "type": "group",
                            "direction": "vertical",
                            "name": "Actions",
                            "showLine": true,
                            "elements": [
                                {
                                    "type": "submitactions",
                                    "name": "When field is submitted",
                                    "setting": "onSubmit",
                                    "id": "OSFIKY"
                                }
                            ],
                            "id": "WPDPUC"
                        },
                        {
                            "type": "group",
                            "direction": "vertical",
                            "name": "General styles (insert custom CSS)",
                            "showLine": true,
                            "collapsed": true,
                            "elements": [
                                {
                                    "type": "textarea",
                                    "name": "Element Container",
                                    "placeholder": "Write custom CSS here",
                                    "setting": "containerStyle",
                                    "events": {
                                        "mouseenter": "showContainerHighlight",
                                        "mouseleave": "hideContainerHighlight"
                                    },
                                    "id": "YNYOHN"
                                },
                                {
                                    "type": "textarea",
                                    "name": "Element Name",
                                    "placeholder": "Write custom CSS here",
                                    "setting": "nameStyle",
                                    "events": {
                                        "mouseenter": "showNameHighlight",
                                        "mouseleave": "hideNameHighlight"
                                    },
                                    "id": "IWNPST"
                                },
                                {
                                    "type": "textarea",
                                    "name": "Character Counter",
                                    "placeholder": "Write custom CSS here",
                                    "setting": "countStyle",
                                    "events": {
                                        "mouseenter": "showCountHighlight",
                                        "mouseleave": "hideCountHighlight"
                                    },
                                    "id": "TAVXRJ"
                                },
                                {
                                    "type": "textarea",
                                    "name": "Clear Button",
                                    "placeholder": "Write custom CSS here",
                                    "setting": "clearButtonStyle",
                                    "events": {
                                        "mouseenter": "showClearButtonHighlight",
                                        "mouseleave": "hideClearButtonHighlight"
                                    },
                                    "id": "EQLNER"
                                }
                            ],
                            "id": "JDDMCQ"
                        },
                        {
                            "type": "group",
                            "direction": "vertical",
                            "name": "Text area style",
                            "showLine": true,
                            "collapsed": true,
                            "elements": [
                                {
                                    "type": "textarea",
                                    "name": "Text area",
                                    "placeholder": "Write custom CSS here",
                                    "setting": "textareaStyle",
                                    "events": {
                                        "mouseenter": "showTextAreaHighlight",
                                        "mouseleave": "hideTextAreaHighlight"
                                    },
                                    "id": "OSTUFD"
                                }
                            ],
                            "id": "VDMMFX"
                        }
                    ],
                    "id": "WEIGCK"
                },
                "id": "BCYYRA",
                "style": {
                    "height": "50px",
                    "width": "366px"
                }
            }
        ]
    }
}