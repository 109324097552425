// Modules
import { useRef, useEffect } from 'react';

// Components
import ElementHeader from '../../SubElements/Header/ElementHeader.js';
import ElementErrorMessage from '../../SubElements/ErrorMessage/ElementErrorMessage.js';

// Styles
import './NumberSlider.css';

// Component
export default function NumberSlider({ element, editing }) {

    // Display value
    //----------------------------------------------------------------------------------------------------

    const displayValue = getDisplayValue();

    // Get display value as string
    function getDisplayValue() {

        try {

            const value = Number(element.getValue());

            // Parse the roundValue
            const roundValue = parseFloat(element.step);

            // Determine the number of decimal places
            const decimalPlaces = !isNaN(roundValue) && roundValue.toString().includes('.')
                ? roundValue.toString().split('.')[1].length
                : 0;

            // Format the number to the determined decimal places
            return (value || 0).toFixed(decimalPlaces);
        }
        
        catch (e) {
            console.error(e);
            element.error(new Error(`Could not convert value to Number`));
            return '';
        }
    }

    // Value calculations
    //----------------------------------------------------------------------------------------------------

    const progress = (displayValue - (element.minValue || 0)) / (element.maxValue - (element.minValue || 0));

    function rangeMouseDown(e) {
        window.addEventListener('mousemove', rangeMouseMove);
        window.addEventListener('mouseup', rangeMouseUp);
        rangeMouseMove(e);
    }

    function rangeMouseUp(e) {
        window.removeEventListener('mousemove', rangeMouseMove);
        window.removeEventListener('mouseup', rangeMouseUp);
    }

    function rangeMouseMove(e) {

        const sliderRect = containerRef.current.getBoundingClientRect();
        const relativePosition = (e.clientX - sliderRect.left) / sliderRect.width;
        let newValue = relativePosition * (element.maxValue - element.minValue) + element.minValue;

        // Rounding the value according to the step
        newValue = Math.round(newValue / element.step) * element.step;

        // Restricting the value to the min and max limits
        newValue = Math.max(element.minValue, Math.min(newValue, element.maxValue));

        // Update element value
        element.updateValue(newValue);
        sliderRef.current.dispatchEvent(new Event('change'));
    }

    useEffect(() => {
        // Clean up event listeners on component unmount
        return () => {
            window.removeEventListener('mousemove', rangeMouseMove);
            window.removeEventListener('mouseup', rangeMouseUp);
        };
    }, []);
    

    // Attach events
    //----------------------------------------------------------------------------------------------------

    const containerRef = useRef(null);
    const sliderRef = useRef(null);
    const sliderTrackRef = useRef(null);
    const sliderBarRef = useRef(null);

    function showContainerHighlight() { showElementHighlight(containerRef); }
    function hideContainerHighlight() { hideElementHighlight(containerRef); }

    function showSliderHighlight() { showElementHighlight(sliderRef); }
    function hideSliderHighlight() { hideElementHighlight(sliderRef); }

    function showTrackHighlight() { showElementHighlight(sliderTrackRef); }
    function hideTrackHighlight() { hideElementHighlight(sliderTrackRef); }


    function showBarHighlight() { showElementHighlight(sliderBarRef); }
    function hideBarHighlight() { hideElementHighlight(sliderBarRef); }


    // Show / hide element highlight
    function showElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'true'); } catch { } }
    function hideElementHighlight(ref) { try { ref.current.setAttribute('highlight', 'false'); } catch { } }

    useEffect(() => {
        element.extend({ showContainerHighlight, hideContainerHighlight, showSliderHighlight, hideSliderHighlight, showTrackHighlight, hideTrackHighlight, showBarHighlight, hideBarHighlight });
    }, []);

    // Element specific events
    //----------------------------------------------------------------------------------------------------

    // Attach listed events to element
    useEffect(() => {

        // Requires valid inputRef
        if (!sliderRef.current) { return; }

        const functions = {};

        // Define and attach functions, add event listener
        for (const eventName in element.events) { functions[eventName] = (e) => { element.onEvent(eventName, e, element.events[eventName]); } }
        for (const eventName in element.events) { sliderRef.current.addEventListener(eventName, functions[eventName]); }

        return () => {

            // Remove event listeners
            if (!sliderRef.current) { return; }
            for (const eventName in element.events) { sliderRef.current.removeEventListener(eventName, functions[eventName]); }
        };
    }, []);

    // Component
    //----------------------------------------------------------------------------------------------------

    return (

        <div className={`gui-element ${element.id}-container`} ref={containerRef}>

            <ElementHeader element={element} editing={editing} nameModifier={String(displayValue)} />

            <div className={`range-input-custom ${element.id}-slider`} ref={sliderRef} onMouseDown={rangeMouseDown} onMouseUp={rangeMouseUp}>
                <div className={`range-input-custom-bar ${element.id}-track`} ref={sliderTrackRef}></div>
                <div className='range-input-custom-thumb-container' style={{ marginLeft: 100 * progress + '%', marginRight: 100 - 100 * progress + '%' }}>
                    <div className='range-input-custom-thumb' style={{ background: 'transparent', outline: 'none', background: 'none', boxShadow: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className={`range-input-custom-thumb ${element.id}-bar`} ref={sliderBarRef} style={{ transform: `translateX(${50 - 100 * progress}%)` }}></div>
                    </div>
                </div>
            </div>

            <ElementErrorMessage message={element.errorMessage} />

            {/* custom style tags */}
            <style>{`.${element.id}-container {${element.containerStyle}}`}</style>
            <style>{`.${element.id}-slider {${element.sliderStyle}}`}</style>
            <style>{`.${element.id}-track {${element.trackStyle}}`}</style>
            <style>{`.${element.id}-bar {${element.barStyle}}`}</style>
        </div>
    );
}