// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProgressIndicator_progressContainer__FRHfh {

    /* flex options */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* dimentions */
    width: auto;
    height: auto;
    flex-grow: 0;
}

.ProgressIndicator_progressCircle__MYYFW {

    /* position */
    position: absolute;

    /* dimentions */
    width: 200px;
    height: 200px;
}

.ProgressIndicator_progressText__55bs8 {

    /* padding */
    padding-left: 25%;
    padding-right: 25%;

    /* font */
    color: rgba(255, 255, 255, 0.75);
    font-size: 16px;
    font-weight: 300;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Shared-Components/ProgressIndicator/ProgressIndicator.module.css"],"names":[],"mappings":"AAAA;;IAEI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;;IAEnB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,YAAY;AAChB;;AAEA;;IAEI,aAAa;IACb,kBAAkB;;IAElB,eAAe;IACf,YAAY;IACZ,aAAa;AACjB;;AAEA;;IAEI,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;;IAElB,SAAS;IACT,gCAAgC;IAChC,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".progressContainer {\n\n    /* flex options */\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n\n    /* dimentions */\n    width: auto;\n    height: auto;\n    flex-grow: 0;\n}\n\n.progressCircle {\n\n    /* position */\n    position: absolute;\n\n    /* dimentions */\n    width: 200px;\n    height: 200px;\n}\n\n.progressText {\n\n    /* padding */\n    padding-left: 25%;\n    padding-right: 25%;\n\n    /* font */\n    color: rgba(255, 255, 255, 0.75);\n    font-size: 16px;\n    font-weight: 300;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressContainer": `ProgressIndicator_progressContainer__FRHfh`,
	"progressCircle": `ProgressIndicator_progressCircle__MYYFW`,
	"progressText": `ProgressIndicator_progressText__55bs8`
};
export default ___CSS_LOADER_EXPORT___;
