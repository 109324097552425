// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ElementErrorMessage_gui-element-error-message__2SMUE {

    /* dimentions */
    width: 100%;
    height: auto;

    /* margins */
    margin-top: 5px;

    /* font */
    color: rgb(255, 120, 120, 0.75);
    font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/Shared-Components/Gui/SubElements/ErrorMessage/ElementErrorMessage.module.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,WAAW;IACX,YAAY;;IAEZ,YAAY;IACZ,eAAe;;IAEf,SAAS;IACT,+BAA+B;IAC/B,eAAe;AACnB","sourcesContent":[".gui-element-error-message {\n\n    /* dimentions */\n    width: 100%;\n    height: auto;\n\n    /* margins */\n    margin-top: 5px;\n\n    /* font */\n    color: rgb(255, 120, 120, 0.75);\n    font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gui-element-error-message": `ElementErrorMessage_gui-element-error-message__2SMUE`
};
export default ___CSS_LOADER_EXPORT___;
