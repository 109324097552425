// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {

    /* dimentions */
    width: 100%;
    height: auto;

    /* flex options */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

    .button {

        /* padding */
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 4px;
        padding-bottom: 4px;

        /* margins */
        margin-left: 15px;
        margin-right: 15px;

        /* border */
        border-radius: 4px;

        /* background */
        background: var(--distinguish-0p05);

        /* font */
        font-size: 12px;
        font-weight: 600;
        white-space: nowrap;

        /* pre-hover */
        opacity: 0.75;
        transition: all 0.25s;

    } .button:hover {

        /* post-hover */
        cursor: pointer;
        opacity: 1;
    }

    .button-line {

        /* dimentions */
        width: 100%;
        height: 1px;

        /* background */
        background-color: var(--line-color);
    }`, "",{"version":3,"sources":["webpack://./src/Shared-Components/Gui/Elements/Button/Button.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,WAAW;IACX,YAAY;;IAEZ,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB;;IAEI;;QAEI,YAAY;QACZ,kBAAkB;QAClB,mBAAmB;QACnB,gBAAgB;QAChB,mBAAmB;;QAEnB,YAAY;QACZ,iBAAiB;QACjB,kBAAkB;;QAElB,WAAW;QACX,kBAAkB;;QAElB,eAAe;QACf,mCAAmC;;QAEnC,SAAS;QACT,eAAe;QACf,gBAAgB;QAChB,mBAAmB;;QAEnB,cAAc;QACd,aAAa;QACb,qBAAqB;;IAEzB,EAAE;;QAEE,eAAe;QACf,eAAe;QACf,UAAU;IACd;;IAEA;;QAEI,eAAe;QACf,WAAW;QACX,WAAW;;QAEX,eAAe;QACf,mCAAmC;IACvC","sourcesContent":[".button-container {\n\n    /* dimentions */\n    width: 100%;\n    height: auto;\n\n    /* flex options */\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n}\n\n    .button {\n\n        /* padding */\n        padding-left: 12px;\n        padding-right: 12px;\n        padding-top: 4px;\n        padding-bottom: 4px;\n\n        /* margins */\n        margin-left: 15px;\n        margin-right: 15px;\n\n        /* border */\n        border-radius: 4px;\n\n        /* background */\n        background: var(--distinguish-0p05);\n\n        /* font */\n        font-size: 12px;\n        font-weight: 600;\n        white-space: nowrap;\n\n        /* pre-hover */\n        opacity: 0.75;\n        transition: all 0.25s;\n\n    } .button:hover {\n\n        /* post-hover */\n        cursor: pointer;\n        opacity: 1;\n    }\n\n    .button-line {\n\n        /* dimentions */\n        width: 100%;\n        height: 1px;\n\n        /* background */\n        background-color: var(--line-color);\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
