// Modules
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

// Create a div that will hold our overlay outside of the component
const overlayRoot = document.createElement('div');

const Overlay = ({ children }) => {
    
    useEffect(() => {
        // Append the created div to the body when the component mounts
        document.body.appendChild(overlayRoot);

        return () => {
            // Clean up the div when the component unmounts
            document.body.removeChild(overlayRoot);
        };
    }, []);

    // Render the children inside the overlayRoot using React Portal
    return ReactDOM.createPortal(
        children,
        overlayRoot
    );
};

export default Overlay;