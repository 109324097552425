// Modules
import { useEffect } from 'react';

// State
import { useRefresh } from '@Utils/hooks/useRefresh.js';
import { VisualEditorState } from '@State/VisualEditor/VisualEditorState.js';

// Components
import NodeInput from './NodeInput/NodeInput.js';

// Resources
import addIcon from './addIcon.svg';

// Styles
import s from './NodeInputs.module.css';

// Component function
export default function NodeInputs({ node }) {

    const refresh = useRefresh();
    useEffect(() => { return node.listen('updateInputs', refresh); }, []);

    // Component html
    //----------------------------------------------------------------------------------------------------

    return (
        <div className={s['node-inputs']}>

            {node.settings?.inputs?.canAdd ? <div className={s['node-add-input-button']}> </div> : null}

            {/* Map through inputs */}
            {
                node.inputs?.map((input, index) => {
                    return (<NodeInput input={input} key={index} />);
                })
            }

            {/* Add input button */}
            {node.settings?.inputs?.canAdd ? <img className={s['node-add-input-button']} src={addIcon} onClick={node.addInput.bind(node)} title={'Add input'} onDragStart={(e) => { e.preventDefault(); }}></img> : null}
        </div>
    );
}