// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SandboxNodes_sandbox-nodes__NmmaY {

    /* position */
    position: absolute;
    left: 0px;
    top: 0px;

    /* dimensions */
    width: 0px;
    height: 0px;
    overflow: visible;
}`, "",{"version":3,"sources":["webpack://./src/VisualEditor/Sandbox/SandboxNodes/SandboxNodes.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,QAAQ;;IAER,eAAe;IACf,UAAU;IACV,WAAW;IACX,iBAAiB;AACrB","sourcesContent":[".sandbox-nodes {\n\n    /* position */\n    position: absolute;\n    left: 0px;\n    top: 0px;\n\n    /* dimensions */\n    width: 0px;\n    height: 0px;\n    overflow: visible;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sandbox-nodes": `SandboxNodes_sandbox-nodes__NmmaY`
};
export default ___CSS_LOADER_EXPORT___;
