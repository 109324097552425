// Components
import Overlay from '@Utils/hooks/Overlay/Overlay.js';
import { useSmoothScroll } from '@Utils/hooks/useSmoothScroll.js';
import GuiGroup from '../../Elements/Group/Group.js';

// Resources
import closeIcon from '../../Icons/delete.svg';
import { useRef, useEffect } from 'react';

// Styles
import s from './ElementOptionsPanel.module.css'

// Component
export default function ElementOptionsPanel({ element, closePanel }) {

    const overlayRef = useRef(null);
    const panelRef = useSmoothScroll();

    // Open / close panel
    //----------------------------------------------------------------------------------------------------

    // Show / fade in panel on load
    useEffect(() => {

        overlayRef.current.style.opacity = 0;

        setTimeout(() => {
            if (!overlayRef.current) { return; }
            overlayRef.current.style.opacity = 1;
        }, 100);
    }, [])

    // Fade out / hide panel on close
    function fadeOutAndHide() {
        overlayRef.current.style.opacity = 0;
        setTimeout(() => { closePanel() }, 300);
    }

    // Component
    //----------------------------------------------------------------------------------------------------

    return (

        <Overlay>
            <div className={s['gui-options-panel-overlay']} ref={overlayRef} onClick={fadeOutAndHide}>

                <div className={s['gui-options-panel']} ref={panelRef} onClick={(e) => { e.stopPropagation(); }}>

                    <div className={s['gui-options-panel-header']}>
                        <div className={s['gui-options-panel-header-name']}>{`"${element.name || element.type}" Options`}</div>
                        <img className={s['gui-options-panel-close-button']} src={closeIcon} onClick={fadeOutAndHide}></img>
                    </div>

                    <div className={s['gui-options-panel-content']}>
                        <GuiGroup group={element.gui} />
                    </div>
                </div>
            </div>
        </Overlay>
    );
}