// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * okaidia theme for JavaScript, CSS and HTML
 * Loosely based on Monokai textmate theme by http://www.monokai.nl/
 * @author ocodia
 */

code[class*="language-"],
pre[class*="language-"] {
    color: #9AD9FA;
}

:not(pre)>code[class*="language-"],

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: rgba(255, 255, 255, 0.25);
}

.token.punctuation {
    color: #f8f8f2;
}

.namespace {
    opacity: .7;
}

.token.property,
.token.tag,
.token.constant,
.token.symbol,
.token.deleted {
    color: #f92672;
}

.token.boolean,
.token.number {
    color: #ae81ff;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
    color: #a6e22e;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
    color: #f8f8f2;
}

.token.atrule,
.token.attr-value,
.token.function {
    color: #e6db74;
}

.token.keyword {
    color: #569CD6;
}

.token.regex,
.token.important {
    color: #fd971f;
}

.token.important,
.token.bold {
    font-weight: bold;
}

.token.italic {
    font-style: italic;
}

.token.entity {
    cursor: help;
}`, "",{"version":3,"sources":["webpack://./src/Shared-Components/Gui/Elements/CodeEditor/okaidia-theme.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;;IAEI,cAAc;AAClB;;AAEA;;;;;;IAMI,gCAAgC;AACpC;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;;;;;IAKI,cAAc;AAClB;;AAEA;;IAEI,cAAc;AAClB;;AAEA;;;;;;IAMI,cAAc;AAClB;;AAEA;;;;;;IAMI,cAAc;AAClB;;AAEA;;;IAGI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;;IAEI,cAAc;AAClB;;AAEA;;IAEI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB","sourcesContent":["/**\n * okaidia theme for JavaScript, CSS and HTML\n * Loosely based on Monokai textmate theme by http://www.monokai.nl/\n * @author ocodia\n */\n\ncode[class*=\"language-\"],\npre[class*=\"language-\"] {\n    color: #9AD9FA;\n}\n\n:not(pre)>code[class*=\"language-\"],\n\n.token.comment,\n.token.prolog,\n.token.doctype,\n.token.cdata {\n    color: rgba(255, 255, 255, 0.25);\n}\n\n.token.punctuation {\n    color: #f8f8f2;\n}\n\n.namespace {\n    opacity: .7;\n}\n\n.token.property,\n.token.tag,\n.token.constant,\n.token.symbol,\n.token.deleted {\n    color: #f92672;\n}\n\n.token.boolean,\n.token.number {\n    color: #ae81ff;\n}\n\n.token.selector,\n.token.attr-name,\n.token.string,\n.token.char,\n.token.builtin,\n.token.inserted {\n    color: #a6e22e;\n}\n\n.token.operator,\n.token.entity,\n.token.url,\n.language-css .token.string,\n.style .token.string,\n.token.variable {\n    color: #f8f8f2;\n}\n\n.token.atrule,\n.token.attr-value,\n.token.function {\n    color: #e6db74;\n}\n\n.token.keyword {\n    color: #569CD6;\n}\n\n.token.regex,\n.token.important {\n    color: #fd971f;\n}\n\n.token.important,\n.token.bold {\n    font-weight: bold;\n}\n\n.token.italic {\n    font-style: italic;\n}\n\n.token.entity {\n    cursor: help;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
