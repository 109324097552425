// Modules
import React, { useEffect, useRef } from 'react';
import ReactDomClient from 'react-dom/client';
import { SandboxedClass } from './SandboxedClass.js';

// Script that will be executed inside the iframe
const scriptContent = `

    const { SandboxedClass } = window.a;
    const instance = new SandboxedClass();

    instance.logWindow();
`

// Component function
export default function TestIframeComponent() {

    const iframeRef = useRef();

    useEffect(() => {

        // Get iframe and iframe document
        const iframe = iframeRef.current;
        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
        const iframeWindow = iframe.contentWindow;

        // Write html into the document
        iframeDoc.open();
        iframeDoc.write(`<script>${scriptContent}</script>`);
        iframeDoc.close();

    }, []);

    // Component HTML
    //--------------------------------------------------------------------------------

    return (
        <iframe ref={iframeRef}></iframe>
    );
}